import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FilterValue } from "react-table";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";

interface IMinistriesFiltersProps {
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
}

export const MinistriesFilters = ({
  globalFilter,
  setGlobalFilter,
}: IMinistriesFiltersProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      container
      sx={{
        marginBottom: match ? "1rem" : "0rem",
        alignSelf: "end",
        maxWidth: match ? "100%" : "50%",
      }}
    >
      <Grid item sm={7} lg={4} sx={{ textAlign: "left" }}>
        <FormControl
          sx={css.uiSearch}
          variant="filled"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <FilledInput
            startAdornment={
              <InputAdornment
                position="start"
                placeholder="Поиск министерства.."
              >
                <Search
                  sx={{
                    color: theme.palette.main.gray,
                    width: "1.25rem",
                    height: "1.25rem",
                    ml: "10px",
                  }}
                />
              </InputAdornment>
            }
            placeholder="Поиск министерства"
            value={globalFilter}
            autoFocus={Boolean(globalFilter)}
            style={{ fontSize: "1rem" }}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
