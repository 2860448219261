export const css = {
  indicatorValue: (isBig?: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.25rem",
    fontWeight: "bold",
    flex: 1,
    boxShadow: "none",
    cursor: "pointer",
    lineHeight: isBig ? "4rem" : "1.25rem",
    // backgroundColor: "transparent",
  }),
  indicatorTotal: {
    color: "text.secondary",
  },
};
