import { useCallback } from "react";
import { useCashExecution } from "SRC/redux/slices/gosprogram/hooks/useCashExecution";
import { useControlPoints } from "SRC/redux/slices/gosprogram/hooks/useControlPoints";
import { useModalSideBar } from "SRC/redux/slices/gosprogram/hooks/useModalSideBar";
import { useSubjects } from "SRC/redux/slices/gosprogram/hooks/useSubjects";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";

export interface IEventDataState {
  loading: boolean;
  loaded: boolean;
  init(params: IEventParams): void;
  hasError: any;
}

export const useEventData = (): IEventDataState => {
  const {
    fetching: loadingCashExecution,
    load: loadCashExecution,
    fetched: loadedCashExecution,
    error: errorCashExecution,
  } = useCashExecution();
  const {
    fetching: loadingControlPoints,
    load: loadControlPoints,
    fetched: loadedControlPoints,
    error: errorControlPoints,
  } = useControlPoints();
  const {
    fetching: loadingSubjects,
    load: loadSubjects,
    fetched: loadedSubjects,
    error: errorSubjects,
  } = useSubjects();
  const {
    fetching: loadingModalSideBar,
    load: loadModalSideBar,
    fetched: loadedModalSideBar,
    error: errorModalSideBar,
  } = useModalSideBar();

  const loading =
    loadingCashExecution ||
    loadingControlPoints ||
    loadingSubjects ||
    loadingModalSideBar;
  const loaded =
    loadedCashExecution &&
    loadedControlPoints &&
    loadedSubjects &&
    loadedModalSideBar;
  const hasError =
    errorCashExecution ||
    errorControlPoints ||
    errorSubjects ||
    errorModalSideBar;

  const init = useCallback(
    (params) => {
      if (!loading) {
        loadCashExecution(params);
        loadControlPoints(params);
        loadSubjects(params);
        loadModalSideBar(params);
      }
    },
    [
      loading,
      loadCashExecution,
      loadControlPoints,
      loadSubjects,
      loadModalSideBar,
    ]
  );

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
