import { Box, Grid, Typography, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { WarningTriangle } from "ASSETS/svg";
import React, { Dispatch, SetStateAction } from "react";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { IDataSideBar } from "SRC/pages/Program/common/EventCard/util/interfaces";

import { css } from "../EventCard.styled";

interface IProps {
  data: IDataSideBar;
  unit: string;
  setUnit: Dispatch<SetStateAction<string>>;
}

export const EventCardSidebar = ({ data, unit, setUnit }: IProps) => {
  const theme = useTheme();

  return (
    <Box sx={css.sideBarTop}>
      <Box sx={css.borderContainer}>
        <Typography sx={css.sideBarTopHeaderText}>
          Уровень достижения мероприятия
        </Typography>
        <Box sx={css.achievementLevel}>
          <Typography sx={css.achievementLevelText} className="big">
            {String(data?.achievementLevel).split(".")[0]}
          </Typography>
          <Typography sx={css.achievementLevelText} className="small">
            {String(data?.achievementLevel).split(".")[1] &&
              `,${String(data?.achievementLevel).split(".")[1]}`}
          </Typography>
          <Typography sx={css.achievementLevelText} className="percent">
            &nbsp;%
          </Typography>
        </Box>
        <ProgressBar
          value={Number(data?.achievementLevel)}
          progressColor={theme.palette.primary.contrastText}
        />
      </Box>
      <Box sx={css.borderContainer}>
        <Typography
          sx={css.sideBarTopHeaderText}
          style={{ marginBottom: "1rem" }}
        >
          Количество корректировок
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText} className="lineHeight">
              ухудшилось значение / срок
            </Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="red">
              {data?.corrections.value.worse}
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="red">
              {data?.corrections.time.worse}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>
              улучшилось значение / срок
            </Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="green">
              {data?.corrections.value.better}
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="green">
              {data?.corrections.time.better}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={css.borderContainer}>
        <Grid container sx={css.sideBarTopItemContainer}>
          <Grid item sx={css.sideBarTopHeaderText}>
            Значение мероприятия
          </Grid>
          <Grid item>
            <WarningTriangle
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>
              план / факт / прогноз
            </Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="white">
              {data?.event.plan}
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              {data?.event.fact}
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              {data?.event.forecast}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={css.borderContainer}>
        <Grid container sx={css.sideBarTopItemContainer}>
          <Grid item sx={css.sideBarTopHeaderText}>
            Срок реализации
          </Grid>
          <Grid item>
            <WarningTriangle
              style={{
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>план / факт</Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="white">
              {data?.realization.plan}
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              {data?.realization.fact}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container sx={css.sideBarTopItemContainer}>
          <Grid item sx={css.sideBarTopHeaderText}>
            Финансирование
          </Grid>
          <Grid item>
            <Box>
              <Select
                value={unit}
                defaultValue="млдр"
                onChange={(event: SelectChangeEvent) =>
                  setUnit(event.target.value)
                }
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="млрд">млрд &#8381;</MenuItem>
                <MenuItem value="млн">млн &#8381;</MenuItem>
                <MenuItem value="тыс">тыс &#8381;</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography sx={css.sideBarGrayText}>план / факт</Typography>
          </Grid>
          <Grid item sx={css.flexRow}>
            <Typography sx={css.sideBarValueText} className="white">
              {data?.financing.plan}
            </Typography>
            &nbsp;/&nbsp;
            <Typography sx={css.sideBarValueText} className="white">
              {data?.financing.fact}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
