import { Box, Paper } from "@mui/material";
import React from "react";
import { RatingTab } from "SRC/pages/Summary/common/Rating/RatingTab/RatingTab";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";
import { css } from "SRC/pages/Summary/layouts/right-layout/right-layout.styled";

interface IRightLayoutProps {
  topBestData: IRatingTopBestData[] | undefined;
  topWorstData: IRatingTopWorstData[] | undefined;
}
export const RightLayout = ({
  topBestData,
  topWorstData,
}: IRightLayoutProps) => {
  return (
    <Box sx={css.rightLayout}>
      <Box sx={css.tableHeaderWrapper}>
        <Paper sx={css.tableTitle} component="h1" elevation={0}>
          Рейтинг
        </Paper>
      </Box>
      <Box sx={css.divider} />
      <Box sx={css.ratingTabs}>
        <RatingTab data={topBestData} withDarkBgr={true} typeTop={"best"} />
        <RatingTab data={topWorstData} withDarkBgr={true} typeTop={"worst"} />
      </Box>
    </Box>
  );
};
