import { Box, styled, Theme } from "@mui/material";
import theme from "SRC/theme";

export const wrapImg = () => ({
  display: "flex",
  flex: "0 0 30%",
  [theme.breakpoints.up("xl")]: {
    flex: "0 0 45%",
  },
});
export const financingWrapStatistic = () => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100%",
});

export const financingScreenStatistic = (theme: Theme) => ({
  backgroundColor: theme.palette.main.darkBlue,
  padding: "1rem",
  borderRadius: "0.5rem",
  gap: "0.5rem",
  flex: 1,
  "& svg": {
    flexShrink: "1",
    position: "relative",
    top: "0.5rem",
    marginRight: "1rem",
    fill: theme.palette.success.main,
  },
  [theme.breakpoints.up("xs")]: {
    padding: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    padding: "1.5rem",
  },
});

export const FinancingBox = styled(Box)({
  flex: 1,
  paddingLeft: 0,
  flexDirection: "column",
  display: "flex",
  justifyContent: "flex-start",
});

export const FinancingBoxTitle = styled("div")({
  paddingBottom: "1.5rem",
  fontSize: "1.125rem",
  fontWeight: 700,
  color: theme.palette.text.onAccent,
  [theme.breakpoints.up("xl")]: {
    paddingBottom: "0.8rem",
  },
});

export const financingBoxStyles = {
  wrapper: (isRight: boolean) => {
    const fill = isRight
      ? theme.palette.main.blue
      : theme.palette.text.onAccent;

    return {
      borderLeft: isRight
        ? `2px dashed ${theme.palette.background.paper}`
        : "none",
      paddingLeft: isRight ? "1.5rem" : "0",
      "& > svg": {
        overflow: "visible",
      },
      "& > svg > circle": {
        fill,
        filter: `drop-shadow(0 0 2px ${fill})`,
      },
    };
  },
};

export const FinancingFact = styled("div")({
  marginTop: "0.5rem",
  fontSize: "1rem",
  color: theme.palette.text.primary,
});

export const financingTopCount = () => ({
  padding: "0.5rem 0",
  fontSize: "1.5rem",
  [theme.breakpoints.up("xl")]: {
    padding: "0.2rem 0",
  },
});

export const financingBottomCount = () => ({
  py: "0.5rem",
  fontSize: "1.25rem",
  borderTop: "1px solid #58637B",
  width: "4rem",
  color: theme.palette.text.secondary,
  [theme.breakpoints.up("xl")]: {
    py: "0.2rem",
  },
});

export const css = {
  heading: {
    pb: 4,
    borderBottom: (theme: Theme) => `3px dotted ${theme.palette.main.darkBlue}`,
  },
  tabsHeading: {
    borderBottom: 1,
    borderColor: "divider",
    "& .MuiTab-root": {
      color: (theme: Theme) => theme.palette.complementary.grayBlue,
      fontWeight: "bold",
      textTransform: "lowercase",
      '&[aria-selected="true"]': {
        color: "white",
      },
    },
  },
  graph: {
    width: "100%",
  },
  fistTabContentPaddings: {
    padding: "30px",
  },
};
