import { olapRequestData3, olapRequestData4 } from "CORE/api/core";
import {
  getGpIndicators,
  getMinistriesIndicators,
} from "SRC/constants/dataCodes";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

export const getAllProgramsInfo = (period: IPeriod) => {
  return olapRequestData4([
    {
      requestId: "2ff4e5db-c923-4a17-9e1d-c60dae4bcb46",
      type: "data2",
      params: {
        modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1562",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "PERIOD_CODE",
            ],
          },
          {
            id: "1565",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "gp_code",
              "gp_short_name",
              "gp_icon_url",
              "gp_name",
              "Вице-премьер",
              "Министерство",
              "Вице-премьер, код",
              "Вице-премьер, фото",
              "Секретная",
              "Комплексная",
              "Отраслевая",
              "Сфера",
            ],
          },
        ],
        indicators: {
          id: "1571",
          items: [
            {
              id: "1547",
              aggregationFunction: "sum",
            },
            {
              id: "1548",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1572",
              aggregationFunction: "sum",
            },
            {
              id: "1573",
              aggregationFunction: "sum",
            },
            {
              id: "1574",
              aggregationFunction: "sum",
            },
            {
              id: "1575",
              aggregationFunction: "sum",
            },
            {
              id: "1576",
              aggregationFunction: "sum",
            },
            {
              id: "1577",
              aggregationFunction: "sum",
            },
            {
              id: "1578",
              aggregationFunction: "sum",
            },
            {
              id: "1579",
              aggregationFunction: "sum",
            },
            {
              id: "1580",
              aggregationFunction: "sum",
            },
            {
              id: "1581",
              aggregationFunction: "sum",
            },
            {
              id: "1582",
              aggregationFunction: "sum",
            },
            {
              id: "1583",
              aggregationFunction: "sum",
            },
            {
              id: "1584",
              aggregationFunction: "sum",
            },
            {
              id: "1585",
              aggregationFunction: "sum",
            },
            {
              id: "1586",
              aggregationFunction: "sum",
            },
            {
              id: "1587",
              aggregationFunction: "sum",
            },
            {
              id: "1588",
              aggregationFunction: "sum",
            },
            {
              id: "1589",
              aggregationFunction: "sum",
            },
            {
              id: "1590",
              aggregationFunction: "sum",
            },
            {
              id: "1591",
              aggregationFunction: "sum",
            },
            {
              id: "1592",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1593",
              aggregationFunction: "sum",
            },
            {
              id: "1594",
              aggregationFunction: "sum",
            },
            {
              id: "1595",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1596",
              aggregationFunction: "sum",
            },
            {
              id: "1597",
              aggregationFunction: "sum",
            },
            {
              id: "1598",
              aggregationFunction: "sum",
            },
            {
              id: "1599",
              aggregationFunction: "sum",
            },
            {
              id: "1600",
              aggregationFunction: "sum",
            },
            {
              id: "1601",
              aggregationFunction: "sum",
            },
            {
              id: "1602",
              aggregationFunction: "sum",
            },
            {
              id: "1603",
              aggregationFunction: "sum",
            },
            {
              id: "1604",
              aggregationFunction: "sum",
            },
            {
              id: "1605",
              aggregationFunction: "sum",
            },
            {
              id: "1652",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1653",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1678",
              aggregationFunction: "sum",
            },
            {
              id: "1679",
              aggregationFunction: "sum",
            },
            {
              id: "1680",
              aggregationFunction: "sum",
            },
            {
              id: "1681",
              aggregationFunction: "sum",
            },
            {
              id: "1682",
              aggregationFunction: "sum",
            },
            {
              id: "1730",
              aggregationFunction: "sum",
            },
            {
              id: "1731",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1753",
              aggregationFunction: "sum",
            },
            {
              id: "1754",
              aggregationFunction: "sum",
            },
            {
              id: "1755",
              aggregationFunction: "sum",
            },
            {
              id: "1756",
              aggregationFunction: "sum",
            },
            {
              id: "1757",
              aggregationFunction: "sum",
            },
            {
              id: "1769",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1796",
              aggregationFunction: "sum",
            },
            {
              id: "1838",
              aggregationFunction: "sum",
            },
            {
              id: "1839",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1562",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1565",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1565",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1562",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [period.filterValue.slice(0, 4)],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getAllPremiersInfo = (period: IPeriod) => {
  return olapRequestData4([
    {
      requestId: "44ae5da7-4d52-40ca-936c-b498c9a2f275",
      type: "data2",
      params: {
        modelUuid: "f8cfb9f5-ce0b-4b19-86c5-fd5a55d97a77",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1683",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "PERIOD_CODE",
            ],
          },
          {
            id: "1686",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "VP_CODE",
              "VP_SHORT_NAME",
              "VP_PHOTO_URL",
              "VP_NAME",
            ],
          },
        ],
        indicators: {
          id: "1692",
          items: [
            {
              id: "1693",
              aggregationFunction: "sum",
            },
            {
              id: "1694",
              aggregationFunction: "sum",
            },
            {
              id: "1695",
              aggregationFunction: "sum",
            },
            {
              id: "1696",
              aggregationFunction: "sum",
            },
            {
              id: "1697",
              aggregationFunction: "sum",
            },
            {
              id: "1698",
              aggregationFunction: "sum",
            },
            {
              id: "1699",
              aggregationFunction: "sum",
            },
            {
              id: "1700",
              aggregationFunction: "sum",
            },
            {
              id: "1701",
              aggregationFunction: "sum",
            },
            {
              id: "1702",
              aggregationFunction: "sum",
            },
            {
              id: "1703",
              aggregationFunction: "sum",
            },
            {
              id: "1704",
              aggregationFunction: "sum",
            },
            {
              id: "1705",
              aggregationFunction: "sum",
            },
            {
              id: "1706",
              aggregationFunction: "sum",
            },
            {
              id: "1707",
              aggregationFunction: "sum",
            },
            {
              id: "1708",
              aggregationFunction: "sum",
            },
            {
              id: "1709",
              aggregationFunction: "sum",
            },
            {
              id: "1710",
              aggregationFunction: "sum",
            },
            {
              id: "1711",
              aggregationFunction: "sum",
            },
            {
              id: "1712",
              aggregationFunction: "sum",
            },
            {
              id: "1713",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1714",
              aggregationFunction: "sum",
            },
            {
              id: "1715",
              aggregationFunction: "sum",
            },
            {
              id: "1716",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1717",
              aggregationFunction: "sum",
            },
            {
              id: "1718",
              aggregationFunction: "sum",
            },
            {
              id: "1719",
              aggregationFunction: "sum",
            },
            {
              id: "1720",
              aggregationFunction: "sum",
            },
            {
              id: "1721",
              aggregationFunction: "sum",
            },
            {
              id: "1722",
              aggregationFunction: "sum",
            },
            {
              id: "1723",
              aggregationFunction: "sum",
            },
            {
              id: "1724",
              aggregationFunction: "sum",
            },
            {
              id: "1725",
              aggregationFunction: "sum",
            },
            {
              id: "1726",
              aggregationFunction: "sum",
            },
            {
              id: "1727",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1728",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1742",
              aggregationFunction: "sum",
            },
            {
              id: "1743",
              aggregationFunction: "sum",
            },
            {
              id: "1744",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1745",
              aggregationFunction: "sum",
            },
            {
              id: "1746",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1747",
              aggregationFunction: "sum",
            },
            {
              id: "1748",
              aggregationFunction: "sum",
            },
            {
              id: "1749",
              aggregationFunction: "sum",
            },
            {
              id: "1758",
              aggregationFunction: "sum",
            },
            {
              id: "1759",
              aggregationFunction: "sum",
            },
            {
              id: "1760",
              aggregationFunction: "sum",
            },
            {
              id: "1761",
              aggregationFunction: "sum",
            },
            {
              id: "1762",
              aggregationFunction: "sum",
            },
            {
              id: "1763",
              aggregationFunction: "sum",
            },
            {
              id: "1764",
              aggregationFunction: "sum",
            },
            {
              id: "1765",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1766",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1767",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1768",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1797",
              aggregationFunction: "sum",
            },
            {
              id: "1840",
              aggregationFunction: "sum",
            },
            {
              id: "1841",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1683",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1686",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1686",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1683",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [period.filterValue.slice(0, 4)],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

// Министерства
export const getMinistries = (period: IPeriod) => {
  return olapRequestData3([
    {
      requestId: "656bfe35-fdfe-4562-8364-e2745ae60680",
      type: "data2",
      params: {
        modelUuid: "485e7c43-6639-4b8e-9979-996461bea181",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1770",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "GERB_ICON",
            ],
          },
          {
            id: "1774",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "DatePart",
              "PeriodStart",
              "PeriodEnd",
              "PERIOD2",
            ],
          },
        ],
        indicators: {
          id: "1781",
          items: getMinistriesIndicators(
            1782,
            1783,
            1784,
            1785,
            1786,
            1787,
            1788
          ),
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1770",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1774",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1770",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1774",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [period.filterValue.slice(0, 4)],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getTopBest = () => {
  return olapRequestData3([
    {
      requestId: "175baed8-7166-4997-9cfc-9f60b4b1cade",
      type: "data2",
      params: {
        modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1562",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1565",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1571",
          items: getGpIndicators(1547),
          limit: {
            id: "1547",
            order: "desc",
            type: "TOP",
            value: 5,
          },
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1562",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1565",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1565",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1562",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: ["2022"],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getTopWorst = () => {
  return olapRequestData3([
    {
      requestId: "f249963f-43f9-4d47-b6e1-5d676872e22a",
      type: "data2",
      params: {
        modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1562",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1565",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1571",
          items: getGpIndicators(1547),
          limit: {
            id: "1547",
            order: "asc",
            type: "TOP",
            value: 5,
          },
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1562",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1565",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1565",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1562",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: ["2022"],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};
