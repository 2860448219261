import { Box, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  iconCss,
  StatusIconContainer,
} from "SRC/components/Table/assets/icons";
import {
  css,
  TableCellBigText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import theme from "SRC/theme";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const subjectColumns = [
  {
    Header: "Название субъекта",
    accessor: "nameSubject",
    Cell: (tableProps: CustomTableProps) => (
      <div style={{ position: "relative", padding: "0 20px" }}>
        <StatusIconContainer>
          <Box
            sx={iconCss.statusIconImage(
              theme,
              tableProps.row.original.statusLabel
            )}
          />
        </StatusIconContainer>
        <Box
          sx={css.tableCellText}
          className="with-right-border withMarginLeft"
        >
          <Typography sx={css.tableCellText}>
            {tableProps.row.original.nameSubject}
          </Typography>
        </Box>
      </div>
    ),
  },
  {
    Header: "Сроки",
    Footer: " ",
    columns: [
      {
        Header: "План",
        accessor: "timePlan",
        Cell: (tableProps: CustomTableProps) => (
          <TableCellBigText align="center">
            {tableProps.row.original.timePlan}
          </TableCellBigText>
        ),
      },
      {
        Header: "Факт",
        accessor: "timeFact",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(theme, tableProps.row.original.statusLabel)}
            className="bigText with-right-border"
            align="center"
          >
            {tableProps.row.original.timeFact}
          </Typography>
        ),
      },
    ],
  },
  {
    Header: "Значение",
    Footer: " ",
    columns: [
      {
        Header: "План",
        accessor: "countObjectPlan",
        Cell: (tableProps: CustomTableProps) => (
          <TableCellBigText align="center">
            {tableProps.row.original.countObjectPlan}
          </TableCellBigText>
        ),
      },
      {
        Header: "Факт",
        accessor: "countObjectFact",
        Cell: (tableProps: CustomTableProps) => (
          <Typography
            sx={css.tableCellText(
              theme,
              tableProps.row.original.statusLabel,
              true
            )}
            className="bigText with-right-border"
            align="center"
          >
            {tableProps.row.original.countObjectFact}
          </Typography>
        ),
      },
    ],
  },
];

export { subjectColumns };
