import { Box, Grid } from "@mui/material";
import React from "react";
import { ProgressDoughnut } from "SRC/components/Charts";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import InfoCard from "SRC/pages/Program/common/GovProgramInfoWidget/InfoCard/InfoCard";
import SimpleInfo from "SRC/pages/Program/common/GovProgramInfoWidget/InfoCard/SimpleInfo/SimpleInfo";

import {
  DottedVerticalLine,
  SmallWhiteText,
  VerticalDividerContainer,
} from "../GovProgramInfoWidget.styled";
import {
  css,
  CuratorNameContainer,
  CuratorPhoto,
  CuratorSection,
  CuratorText,
  Guide,
} from "./CuratorInfo.styled";

interface ICuratorData {
  curator: {
    name: string | number;
    photo: string;
    executorPhoto: string;
    executorName: string;
  };
  achievementLevel: {
    percent: number;
    units: string;
  };
  events: {
    total: number;
    done: number;
    atRisk: number;
    units: string;
    percentage: number;
  };
  objectsCreated: {
    total: number;
    done: number;
    atRisk?: number;
    units: string;
    percentage: number;
  };
  npa: {
    total: number;
    done: number;
    atRisk: number;
    units: string;
    percentage: number;
  };
}

interface IProps {
  curatorData: ICuratorData;
}

export const CuratorInfo = ({ curatorData }: IProps) => {
  const { curator, achievementLevel, events, objectsCreated, npa } =
    curatorData;

  return (
    <Box
      style={{
        display: "flex",
        height: "100%",
      }}
    >
      <VerticalDividerContainer>
        <DottedVerticalLine />
      </VerticalDividerContainer>
      <CuratorSection>
        <Box sx={css.guideContainer}>
          <Guide>
            {curator.name && typeof curator.name === "string" && (
              <>
                <CuratorPhoto src={curator.photo} alt="photo" />
                <CuratorNameContainer>
                  <CuratorText>Куратор</CuratorText>
                  <SmallWhiteText
                    style={{ lineHeight: "1.5rem", fontSize: "1.25rem" }}
                  >
                    {curator.name}
                  </SmallWhiteText>
                </CuratorNameContainer>
              </>
            )}
          </Guide>
          <Guide>
            {curator.executorName && (
              <>
                <CuratorPhoto src={curator.executorPhoto} alt="photo" />
                <CuratorNameContainer>
                  <CuratorText>Ответственный исполнитель</CuratorText>
                  <SmallWhiteText
                    style={{ lineHeight: "1.5rem", fontSize: "1.25rem" }}
                  >
                    {curator.executorName}
                  </SmallWhiteText>
                </CuratorNameContainer>
              </>
            )}
          </Guide>
        </Box>
        <Grid container direction="column" gap={"1rem"}>
          <Grid item>
            <InfoCard title="Уровень достижения" units={achievementLevel.units}>
              <Box sx={css.curatorChartContainer}>
                <ProgressDoughnut
                  total={100}
                  value={achievementLevel.percent}
                />
              </Box>
            </InfoCard>
          </Grid>
          <Grid item>
            <InfoCard
              title="Мероприятия"
              units={events.units}
              atRisk={events.atRisk}
            >
              <SimpleInfo
                doneOf={events.done}
                totalOf={events.total}
                percentage={events.percentage}
              />
              <ProgressBar value={events.percentage} />
            </InfoCard>
          </Grid>
          <Grid item>
            <InfoCard
              title="Создано объектов"
              units={objectsCreated.units}
              atRisk={objectsCreated.atRisk}
            >
              <SimpleInfo
                doneOf={objectsCreated.done}
                totalOf={objectsCreated.total}
                percentage={objectsCreated.percentage}
              />
              <ProgressBar value={objectsCreated.done} />
            </InfoCard>
          </Grid>
          <Grid style={{ opacity: 0.3 }} item>
            <InfoCard title="НПА" units={npa.units} atRisk={npa.atRisk}>
              <SimpleInfo
                doneOf={npa.done}
                totalOf={npa.total}
                percentage={npa.percentage}
              />
              <ProgressBar value={npa.done} />
            </InfoCard>
          </Grid>
        </Grid>
      </CuratorSection>
    </Box>
  );
};
