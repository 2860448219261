import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTopWorst } from "SRC/redux/slices/summary/api/client";

export interface IProgramTopWorst {
  data: Record<string, any>;
  requestId: string;
  type: string;
}

export interface IProgramsTopWorstState {
  items: IProgramTopWorst[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk("programTopWorst/get", async () => await getTopWorst()),
};

const initialState: IProgramsTopWorstState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isProgramsTopWorst = (array: any): array is IProgramTopWorst[] =>
  !!array?.length;

const slice = createSlice({
  name: "programsTopWorst",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IProgramsTopWorstState, action) => {
          state.items = isProgramsTopWorst(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
