import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
// import Carousel, {
//   CarouselItem,
//   CounterButtons,
// } from "SRC/components/Carousel/Carousel";
import ChartView from "SRC/pages/Program/common/GoalsTab/GoalItem/ChartView/ChartView";
import IndicatorInfo from "SRC/pages/Program/common/GoalsTab/GoalItem/IndicatorInfo/IndicatorInfo";
import themeConfig from "SRC/theme";
import { IProgramGoal } from "SRC/types";

import { css } from "./GoalItem.styled";

interface IProps {
  item: IProgramGoal;
  chunkLength: number | boolean;
}

const GoalItem = (props: IProps) => {
  const { item, chunkLength } = props;

  const theme = useTheme();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const [chartViewIndex, setChartViewIndex] = useState(null);

  // TODO когда появятся данные по показателям, раскомментировать код и вставить данные в карусель
  // const [activeIndex, setActiveIndex] = useState(0);

  // const updateIndex = (newIndex: number) => {
  //   if (newIndex < 0) {
  //     newIndex = indicatorsInfo?.length - 1;
  //   } else if (newIndex >= indicatorsInfo?.length) {
  //     newIndex = 0;
  //   }
  //
  //   setActiveIndex(newIndex);
  // };

  const validChartPlanData = item?.["График УД показателя, План"].map((it) => {
    if (it === null) {
      return 0;
    }
    return it;
  });
  const validChartFactData = item?.["График УД показателя, Факт"].map((it) => {
    if (it === null) {
      return 0;
    }
    return it;
  });

  const isLastChunkStyle = chunkLength && chunkLength < (isExtraLarge ? 9 : 6);

  return (
    <Box sx={css.itemWrapper(isLastChunkStyle, theme) as SxProps}>
      <Box sx={css.itemContainer(true, chartViewIndex) as SxProps}>
        <Box
          sx={css.progressContainer(item?.["Уровень достижения"]) as SxProps}
        >
          <Box sx={css.progressBgr(item?.["Уровень достижения"]) as SxProps} />
          <Box sx={css.progressBgrBorder as SxProps} />
          <Box style={{ zIndex: 1 }}>
            <Typography sx={css.smallText}>
              Цель {item?.["Номер Цели"]}
            </Typography>
            <Typography sx={css.mainText as SxProps}>
              {item?.["Имя Цели"]}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", zIndex: 1 }}>
            <Typography sx={css.percentBigText as SxProps}>
              {roundNumbersToFixed(item?.["Уровень достижения"], 0)}
            </Typography>
            <Typography
              sx={css.percentBigText as SxProps}
              style={{ color: theme.palette.complementary.grayBlue }}
            >
              &nbsp;%
            </Typography>
          </Box>
        </Box>
        <Box sx={css.indicatorContainer as SxProps}>
          <Box sx={css.indicatorLabel}>
            <Typography sx={css.indicatorLabelText}>Показатель</Typography>
            <Box sx={css.dotsLine} />
            {/*<CounterButtons*/}
            {/*  updateIndex={updateIndex}*/}
            {/*  activeIndex={activeIndex}*/}
            {/*  total={indicatorsInfo?.length}*/}
            {/*/>*/}
          </Box>
          <Box sx={css.carouselContainer}>
            {/*<Carousel activeIndex={activeIndex}>*/}
            {/*  {indicatorsInfo?.map((elem: any, i: number) => {*/}
            {/*    return (*/}
            {/*      <CarouselItem key={i}>*/}
            {/*        <IndicatorInfo*/}
            {/*          index={0}*/}
            {/*          plan={0}*/}
            {/*          fact={0}*/}
            {/*          indicatorName={item?.["Имя показателя"]}*/}
            {/*          chartPlanData={validChartPlanData}*/}
            {/*          chartFactData={validChartFactData}*/}
            {/*          setChartViewIndex={setChartViewIndex}*/}
            {/*        />*/}
            {/*      </CarouselItem>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</Carousel>*/}
            <IndicatorInfo
              index={0}
              plan={item?.["УД показателя, План"]}
              fact={item?.["УД показателя, Факт"]}
              indicatorName={item?.["Имя показателя"]}
              chartPlanData={validChartPlanData}
              chartFactData={validChartFactData}
              setChartViewIndex={setChartViewIndex}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={css.itemContainer(false, chartViewIndex) as SxProps}>
        {chartViewIndex !== null && (
          <ChartView
            setChartViewIndex={setChartViewIndex}
            // indicatorsInfo={indicatorsInfo[chartViewIndex]}
            plan={item?.["УД показателя, План"]}
            fact={item?.["УД показателя, Факт"]}
            indicatorName={item?.["Имя показателя"]}
            chartPlanData={validChartPlanData}
            chartFactData={validChartFactData}
          />
        )}
      </Box>
    </Box>
  );
};

export default GoalItem;
