import { Box, Grid, SxProps, useTheme } from "@mui/material";
import React from "react";

import { css } from "./Layout.styles";

interface ILayoutProps {
  left: React.ReactNode;
  center: React.ReactNode;
  right: React.ReactNode;
  isProgramLayout?: boolean;
}
export const Layout = ({
  left,
  center,
  right,
  isProgramLayout,
}: ILayoutProps) => {
  const theme = useTheme();

  return (
    <Grid container p={8} sx={css.container}>
      {center && (
        <Grid item xs={12} lg={4} xl={6} gap={5} sx={css.center as SxProps}>
          {center}
        </Grid>
      )}
      {right && (
        <Grid
          item
          xs={12}
          lg={4}
          xl={3}
          sx={css.right(theme, isProgramLayout) as SxProps}
        >
          <Box sx={css.gridWrapper}>{right}</Box>
        </Grid>
      )}
      {left && (
        <Grid item xs={12} lg={4} xl={3} sx={css.left}>
          <Box sx={css.gridWrapper}>{left}</Box>
        </Grid>
      )}
    </Grid>
  );
};
