import { styled, Theme } from "@mui/material";

interface IProps {
  isSmall?: boolean;
  length?: number | undefined;
  withBigRows?: boolean;
  hasBorder?: "left" | "right";
  multilineHeader?: boolean;
  withHeadBgr?: boolean;
  withClickableRow?: boolean;
  tableHeight?: string | number;
  statusLabel?: string;
}

export const WarningIconContainer = styled("div")<IProps>({
  width: "1.3rem",
  position: "absolute",
  left: "-0.6rem",
  top: "50%",
  transform: "translateY(-40%)",
});

export const WarningIconImage = styled("img")({
  width: "100%",
});

export const StatusIconContainer = styled("div")<IProps>(({ theme }) => ({
  width: "0.5rem",
  height: "3.4rem",
  position: "absolute",
  left: "0.4rem",
  transform: "translateY(50%)",
  bottom: "50%",
  background: theme.palette.background.default,
  borderRadius: "0.25rem",
}));

export const iconCss = {
  statusIconImage: (theme: Theme, statusLabel?: string) => ({
    background:
      statusLabel === "atRisk"
        ? theme.palette.error.main
        : theme.palette.success.main,
    height: "3.2rem",
    width: "0.25rem",
    left: "0.125rem",
    bottom: "1.5rem",
    position: "relative",
    transform: "translateY(50%)",
    borderRadius: "0.125rem",
  }),
};

export const StatusIconImage = styled("div")<IProps>(
  ({ theme, statusLabel }: IProps & { theme: Theme }) => ({
    background:
      statusLabel === "atRisk"
        ? theme.palette.error.main
        : statusLabel === "inProgress"
        ? theme.palette.main.yellow
        : theme.palette.success.main,
    height: "3.2rem",
    width: "0.25rem",
    left: "0.125rem",
    bottom: "1.5rem",
    position: "relative",
    transform: "translateY(50%)",
    borderRadius: "0.125rem",
  })
);
