import { Theme } from "@mui/material";

export const css = {
  tabs: {
    maxWidth: (theme: Theme) => theme.breakpoints.values.md,
    margin: "auto",
    width: "100%",
  },
  tabSX: (theme: Theme) => ({
    flex: "0 0 auto",
    maxWidth: "fit-content",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    "&.Mui-selected": {
      color: theme.palette.text.onAccent,
    },
  }),
};
