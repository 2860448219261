import { Box, SxProps } from "@mui/material";
import React, { CSSProperties, FC } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

import { css } from "./StackedProgressBar.styled";

interface IProps {
  firstValue: number;
  firstPercent: number;
  secondValue: number;
  secondPercent: number;
  total: number;
  progressColor?: string;
  style?: CSSProperties;
}

export const StackedProgressBar: FC<IProps> = ({
  firstValue = 0,
  secondValue = 0,
  firstPercent = 0,
  secondPercent = 0,
  total,
  progressColor,
  style,
}) => {
  const firstVal = firstPercent > 100 ? 100 : firstPercent;
  const secondVal = secondPercent > 100 ? 100 : secondPercent;

  return (
    <Box>
      <Box sx={{ ...css.uiProgressBarStyle(progressColor), ...style }}>
        <Box sx={css.progressLineFirst(firstVal, progressColor)} />
        <Box sx={css.progressLineSecond(secondVal, progressColor)} />
      </Box>
      <Box sx={css.labelsContainer as SxProps}>
        <Box sx={css.firstLabel(firstVal)}>
          <span>{roundNumbersToFixed(firstValue, 0)}</span>
        </Box>
        <Box sx={css.secondLabel(secondVal)}>
          <span>{roundNumbersToFixed(secondValue, 0)}</span>
        </Box>
        <Box sx={css.totalLabel}>
          <span>{total}</span>
        </Box>
      </Box>
    </Box>
  );
};
