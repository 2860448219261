import { Box, SxProps, Typography, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import themeConfig from "SRC/theme";

import { css } from "./EventChart.styled";
import { getOption } from "./options";

interface IProps {
  planData: number[];
  factData: number[];
}

const EventChart = ({ planData, factData }: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const options = useMemo(
    () => getOption(planData, factData, theme),
    [theme, planData, factData]
  );

  const maxPlan = Math.max.apply(null, planData);
  const maxFact = Math.max.apply(null, factData);
  const maxValue = Math.max(maxPlan, maxFact);

  return (
    <Box sx={css.container}>
      {planData.map((item, i) => {
        const maxItem = Math.max(planData[i], factData[i]);

        const bottomOffset = maxValue
          ? maxItem !== 0
            ? (maxItem * 100) / maxValue > 80
              ? ((maxItem * 100) / maxValue) * 0.85
              : (maxItem * 100) / maxValue > 60
              ? ((maxItem * 100) / maxValue) * 0.9
              : (maxItem * 100) / maxValue > 30
              ? ((maxItem * 100) / maxValue) * 0.95
              : (maxItem * 100) / maxValue > 25
              ? (maxItem * 100) / maxValue
              : (maxItem * 100) / maxValue > 15
              ? ((maxItem * 100) / maxValue) * 1.1
              : (maxItem * 100) / maxValue > 5
              ? (maxItem * 100) / maxValue + 6
              : (maxItem * 100) / maxValue + 8
            : 10
          : 10;

        const leftOffset =
          i > 5
            ? `calc((100%/12)*${i} + 3.${i}%)`
            : i === 5
            ? `calc((100%/12)*${i} + 3%)`
            : `calc((100%/12)*${i} + 2.${i}%)`;

        return (
          <Box sx={css.labelContainer(bottomOffset, leftOffset)}>
            <Typography sx={css.planText}>
              {planData[i] > 0
                ? roundNumbersToFixed(planData[i] || 0, 2)
                : planData[i] === 0
                ? "0.00"
                : "-"}
            </Typography>
            <Box sx={css.divider} />
            <Typography
              sx={css.factText(factData[i] >= planData[i]) as SxProps}
            >
              {factData[i] > 0
                ? roundNumbersToFixed(factData[i] || 0, 2)
                : factData[i] === 0
                ? "0.00"
                : "-"}
            </Typography>
          </Box>
        );
      })}
      <ReactECharts
        option={options}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};

export default EventChart;
