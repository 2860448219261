import { Theme } from "@mui/material";

export const css = {
  chartContainer: (theme: Theme) => ({
    width: "30rem",
    height: "30rem",
    margin: "auto",

    [theme.breakpoints.up("md")]: {
      width: "30rem",
      height: "30rem",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25rem",
      height: "25rem",
    },
    [theme.breakpoints.up("xl")]: {
      width: "18rem",
      height: "18rem",
    },
  }),
  leftHeaderContainer: {
    display: "flex",
  },
  secretProgram: {
    "& svg": {
      width: "2rem",
      height: "4rem",
    },
  },
  programInfoContainer: {
    paddingRight: "2rem",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
};
