import { createSlice } from "@reduxjs/toolkit";

export interface IPeriod {
  value: null | Date;
  filterValue: string;
}

export interface IFiltersState {
  program: string;
  vp: string;
  goal: string;
  foiv: string;
  ministry: string;
  period: IPeriod;
  tooltipCoordinates: [number, number];
  viewChart: number;
  centerTab: string;
  complexGridTab: string;
  typeProgram: string;
  calculationMethod: "effect" | "result";
}

const initialState: IFiltersState = {
  program: "",
  vp: "",
  goal: "",
  foiv: "",
  ministry: "",
  period: {
    value: new Date(),
    filterValue: "202200",
  },
  viewChart: 1,
  tooltipCoordinates: [0, 0],
  centerTab: "analytics",
  complexGridTab: "govPrograms",
  typeProgram: "",
  calculationMethod: "effect",
};

const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    clear: () => initialState,
    selectProgram: (state, action: { payload: string }) => {
      state.program = action.payload;
    },
    selectVP: (state, action: { payload: string }) => {
      state.vp = action.payload;
    },
    selectMinistry: (state, action: { payload: string }) => {
      state.ministry = action.payload;
    },
    selectGoal: (state, action: { payload: string }) => {
      state.goal = action.payload;
    },
    selectFoiv: (state, action: { payload: string }) => {
      state.foiv = action.payload;
    },
    selectTypeProgram: (state, action: { payload: string }) => {
      state.typeProgram = action.payload;
    },
    selectPeriod: (state, action: { payload: IPeriod }) => {
      state.period = action.payload;
    },
    toggleChart: (state, action: { payload: number }) => {
      state.viewChart = action.payload;
    },
    setCenterTab: (state, action: { payload: string }) => {
      state.centerTab = action.payload;
    },
    setComplexGridTab: (state, action: { payload: string }) => {
      state.complexGridTab = action.payload;
    },
    setTooltipCoordinates: (state, action: { payload: [number, number] }) => {
      state.tooltipCoordinates = action.payload;
    },
    setCalculationMethod: (state, action: { payload: "effect" | "result" }) => {
      state.calculationMethod = action.payload;
    },
  },
});

export default slice;
