import { Box, Grid, styled, Theme, Typography } from "@mui/material";
import DotLineDark from "ASSETS/svg/line/dottedLineDark.svg";
import DottedVerticalLineDark from "ASSETS/svg/line/dottedVerticalLineDark.svg";

interface IProps {
  percentageValue?: number;
  progressValue?: number;
}

export const GridContainer = styled(Grid)(({ theme }) => ({
  background: theme.palette.complementary.darkBlue,
  borderRadius: "2.5rem",
  width: "100%",
  maxWidth: theme.breakpoints.values.md,
  // height: "62.5rem",
  margin: "auto",
  position: "relative",
  padding: "2rem 4rem",

  [theme.breakpoints.up("xl")]: {
    padding: "2rem 2rem 2rem 3rem",
  },
}));

export const ProgramIcon = styled("img")(({ theme }) => ({
  width: "3.5rem",
  height: "3.5rem",
  position: "absolute",
  left: "-1.75rem",
  top: "1.75rem",
  border: `4px solid ${theme.palette.background.default}`,
  borderRadius: "50%",

  [theme.breakpoints.up("xl")]: {
    width: "3rem",
    height: "3rem",
    left: "-1.5rem",
    top: "1.5rem",
  },
}));

export const ProgramId = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "3.25rem",
  lineHeight: "3.1rem",
  color: theme.palette.complementary.grayBlue,

  [theme.breakpoints.up("xl")]: {
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
  },
}));

export const BigWhiteText = styled(Typography)(({ theme }) => ({
  display: "flex",
  lineHeight: "2rem",
  fontWeight: "bold",
  fontSize: "2rem",
  textAlign: "center",
  color: theme.palette.primary.contrastText,
  "&.centered": {
    justifyContent: "center",
  },

  [theme.breakpoints.up("xl")]: {
    fontSize: "1.75rem",
  },
}));

export const ProgramType = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  lineHeight: "1.5rem",
  color: theme.palette.complementary.grayBlue,

  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
}));

export const DottedLineDark = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.2rem",
  marginTop: "0.5rem",
  marginBottom: "1rem",
  background: `url(${DotLineDark}) repeat`,
}));

export const SpaceBetweenContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
}));

export const MediumGrayText = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  lineHeight: "1.25rem",
  color: theme.palette.complementary.grayBlue,

  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));

export const MediumGrayTextBold = styled(MediumGrayText)(({ theme }) => ({
  fontWeight: "bold",
}));

export const PercentageText = styled(Typography)<IProps>(
  ({ theme, percentageValue }: IProps & { theme: Theme }) => ({
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "2rem",
    color:
      percentageValue !== 100
        ? theme.palette.complementary.whiteTransparent
        : theme.palette.success.main,
    display: "flex",

    [theme.breakpoints.up("xl")]: {
      fontSize: "1.75rem",
    },
  })
);

export const PercentageSymbol = styled(PercentageText)(({ theme }) => ({
  color: theme.palette.complementary.grayBlue,
}));

export const PercentageSymbolSmall = styled(PercentageSymbol)(({ theme }) => ({
  fontSize: "1.25rem",
  lineHeight: "1.8rem",

  [theme.breakpoints.up("xl")]: {
    fontSize: "1.25rem",
    lineHeight: "1.8rem",
  },
}));

export const SmallWhiteText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  color: theme.palette.primary.contrastText,
}));

export const PercentageSmallText = styled(PercentageText)<IProps>(
  ({ theme, percentageValue }: IProps & { theme: Theme }) => ({
    fontSize: "1.25rem",
    lineHeight: "1.8rem",
  })
);

export const ThinMainText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1rem",
  color: theme.palette.main.gray,
}));

export const VerticalDividerContainer = styled(Box)(({ theme }) => ({
  width: "2rem",
}));

export const DottedVerticalLine = styled(Box)(({ theme }) => ({
  width: "0.2rem",
  height: "100%",
  background: `url(${DottedVerticalLineDark}) repeat`,
  backgroundSize: "contain",
}));

export const css = {
  percentageText: (percentageValue?: number) => ({
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: "2rem",
    color: (theme: Theme) =>
      percentageValue !== 100
        ? theme.palette.complementary.whiteTransparent
        : theme.palette.success.main,
    display: "flex",
    "&.percentageSmallText": {
      fontSize: "1.25rem",
      lineHeight: "1.8rem",
    },
  }),
  closeButton: {
    position: "absolute",
    right: "-1rem",
    cursor: "pointer",
  },
  iconContainer: {
    position: "absolute",
    background: (theme: Theme) => theme.palette.background.default,
    border: (theme: Theme) => `0.2rem solid ${theme.palette.background.paper}`,
    borderRadius: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.4rem",
    transform: "translateX(-130%)",
  },
  codeText: {
    color: (theme: Theme) => theme.palette.main.gray,
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
    fontWeight: "bold",
  },
  secretContainer: {
    "& svg": {
      position: "absolute",
      right: "2rem",
      width: "2rem",
      height: "4rem",
    },
  },
};
