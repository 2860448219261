import { Theme } from "@mui/material";

export const css = {
  itemRight: (activeGoal: boolean, activeProgram: boolean) => ({
    display: "flex",
    position: "relative",
    justifyContent: "end",
    transition: "0.5s ease-in",
    maxWidth: activeGoal || activeProgram ? "60rem" : "43.5rem",
    marginLeft: "auto",
    minHeight: "8.5rem",
    maxHeight: "8.5rem",
  }),
  itemRightContainer: (theme: Theme) => ({
    display: "flex",
    border: `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    borderRadius: "1.5rem",
    padding: "1rem",
    maxHeight: "8.875rem",
    maxWidth: "38rem",
    minWidth: "38rem",
    backgroundColor: theme.palette.main.darkBlue,
    zIndex: 1,
    cursor: "pointer",
  }),
  rightImg: {
    display: "flex",
    "& img": {
      borderRadius: "0.5rem",
    },
  },
  rightName: (
    activeGoal: boolean,
    activeFactor: boolean,
    activeFilter: boolean,
    filterGoal: boolean,
    theme: Theme
  ) => ({
    display: "flex",
    alignItems: "center",
    fontSize: "1.375rem",
    fontWeight: 700,
    lineHeight: "140%",
    color:
      activeGoal || activeFactor
        ? theme.palette.primary.contrastText
        : !activeFilter
        ? theme.palette.primary.contrastText
        : filterGoal
        ? theme.palette.primary.contrastText
        : theme.palette.complementary.grayBlue,
    transition: "0.5s ease-in",
  }),
  borderFactor: (theme: Theme, activeGoal: boolean, filterGoal: boolean) => ({
    height: "100%",
    background: theme.palette.complementary.grayBorder,
    padding: "0.1rem",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    opacity: activeGoal || filterGoal ? 1 : 0,
    transition: "0.5s ease-in",
    transform:
      activeGoal || filterGoal ? "translateX(0rem)" : "translateX(21rem)",
  }),
  borderRound: (theme: Theme) => ({
    borderRadius: "50%",
    width: "1rem",
    height: "1rem",
    background: theme.palette.primary.contrastText,
    border: `0.25rem solid ${theme.palette.complementary.grayBorder}`,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  }),
  factorContainer: {
    display: "flex",
    alignItems: "start",
    flexDirection: "row",
    gap: "0.2rem",
    justifyContent: "center",
    position: "static",
    top: 0,
    bottom: 0,
  },
  factorsList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    gap: "0.3rem",
  },
  factor: (activeGoal: boolean, activeFactor: boolean, theme: Theme) => ({
    display: "flex",
    color: activeFactor
      ? theme.palette.primary.contrastText
      : theme.palette.complementary.grayBlue,
    background: activeFactor
      ? "rgba(255,255,255,0.3)"
      : theme.palette.complementary.darkBlue,
    borderRadius: "0.5rem",
    border: `0.15rem solid ${
      activeFactor
        ? "rgba(255,255,255,0.3)"
        : theme.palette.complementary.darkBlue
    }`,
    minWidth: "22rem",
    position: "relative",
    paddingLeft: "1rem",
    gap: "0.5rem",
    transition: "0.5s ease-in",
    cursor: "pointer",
    transform:
      activeFactor && activeGoal
        ? "translateX(1rem)"
        : !activeFactor && activeGoal
        ? "translateX(18rem)"
        : activeFactor && !activeGoal
        ? "translateX(1rem)"
        : !activeFactor && !activeGoal
        ? "translateX(21rem)"
        : "translateX(1rem)",
  }),
  factorName: {
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  factorPoint: {
    borderRight: "0.15rem solid black",
    pr: "2rem",
    maxWidth: "3rem",
    minWidth: "3rem",
    fontWeight: 700,
  },
  programCounter: (
    theme: Theme,
    activeGoal: boolean,
    activeProgram: boolean
  ) => ({
    opacity: activeGoal || activeProgram ? "0" : "1",
    display: "flex",
    alignItems: "center",
    transform: "translateX(20rem)",
    width: activeGoal || activeProgram ? 0 : "auto",
  }),
  counter: (theme: Theme, activeGoal: boolean, activeProgram: boolean) => ({
    background: "rgba(255, 255, 255, 0.15)",
    right: 0,
    borderRadius: "50%",
    fontSize: "1.5rem",
    fontWeight: 700,
    maxWidth: activeProgram || activeGoal ? "0" : "1rem",
    maxHeight: "1rem",
    padding: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid rgba(255, 255, 255, 0.3)`,
  }),
};
