import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/programEvents";
import { IGoal } from "SRC/redux/slices/main/slices/goals";
import { IPremier } from "SRC/redux/slices/main/slices/premiers";
import { IProgram } from "SRC/redux/slices/main/slices/programs";
import { IStat } from "SRC/redux/slices/main/slices/stats";
import { IProgramGoal, IStructureElement } from "SRC/types";

export const DATA_PERIOD_VALUE = "202200";
export const DATA_ACTUAL_VALUE = "2"; // Признак актуальности

export const MODEL_UUID = "2d1cc9ce-87da-40b3-80b6-37679e0357fd";

export enum SECTION_NAMES {
  GOALS = "1.0.2 Национальные цели",
  PRIMERS = "1.0.5 Вице-премьеры",
  PROGRAMS = "1.0.9 ГосПрограммы",
  STATS = "1.0.10 Статистика по ГП",
  PROGRAMGOALS = "2.0.1 Показатели ГП",
  STRUCTURE = "2.1.1 Структурные элементы ГП",
  PROGRAMEVENTS = "3.0.1 Мероприятия",
}

export interface IModel<TD> {
  data: TD;
  modelId: string;
  dataTotal: number;
  modelUuid: string;
  debug_info: {
    custom_query: string | boolean;
    custom_query_id: number;
    custom_query_function: string;
  };
}

export interface IModels {
  [SECTION_NAMES.GOALS]: IModel<IGoal>;
  [SECTION_NAMES.PRIMERS]: IModel<IPremier>;
  [SECTION_NAMES.PROGRAMS]: IModel<IProgram>;
  [SECTION_NAMES.STATS]: IModel<IStat>;
  [SECTION_NAMES.PROGRAMGOALS]: IModel<IProgramGoal>;
  [SECTION_NAMES.PROGRAMEVENTS]: IModel<IProgramEvent>;
  [SECTION_NAMES.STRUCTURE]: IModel<IStructureElement>;
}

export enum REQUEST_TYPE {
  DIMENSION_DATA = "dimension_data",
  DATA2 = "data2",
}

export enum FILTER_TYPE {
  AND = "AND",
  OR = "OR",
  STARTER = "STARTER",
}

export enum FILTER_OPERAND_TYPE {
  LEVEL = "LEVEL",
  PARENT = "PARENT",
  EQ = "EQ",
  GE = "GE",
  DIM = "DIM",
  CONST = "CONST",
}

export type TDataFilter = Record<string, string | number | null>;

export interface IOlapRequestBaseParams {
  modelUuid: string;
  section_name?: string;
  filters?: TDataFilter;
}

export interface IOlapParamsData2 extends IOlapRequestBaseParams {
  dimensions?: any; //TODO
  indicators?: any; //TODO
  dashboardUuid?: string;
  includeGaps?: boolean;
  dataRequestCaching?: boolean;
  dataSort?: Record<string, any>[];
  dataFilter?: Record<string, any>;
  dimensionId?: string;
  includeAttributesByCodes?: string[];
}

export interface IOlapRequestData2 {
  type: REQUEST_TYPE.DATA2;
  params: IOlapParamsData2;
}

export type IOlapRequest = IOlapRequestData2 & {
  requestId?: string;
};

export interface IOlapResponseDataData2 {
  data: IModels[SECTION_NAMES];
}

export interface IOlapResponseData2 {
  responses: {
    [id: string]: {
      requestId: string;
      type: REQUEST_TYPE.DATA2;
      data: IOlapResponseDataData2;
    };
  };
}

export type IOlapResponse = IOlapResponseData2;

export enum RATE {
  HIGH = "high",
  LOW = "low",
}
