import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { MutableRefObject, useEffect, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { css } from "SRC/pages/Summary/common/DasboardTables/DashboardTables.styled";
import { ITableDataItem } from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { getCashColumns } from "./cashData";

interface ICashTable {
  forwardRef: MutableRefObject<HTMLElement | undefined>;
  onScroll: (scroll: any) => void;
  tableData: ITableDataItem[];
  setSortBy: (sortBy: any) => void;
}

export const CashTable = ({
  forwardRef,
  onScroll,
  tableData,
  setSortBy,
}: ICashTable) => {
  const theme = useTheme();
  const { program, setSelectedProgram }: IFilters = useFilters();

  const columns: Column[] = useMemo(() => getCashColumns(theme), [theme]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy: baseSortBy,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Box sx={css.tablesWrapper as SxProps}>
      <Box>
        <Typography sx={css.title as SxProps}>Кассовое исполнение</Typography>
      </Box>
      <BaseTable
        columns={columns}
        data={tableData}
        sortedColumns={["План", "Факт"]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={(sortBy) => {
          setSortBy(sortBy);
          baseSortBy(sortBy);
        }}
        tableHeight={css.tableHeight}
        selectedRow={program}
        setSelectedRow={setSelectedProgram}
        scrollHide={true}
        forwardRef={forwardRef}
        onScroll={onScroll}
      />
    </Box>
  );
};
