import {
  Box,
  Grid,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import { BlueCloseButton } from "ASSETS/svg/close";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import executor from "SRC/assets/non-usage/executors/Ministery_RF.svg";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { CuratorInfo } from "SRC/pages/Program/common/GovProgramInfoWidget/CuratorInfo/CuratorInfo";
import { ProgramInfo } from "SRC/pages/Program/common/GovProgramInfoWidget/ProgramInfo/ProgramInfo";
import { IStatistics } from "SRC/redux/slices/gosprogram/slices/statistics";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { IProgram } from "SRC/redux/slices/main/slices/programs";
import themeConfig from "SRC/theme";

import { css, GridContainer, ProgramIcon } from "./GovProgramInfoWidget.styled";

interface IGovProgramInfoWidgetProps {
  programInfo: IProgram;
  statistics: IStatistics;
}

export const GovProgramInfoWidget = ({
  programInfo,
  statistics,
}: IGovProgramInfoWidgetProps) => {
  const { gp_code, gp_icon_url } = programInfo || {};

  const theme = useTheme<typeof themeConfig>();
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const navigate = useNavigate();

  const { calculationMethod }: IFilters = useFilters();

  const programType = Number(programInfo["Комплексная"])
    ? "Комплексная"
    : Number(programInfo["Отраслевая"])
    ? "Отраслевая"
    : Number(programInfo["Секретная"])
    ? "Секретная"
    : "";

  const programInfoData = useMemo(
    () => ({
      id: programInfo.gp_code,
      name: programInfo.gp_name,
      programType: programType,
      icon: programInfo.gp_icon_url,
      programSecret: Number(programInfo["Секретная"]),
      chartData: {
        categories: ["КПМ", "Показатели", "ФП", "ВП"],
        values: [
          roundNumbersToFixed(
            calculationMethod === "effect"
              ? statistics.data["Выполнение мероприятий КПМ, %"] || 0
              : statistics.data["Выполнение мероприятий КПМ 2, %"] || 0,
            0
          ),
          roundNumbersToFixed(
            calculationMethod === "effect"
              ? statistics.data["Количество показателей, выполнено %"] || 0
              : statistics.data["Количество показателей 2, выполнено %"] || 0,
            0
          ),
          roundNumbersToFixed(
            calculationMethod === "effect"
              ? statistics.data["Выполнение мероприятий ФП, %"] || 0
              : statistics.data["Выполнение мероприятий ФП 2, %"] || 0,
            0
          ),
          roundNumbersToFixed(
            calculationMethod === "effect"
              ? statistics.data["Выполнение мероприятий ВП, %"] || 0
              : statistics.data["Выполнение мероприятий ВП 2, %"] || 0,
            0
          ),
        ],
      },
      indicators: {
        total: statistics.data["Количество показателей"],
        done: statistics.data["Количество показателей, выполнено"],
        percentage:
          calculationMethod === "effect"
            ? statistics.data["Количество показателей, выполнено %"] || 0
            : statistics.data["Количество показателей 2, выполнено %"] || 0,
        units: "ед.",
      },
      structureElements: {
        elements: [
          {
            name: "Федеральные проекты",
            amount: statistics.data["Федеральных проектов, кол-во"],
            total: statistics.data["Выполнение мероприятий ФП, план"],
            done: statistics.data["Выполнение мероприятий ФП, факт"],
            percentage:
              calculationMethod === "effect"
                ? statistics.data["Выполнение мероприятий ФП, %"] || 0
                : statistics.data["Выполнение мероприятий ФП 2, %"] || 0,
          },
          {
            name: "Ведомственные проекты",
            amount: statistics.data["Ведомственных проектов, кол-во"],
            total: statistics.data["Выполнение мероприятий ВП, план"],
            done: statistics.data["Выполнение мероприятий ВП, факт"],
            percentage:
              calculationMethod === "effect"
                ? statistics.data["Выполнение мероприятий ВП, %"] || 0
                : statistics.data["Выполнение мероприятий ВП 2, %"] || 0,
          },
          {
            name: "Комплексы процессных мероприятий",
            amount: statistics.data["Комплексы процессных мероприятий, кол-во"],
            total: statistics.data["Выполнение мероприятий КПМ, план"],
            done: statistics.data["Выполнение мероприятий КПМ, факт"],
            percentage:
              calculationMethod === "effect"
                ? statistics.data["Выполнение мероприятий КПМ, %"] || 0
                : statistics.data["Выполнение мероприятий КПМ 2, %"] || 0,
          },
        ],
        total: statistics.data["Структурные элементы, всего"],
        units: "ед.",
        percentage:
          calculationMethod === "effect"
            ? statistics.data["Структурные элементы, %"] || 0
            : statistics.data["Структурные элементы 2, %"] || 0,
      },
      financing: {
        fact: statistics.data["Финансирование факт"],
        plan: statistics.data["Финансирование план"],
        budget: {
          total: statistics.data["Финансирование бюджет план"],
          done: statistics.data["Финансирование бюджет факт"],
        },
        extrabudget: {
          total: statistics.data["Финансирование внебюджет план"],
          done: statistics.data["Финансирование внебюджет факт"],
        },
        units: "млрд.руб.",
      },
    }),
    [statistics, programInfo, programType, calculationMethod]
  );
  const curatorInfoData = useMemo(
    () => ({
      curator: {
        name: programInfo["Вице-премьер"],
        photo: programInfo["Вице-премьер, фото"],
        executorPhoto: executor, //TODO получить урл фото исполнителя госпрограммы
        executorName: programInfo["Министерство"],
      },
      achievementLevel: {
        percent:
          calculationMethod === "effect"
            ? statistics.data["Уровень достижения"]
            : statistics.data["Уровень достижения 2"],
        units: "%",
      },
      events: {
        total: statistics.data["Мероприятий всего"],
        done: statistics.data["Мероприятий выполнено"],
        atRisk: statistics.data["Мероприятий под риском"],
        units: "ед.",
        percentage: statistics.data["Мероприятий выполнено, %"],
      },
      objectsCreated: {
        total: statistics.data["Создано объектов, план"],
        done: statistics.data["Создано объектов, факт"],
        units: "ед.",
        percentage: statistics.data["Создано объектов, %"],
      },
      npa: {
        total: statistics.data["Нормативно-правовые акты, план"],
        done: statistics.data["Нормативно-правовые акты, факт"],
        atRisk: statistics.data["Нормативно правовые акты, под риском"],
        units: "ед.",
        percentage: statistics.data["Нормативно правовые акты, %"],
      },
    }),
    [statistics, programInfo, calculationMethod]
  );

  return (
    <GridContainer container>
      {programInfoData.programSecret && !isExtraLarge ? (
        <Box sx={css.secretContainer}>
          <SecretProgram />
        </Box>
      ) : null}
      {isExtraLarge ? (
        <Box sx={css.iconContainer as SxProps}>
          <Typography sx={css.codeText}>{gp_code}</Typography>
          <img src={gp_icon_url} alt="icon" />
        </Box>
      ) : (
        <ProgramIcon src={gp_icon_url} alt="icon" />
      )}
      <Grid item sm={8}>
        <ProgramInfo data={programInfoData} />
      </Grid>
      <Grid item sm={4}>
        <CuratorInfo curatorData={curatorInfoData} />
      </Grid>
      {isExtraLarge && (
        <Box sx={css.closeButton} onClick={() => navigate(-1)}>
          <BlueCloseButton />
        </Box>
      )}
    </GridContainer>
  );
};
