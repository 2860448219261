import { Box, styled, Typography } from "@mui/material";
import { DottedLine } from "ASSETS/svg/line";

export const InfoCardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  background: theme.palette.complementary.darkBlueLight,
  borderRadius: "1.25rem",
  padding: "1.5rem",

  [theme.breakpoints.up("xl")]: {
    padding: "1.25rem",
  },
}));

export const InfoCardDivider = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.2rem",
  background: `url(${DottedLine}) repeat`,
  backgroundSize: "contain",
  marginTop: "0.5rem",
  marginBottom: "1rem",
}));

export const InfoCardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.25rem",
  lineHeight: "1.5rem",
  color: theme.palette.main.gray,

  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
}));
