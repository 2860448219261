import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";
import { IProgramTopBest } from "SRC/redux/slices/summary/slices/programsTopBest";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IProgramsTopBest {
  fetching: boolean;
  fetched: boolean;
  items: IProgramTopBest[];
  load(): void;
  error: SerializedError | null;
}

export const useProgramTopBest = (): IProgramsTopBest => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programsTopBest = [],
    fetched,
  } = useAppSelector((state) => state.summary.programsTopBest);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(() => {
    if (!fetching) {
      dispatch(actions.programTopBest.get()).then((action) => {
        if (actions.programTopBest.get.rejected.match(action)) {
          setError(action.error);
        }
        return action;
      });
    }
  }, [dispatch, fetching]);

  return {
    fetching,
    fetched,
    items: programsTopBest,
    load,
    error,
  };
};
