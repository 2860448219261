import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCashExecution } from "SRC/redux/slices/gosprogram/hooks/useCashExecution";

export interface ICharacterTab {
  value: string;
}

export const CharacterTab = ({ value }: ICharacterTab) => {
  const { items: cashExecution } = useCashExecution();

  const [characterTabData, setCharacterTabData] = useState<any>([]);
  const { data: data1 } = cashExecution[0] || {};

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      setCharacterTabData(data2);
    }
  }, [data1]);

  const text = value ? value : "";

  return (
    <Typography
      component={"p"}
      sx={{
        fontSize: "1.5rem",
        lineHeight: "2rem",
        padding: "2rem 2.5rem",
        minHeight: "27rem",
      }}
    >
      {cashExecution && text}
    </Typography>
  );
};
