import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { css as tableCss } from "SRC/components/Table/BaseTable/BaseTable.styled";
import { css } from "SRC/pages/Program/common/StructureCard/util/columns.styled";
import theme from "SRC/theme";

interface ICustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
  theme?: Theme;
}

interface IStructureTypeIconProps {
  iconColor?: string;
}

const typeElement: any = {
  federal: `${theme.palette.labelTab.violet}`,
  department: `${theme.palette.labelTab.blue}`,
  complex: `${theme.palette.labelTab.pink}`,
};

const StructureTypeIcon = ({ iconColor }: IStructureTypeIconProps) => (
  <Box sx={css.iconContainer}>
    <Box sx={css.icon(iconColor)} />
  </Box>
);

const getStructureColumns = (theme: Theme) => {
  return [
    {
      Header: " ",
      accessor: "type",
      Cell: (tableProps: ICustomTableProps) => (
        <Box sx={css.type}>
          <StructureTypeIcon
            iconColor={typeElement[tableProps.row.original.type]}
          />
        </Box>
      ),
    },
    {
      Header: "№",
      columns: [
        {
          Header: "",
          accessor: "index",
          Cell: (tableProps: ICustomTableProps) => (
            <Box sx={tableCss.tableCellText}>
              <Typography sx={css.cellTitleContainer}>
                {tableProps.row.original.index}
              </Typography>
            </Box>
          ),
        },
      ],
    },
    {
      Header: "",
      accessor: "abbreviate",
      Cell: (tableProps: ICustomTableProps) => (
        <Box sx={css.abbreviate as SxProps}>
          <Typography
            sx={tableCss.tableCellText}
            align="left"
            className="isSmallOnLarge"
          >
            {tableProps.row.original.abbreviate}
          </Typography>
        </Box>
      ),
    },
    {
      Header: "Наименование структурного элемента",
      columns: [
        {
          Header: "",
          accessor: "name",
          Cell: (tableProps: ICustomTableProps) => (
            <Box sx={tableCss.tableCellText}>
              <Typography sx={css.cellTitleContainer}>
                {tableProps.row.original.name}
              </Typography>
            </Box>
          ),
        },
      ],
    },
    {
      Header: "Количество мероприятий",
      columns: [
        {
          Header: "Всего",
          accessor: "total",
          Cell: (tableProps: ICustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText}
              className="cellContainer"
              align="center"
            >
              {tableProps.row.original.count.total}
            </Typography>
          ),
        },
        {
          Header: "Выполнено",
          accessor: "done",
          Cell: (tableProps: ICustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText}
              align="center"
              className="cellDone"
            >
              {tableProps.row.original.count.done}
            </Typography>
          ),
        },
        {
          Header: "В реализации",
          accessor: "inProgress",
          Cell: (tableProps: ICustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText}
              align="center"
              className="cellProgress"
            >
              {tableProps.row.original.count.inProgress}
            </Typography>
          ),
        },
        {
          Header: "Не начато",
          accessor: "notStart",
          Cell: (tableProps: ICustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText}
              align="center"
              className="cellContainer"
            >
              {tableProps.row.original.count.notStart}
            </Typography>
          ),
        },
        {
          Header: "Под риском",
          accessor: "atRisk",
          Cell: (tableProps: ICustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText}
              align="center"
              className="cellContainer"
            >
              {tableProps.row.original.count.atRisk}
            </Typography>
          ),
        },
        {
          Header: "ЧС",
          accessor: "emergency",
          Cell: (tableProps: ICustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText}
              align="center"
              className="cellContainer"
            >
              {tableProps.row.original.count.emergency}
            </Typography>
          ),
        },
      ],
    },
  ];
};

export { getStructureColumns };
