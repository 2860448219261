import { Theme } from "@mui/material";

export const css = {
  backContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer",
  },
  arrow: {
    width: "0.5rem",
    height: "1rem",
    marginRight: "1rem",
  },
  backText: {
    fontSize: "1rem",
    lineHeight: "1rem",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: "2px",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  eventStatusContainer: {
    position: "absolute",
    width: "1.5rem",
    height: "fit-content",
    left: "-0.75rem",
    top: "50%",
    transform: "translateY(-50%)",

    "& > svg": {
      width: "100%",
      height: "100%",
    },
  },
};
