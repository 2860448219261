import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStructureElements } from "SRC/redux/slices/gosprogram/api/client";
import { IStructureElement } from "SRC/types";

export interface IStructureElementsState {
  items: IStructureElement[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "structureElements/get",
    async (id: string | number) => await getStructureElements(id)
  ),
};

const initialState: IStructureElementsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const slice = createSlice({
  name: "structureElements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state, action) => {
        // @ts-ignore
        state.items = action.payload.data || [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
