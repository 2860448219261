import { Box, Theme } from "@mui/system";

interface IPercentProp {
  theme: Theme;
}

export const Percent = ({ theme }: IPercentProp) => (
  <Box
    component="span"
    sx={{
      color: theme.palette.complementary.grayBlue,
      ml: "2px",
    }}
  >
    %
  </Box>
);
