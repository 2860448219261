import { Box, Grid, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { PhotosModalWrapper } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/PhotosModal/PhotosModalWrapper";
import { IObjectDataItem } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/util/interfaces";
import { getObjectColumns } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/util/objectTableData";
import { VideosModalWrapper } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/VideosModal/VideosModalWrapper";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";

import { css } from "./ObjectTab.styled";

interface IObjectTabProps {
  data: IObjectDataItem[];
}

export const ObjectTab = ({ data }: IObjectTabProps) => {
  const theme = useTheme();

  const [isPhotoModalOpen, setPhotoModalOpen] = useState(false);
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState<string>("");

  const { selectedObjectId, setSelectedObjectId } = useProgramFilters();

  const onPhotoClick = (id: string) => {
    setSelectedObjectId(id);
    setPhotoModalOpen(true);
  };

  const onVideoClick = (id: string) => {
    setSelectedObjectId(id);
    setVideoModalOpen(true);
  };

  const columns: Column[] = useMemo(
    () => getObjectColumns(onPhotoClick, onVideoClick),
    [theme]
  );

  const handlePhotoModalClose = () => {
    setPhotoModalOpen(false);
    setSelectedObjectId("");
  };
  const handleVideoModalClose = () => {
    setSelectedObjectId("");
    setVideoModalOpen(false);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const totalItems = data && data.length > 0 ? 55 : 0;
  const doneItems = data && data.length > 0 ? 48 : 0;
  const inProgressItems = data && data.length > 0 ? 5 : 0;
  const overdueItems = data && data.length > 0 ? 2 : 0;

  return (
    <>
      <Grid container sx={{ gap: "1rem" }}>
        <Grid item>
          <Box
            onClick={() => setFilterValue("")}
            sx={css.flexContainer(filterValue === "")}
          >
            <span className="grayText">Всего </span>
            <span>{totalItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("done")}
            sx={css.flexContainer(filterValue === "done")}
          >
            <Box sx={css.styledDot} className="green" />
            <span className="grayText">Построено </span>
            <span>{doneItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("inProgress")}
            sx={css.flexContainer(filterValue === "inProgress")}
          >
            <Box sx={css.styledDot} className="red" />
            <span className="grayText">Строится </span>
            <span>{inProgressItems}</span>
          </Box>
        </Grid>
        <Grid item>
          <Box
            onClick={() => setFilterValue("overdue")}
            sx={css.flexContainer(filterValue === "overdue")}
          >
            <Box sx={css.styledDot} className="blue" />
            <span className="grayText">Просрочено </span>
            <span>{overdueItems}</span>
          </Box>
        </Grid>
      </Grid>
      <BaseTable
        columns={columns}
        data={data}
        sortedColumns={[
          "Регион/город",
          "Мощность",
          "Строительная готовность",
          "Период реализации",
        ]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={setSortBy}
        tableHeight={"31rem"}
      />
      {selectedObjectId && (
        <>
          <PhotosModalWrapper
            isPhotoModalOpen={isPhotoModalOpen}
            handlePhotoModalClose={handlePhotoModalClose}
          />
          <VideosModalWrapper
            isVideoModalOpen={isVideoModalOpen}
            handleVideoModalClose={handleVideoModalClose}
          />
        </>
      )}
    </>
  );
};
