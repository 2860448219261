import { Box, SxProps, Theme, Typography } from "@mui/material";
import {
  CriticalIcon,
  DoneIcon,
  InProgressIcon,
  NotStartedIcon,
  OutOfDateIcon,
  WarningTriangle,
} from "ASSETS/svg";
import { TableProps, UseTableRowProps } from "react-table";
import {
  css,
  TableCellBigText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";

import { css as eventSectionCss } from "../EventsSection.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
  theme?: Theme;
}

const statusIcons: any = {
  Выполнено: <DoneIcon />,
  "В реализации": <InProgressIcon />,
  "Не начато": <NotStartedIcon />,
  Просрочено: <OutOfDateIcon />,
  "Под риском": <WarningTriangle />,
  ЧС: <CriticalIcon />,
};

const getEventColumns = (theme: Theme) => {
  return [
    {
      Header: " ",
      accessor: "status",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={eventSectionCss.eventStatusContainer as SxProps}>
          {statusIcons[tableProps.row.original.status]}
          {Boolean(tableProps.row.original.atRisk) && <WarningTriangle />}
        </Box>
      ),
    },
    {
      Header: "№",
      accessor: "index",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          style={{ maxWidth: "20rem", minWidth: "5rem" }}
          align="center"
          className="whiteText"
        >
          {tableProps.row.original.index}
        </Typography>
      ),
    },
    {
      Header: "ID мер-ия",
      accessor: "id",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          style={{ maxWidth: "20rem" }}
          align="center"
          className="grayText"
        >
          {tableProps.row.original.id}
        </Typography>
      ),
    },
    {
      Header: "Тип CЭ",
      accessor: "structureElement",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          align="center"
          className="grayText"
          style={{ minWidth: "6rem" }}
        >
          {tableProps.row.original.structureElement}
        </Typography>
      ),
    },
    {
      Header: "Наименование СЭ",
      accessor: "structureElementName",
      Cell: (tableProps: CustomTableProps) => (
        <Typography sx={css.tableCellText} className="eventName whiteText">
          {tableProps.row.original.structureElementName}
        </Typography>
      ),
    },
    {
      Header: "Наименование мероприятия",
      accessor: "name",
      Cell: (tableProps: CustomTableProps) => (
        <Typography sx={css.tableCellText} className="eventName whiteText">
          {tableProps.row.original.name}
        </Typography>
      ),
    },
    {
      Header: "Ед.изм.",
      accessor: "units",
      Cell: (tableProps: CustomTableProps) => (
        <Typography sx={css.tableCellText} align="center" className="grayText">
          {tableProps.row.original.units}
        </Typography>
      ),
    },
    {
      Header: "План",
      accessor: "plan",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText
          align="center"
          sx={{ color: theme.palette.primary.contrastText }}
        >
          {tableProps.row.original.plan}
        </TableCellBigText>
      ),
    },
    {
      Header: "Факт",
      accessor: "fact",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText
          align="center"
          sx={{
            color:
              tableProps.row.original.fact < tableProps.row.original.plan
                ? theme.palette.primary.contrastText
                : theme.palette.primary.contrastText,
          }}
        >
          {tableProps.row.original.fact}
        </TableCellBigText>
      ),
    },
    {
      Header: "Выполнение",
      accessor: "done",
      Cell: (tableProps: CustomTableProps) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TableCellBigText
            align="center"
            sx={{
              color:
                tableProps.row.original.done < 80
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.contrastText,
              maxWidth: "unset",
              margin: "0",
            }}
          >
            {tableProps.row.original.done}
          </TableCellBigText>
          <TableCellBigText
            align="center"
            sx={{
              color: theme.palette.complementary.grayBlue,
              maxWidth: "unset",
              margin: "0",
            }}
          >
            &nbsp;%
          </TableCellBigText>
        </div>
      ),
    },
    {
      Header: "Влияние",
      accessor: "effect",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText
          align="center"
          sx={{ color: theme.palette.primary.contrastText }}
        >
          {tableProps.row.original.effect}
        </TableCellBigText>
      ),
    },
  ];
};

export { getEventColumns };
