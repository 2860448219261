import { Theme } from "@mui/material";

export const css = {
  itemLeft: (theme: Theme) => ({
    background: theme.palette.complementary.darkBlueLight,
    border: `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    padding: "1.5rem 2rem",
    borderRadius: "1.5rem",
    fontSize: "1.375rem",
    fontWeight: 700,
    lineHeight: "130%",
    position: "relative",
    textAlign: "left",
    minHeight: "7.875rem",
    maxHeight: "7.875rem",
    marginRight: "0.5rem",
    cursor: "pointer",
  }),
  leftName: (
    activeProgramGoalItem: boolean,
    activeFilter: boolean,
    filterProgram: boolean,
    theme: Theme
  ) => ({
    color: activeProgramGoalItem
      ? theme.palette.primary.contrastText
      : !activeFilter
      ? theme.palette.primary.contrastText
      : filterProgram
      ? theme.palette.primary.contrastText
      : theme.palette.complementary.grayBlue,
    fontWeight: 700,
    lineHeight: "130%",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    transition: "0.2s ease-in",
  }),
  leftUnit: (theme: Theme) => ({
    color: theme.palette.text.secondary,
  }),
  selectPoint: (
    activeItem: boolean,
    activeFilter: boolean,
    filterProgram: boolean,
    theme: Theme
  ) => ({
    display: "block",
    width: "1rem",
    height: "1rem",
    background: activeItem
      ? theme.palette.primary.contrastText
      : !activeFilter
      ? theme.palette.primary.contrastText
      : filterProgram
      ? theme.palette.primary.contrastText
      : theme.palette.complementary.grayBlue,
    border: `0.25rem solid ${theme.palette.complementary.darkBlue}`,
    borderRadius: "50%",
    position: "absolute",
    right: "-0.6rem",
    top: "50%",
    transform: "translateY(-50%)",
    transition: "0.2s ease-in",
  }),
};
