import { Box, Breadcrumbs, Link, Theme, Typography } from "@mui/material";
import { AtRiskProgram, DspProgram, SecretProgram } from "ASSETS/svg";
import { SmallArrowRight } from "ASSETS/svg/arrow";
import React, { Fragment, useMemo } from "react";
import MethodSwitcher from "SRC/components/MethodSwitcher/MethodSwitcher";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import Tiles from "SRC/pages/Main/common/GovPrograms/Tiles";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useHeaderPrograms } from "SRC/redux/slices/main/hooks/useHeaderPrograms";

import { css } from "./center-layout.styled";

interface ICenterLayoutProps {
  theme: Theme;
  isLargeSize: boolean;
}
export const CenterLayout = ({ theme, isLargeSize }: ICenterLayoutProps) => {
  const { calculationMethod }: IFilters = useFilters();
  const { items: headerPrograms } = useHeaderPrograms();
  const data = useMemo(
    () => headerPrograms[0]?.data[0] || {},
    [headerPrograms]
  );

  return (
    <Fragment>
      <Breadcrumbs aria-label="breadcrumb" separator={<SmallArrowRight />}>
        <Link underline="hover" color="inherit" href="/#/new">
          <Box sx={css.mainPage}>Главная</Box>
        </Link>
        <Box sx={css.currentPage}>Карточки госпрограмм</Box>
      </Breadcrumbs>
      <Box sx={css.headerContainer}>
        <Box sx={css.header}>
          <Box sx={css.title}>Карточки госпрограмм</Box>
          <Box>
            <MethodSwitcher />
          </Box>
        </Box>
        <Box sx={css.header}>
          <Box sx={css.progress}>
            <Box sx={css.progressHeader}>
              <Box sx={css.barName}>Эффективность выполнения программ</Box>
              <Box sx={css.barValue}>
                {calculationMethod === "effect"
                  ? roundNumbersToFixed(getDataSumByCodeGP(data, 1753) || 0, 0)
                  : roundNumbersToFixed(getDataSumByCodeGP(data, 1796) || 0, 0)}
                <span>%</span>
              </Box>
            </Box>
            <ProgressBar
              value={
                calculationMethod === "effect"
                  ? roundNumbersToFixed(getDataSumByCodeGP(data, 1753) || 0, 0)
                  : roundNumbersToFixed(getDataSumByCodeGP(data, 1796) || 0, 0)
              }
              progressColor={theme.palette.pale.green}
              isDarkBg
            />
          </Box>
          <Box sx={css.statuses}>
            <Box sx={css.status}>
              <Box sx={css.statusName}>Всего</Box>
              <Box sx={css.statusCount}>
                {getDataSumByCodeGP(data, 1837) || 0}
              </Box>
            </Box>
            <Box sx={css.status}>
              <Box sx={css.statusIcon}>
                <SecretProgram />
              </Box>
              <Box sx={css.statusName}>Секретные</Box>
              <Box sx={css.statusCount}>
                {getDataSumByCodeGP(data, 1844) || 0}
              </Box>
            </Box>
            <Box sx={css.status}>
              <Box sx={css.statusIcon}>
                <AtRiskProgram />
              </Box>
              <Box sx={css.statusName}>Под риском</Box>
              <Box sx={css.statusCount}>
                {getDataSumByCodeGP(data, 1843) || 0}
              </Box>
            </Box>
            <Box sx={css.status} style={{ display: "none" }}>
              <Box sx={css.statusIcon}>
                <DspProgram />
              </Box>
              <Box sx={css.statusName}>ДСП</Box>
              <Box sx={css.statusCount}>0</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Tiles />
    </Fragment>
  );
};
