import { styled } from "@mui/material";

let height = 0.75;
let trackMargins = 0.25;
let trackHeight = height - trackMargins;
export const ContainerWrapper = () => ({
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "nowrap",
  paddingTop: "2rem",
});
export const LineWrap = styled("div")((props) => ({
  height: `${height}rem`,
  padding: `0.25rem`,
  position: "relative",
  borderRadius: `${height / 2}rem`,
  backgroundColor: props.theme.palette.main.darkBlue,
}));
export const LineWrapAlt = styled(LineWrap)((props) => ({
  backgroundColor: props.theme.palette.complementary.darkBlueLight,
}));
export const LineTrack = styled("div")((props) => ({
  position: "absolute",
  height: `${trackHeight}rem`,
  left: `${trackMargins / 2}rem`,
  top: `${trackMargins / 2}rem`,
  width: `calc(100% - ${trackMargins}rem)`,
  borderRadius: `${height / 2}rem`,
  backgroundColor: props.theme.palette.main.darkBlue,
}));
export const LineTrackAlt = styled(LineTrack)((props) => {
  return {
    backgroundColor: props.theme.palette.complementary.darkBlueLight,
  };
});
const graphLine = styled("div")((props) => {
  return {
    height: `100%`,
    position: "absolute",
    top: 0,
    borderRadius: `${trackHeight / 2}rem`,
  };
});
export const DoneLine = styled(graphLine)((props) => ({
  left: 0,
  backgroundColor: props.theme.palette.success.main,
  zIndex: 1,
}));
