import { AxiosResponse } from "axios";
import { v4 } from "uuid";

import {
  IOlapRequest,
  IOlapRequestData2,
  IOlapResponse,
  IOlapResponseData2,
  IOlapResponseDataData2,
  REQUEST_TYPE,
} from "../scheme/olap";
import client from "./client";

interface IOlapRequestData3 {
  requestId: string;
  type: string;
  params: IOlapRequestData2["params"];
}

// TODO исправить any

interface IOlapResponseDataData3 {
  data: Record<string, any>;
  requestId?: string;
  type?: string;
}

interface IOlapResponseDataData4 {
  data: {
    [key: string]: IOlapResponseDataData3;
  };
}

export interface IOlapResponseData4 {
  responses: {
    [id: string]: {
      requestId: string;
      type: REQUEST_TYPE.DATA2;
      data: {
        [key: string]: IOlapResponseDataData3;
      };
    };
  };
}

export const prepareResponseData2 = (
  response: AxiosResponse<IOlapResponseData2>
): IOlapResponseDataData2 => ({
  data: response.data.responses[0].data.data,
});

export const prepareResponseData3 = (
  response: AxiosResponse<IOlapResponseData2>
): IOlapResponseDataData3 => ({
  data: response.data.responses,
});

export const prepareResponseData4 = (
  response: AxiosResponse<IOlapResponseData4>
): IOlapResponseDataData4 => ({
  data: response.data.responses[0].data[0].data,
});

export const olapRequest = ({ type, requestId, params }: IOlapRequest) => {
  const request = {
    requestId: requestId || v4(),
    type,
    params,
  };

  return client.post<IOlapResponse>("api/data_api/v1.0/olapdata", {
    requests: [request],
  });
};

export const olapRequest2 = (requests: IOlapRequestData3[]) => {
  return client.post<IOlapResponse>("api/data_np_api/v1.0/data", {
    requests: requests,
  });
};

export const olapRequest3 = (requests: IOlapRequestData3[]) => {
  return client.post<IOlapResponseData4>("api/data_np_api/v1.0/data", {
    requests: requests,
  });
};

export const olapRequestData2 = (params: IOlapRequestData2["params"]) =>
  olapRequest({ type: REQUEST_TYPE.DATA2, params }).then((response) =>
    prepareResponseData2(response as AxiosResponse<IOlapResponseData2>)
  );

export const olapRequestData3 = (requests: IOlapRequestData3[]) =>
  olapRequest2(requests).then((response) =>
    prepareResponseData3(response as AxiosResponse<IOlapResponseData2>)
  );

export const olapRequestData4 = (requests: IOlapRequestData3[]) =>
  olapRequest3(requests).then((response) =>
    prepareResponseData4(response as AxiosResponse<IOlapResponseData4>)
  );
