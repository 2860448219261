import { Theme } from "@mui/material";
import BGImage from "ASSETS/svg/backgrounds/mainGraphBg.png";

export const css = {
  container: {
    height: "100%",
    width: "100%",
    backgroundImage: `url(${BGImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "1rem bottom",
    zIndex: 0,
    position: "absolute",
  },
  graph: {
    width: "100%",
    height: "100%",
  },
  tooltipContainer: (cords: [number, number]) => ({
    position: "absolute",
    bottom: {
      sm: `calc(30rem - ${cords[1]}px)`,
      lg: `calc(30rem - ${cords[1] - 100}px)`,
    },
    left: { sm: cords[0], lg: cords[0] < 1800 ? cords[0] : cords[0] - 500 },
    backgroundColor: (theme: Theme) => theme.palette.complementary.darkGrayBlue,
    padding: "1.5rem 2.5rem",
    borderRadius: "16px",
    maxWidth: { sm: "300px", lg: "500px" },
    minWidth: { sm: "300px", lg: "500px" },
    fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
  }),
  tooltipHeader: {
    borderBottom: (theme: Theme) => `2px dashed ${theme.palette.main.darkBlue}`,
    marginBottom: "16px",
  },
  tooltipHeaderLeft: {
    fontSize: "2rem",
    color: (theme: Theme) => theme.palette.main.gray,
    display: "flex",
    gap: "0.5rem",
  },
  tooltipHeaderRight: (value: number) => ({
    fontSize: "1.5rem",
    color: (theme: Theme) =>
      value !== 100 ? theme.palette.error.main : theme.palette.success.main,
  }),
  tooltipHeaderRightPercent: {
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  tooltipText: {
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  tooltipIconWrapper: {
    fontSize: "1.3rem",
    borderRadius: "50%",
    padding: "4px",
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    border: (theme: Theme) =>
      `2px solid ${theme.palette.complementary.darkBlue}`,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    position: "absolute",
    top: "2.5rem",
    left: "-20px",
  },
  tooltipImg: {
    objectFit: "contain",
    width: "2.5rem",
    height: "2.5rem",
    display: "block",
  },
  tooltipBtn: {
    position: "absolute",
    top: "3rem",
    left: "95%",
    width: "2.5rem",
    height: "2.5rem",
    padding: "3px",
    borderRadius: "50%",
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    border: (theme: Theme) =>
      `2px solid ${theme.palette.complementary.darkGrayBlue}`,
  },
  tooltipBtnIcon: {
    fontSize: 26,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    borderRadius: "50%",
    width: "auto",
    height: "auto",
  },
  secretProgram: {
    "& svg": {
      width: "1.5rem",
      height: "1.5rem",
    },
  },
};
