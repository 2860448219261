import { Theme } from "@mui/material";

export const css = {
  tableContainer: (theme: Theme) => ({
    background: `${theme.palette.background.default}`,
    padding: "0.5rem 2.5rem",
    [theme.breakpoints.up("xl")]: { padding: "0.5rem 1.5rem 0.5rem 0.5rem" },
  }),
  tabs: (theme: Theme) => ({
    "& .MuiTabs-root": {
      width: "100%",
    },
    "& .MuiTab-root": {
      fontSize: "1.25rem",
      padding: "1rem",
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTabs-flexContainer": { justifyContent: "space-around" },
  }),
  tabPanel: {
    padding: "0 !important",
  },
};
