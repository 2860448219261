import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { css } from "SRC/pages/Program/common/StructureCard/StructureTabs/StructureTabs.styled";
import { IStructureDataItem } from "SRC/pages/Program/common/StructureCard/util/interfaces";
import { useAppSelector } from "SRC/redux/hooks";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { IStructureElement } from "SRC/types";

interface IStructureElementsTab {
  columns: Column[];
  dataStructure: IStructureElement[];
}

export const StructureElementsTab = ({
  columns,
  dataStructure,
}: IStructureElementsTab) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const { setSelectedStructureRow } = useProgramFilters();
  const { selectedStructureRow } = useAppSelector(
    (state) => state.gosprogram.programFilters
  );
  const data = useMemo<IStructureDataItem[]>(
    () =>
      dataStructure.map((item, index) => {
        return {
          id: item.code,
          index: index + 1,
          name: item.name,
          count: {
            total: item["Мероприятий, всего"],
            done: item["Мероприятий, выполнено"],
            inProgress: item["Мероприятий, в реализации"],
            notStart: item["Мероприятий, не начато"],
            atRisk: item["Мероприятий, под риском"],
            emergency: item["Чрезвычайных ситуаций"],
          },
          type: item["Признак, ВП"]
            ? "department"
            : item["Признак, ФП"]
            ? "federal"
            : item["Признак, КПМ"]
            ? "complex"
            : "",
          abbreviate: item["Признак, ВП"]
            ? "ВП"
            : item["Признак, ФП"]
            ? "ФП"
            : item["Признак, КПМ"]
            ? "КПМ"
            : "",
        };
      }),
    [dataStructure]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const handleStructureElements = (id: string) => {
    setSelectedStructureRow(id);
  };

  return (
    <Box sx={css.tableContainer}>
      <BaseTable
        columns={columns}
        data={data}
        sortedColumns={["Регион/город", "Мощность", "Сроки"]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={setSortBy}
        tableHeight={isExtraLarge ? "28.5rem" : isLarge ? "40rem" : "27rem"}
        onClick={handleStructureElements}
        selectedRow={selectedStructureRow}
        setSelectedRow={setSelectedStructureRow}
      />
    </Box>
  );
};
