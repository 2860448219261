import { Box, Button, SxProps } from "@mui/material";
import { SmallWhiteArrowUp } from "ASSETS/svg";
import theme from "SRC/theme";

import { css } from "./ScrollButton.styled";

interface IScrollButtonProps {
  position: string;
  handleScroll: () => void;
}

export const ScrollButton = ({
  position,
  handleScroll,
}: IScrollButtonProps) => {
  return (
    <Box sx={css.listShadow(position, theme) as SxProps}>
      <Button onClick={handleScroll}>
        <SmallWhiteArrowUp />
      </Button>
    </Box>
  );
};
