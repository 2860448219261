import { Box, SxProps, Typography } from "@mui/material";
import { SmallArrowLeft } from "ASSETS/svg/arrow";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import Counter from "SRC/components/ProgramsCounter/ProgramsCounter";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { EventCard } from "SRC/pages/Program/common/EventCard/EventCard";
import { EventsSection } from "SRC/pages/Program/common/EventsSection/EventsSection";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { StructureCard } from "SRC/pages/Program/common/StructureCard/StructureCard";
import { css } from "SRC/pages/Program/Program.styled";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/useStructureElements";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/programEvents";

import {
  IProgramSummaryContent,
  IProgramSummaryProps,
  IStatus,
} from "../../common/ProgramSummary/interfaces";

export const RightLayout = ({ events }: IProgramSummaryProps) => {
  const [activeTab, setActiveTab] = useState(1);
  const [openEventCard, setOpenEventCard] = useState(false);

  const { items: structures } = useStructureElements();

  const { selectedStatus } = useProgramFilters();

  const eventsTableData = useMemo<IEventItem[] | undefined>(() => {
    if (events && events.length > 0) {
      return events.map((item: IProgramEvent) => ({
        id: item.code,
        name: item.NAME,
        units: item["Ед. измерения результата"],
        structureElement: item.data.find((it) => Number(it.code) === 120)?.text,
        structureElementName: item["Наименование проекта"],
        plan: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 0)?.sum),
          0
        ),
        fact: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 1)?.sum),
          0
        ),
        done: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 2)?.sum),
          0
        ),
        effect: roundNumbersToFixed(
          Number(item.data.find((it) => Number(it.code) === 29)?.sum),
          0
        ),
        status: item.data.find((it) => Number(it.code) === 16)?.sum
          ? "Выполнено"
          : item.data.find((it) => Number(it.code) === 17)?.sum
          ? "Под риском"
          : item.data.find((it) => Number(it.code) === 117)?.sum
          ? "ЧС"
          : item.data.find((it) => Number(it.code) === 14)?.sum
          ? "В реализации"
          : "Не начато",
      }));
    }
  }, [events]);

  const eventsTableDataFiltered = useMemo<IEventItem[] | undefined>(() => {
    if (selectedStatus) {
      return eventsTableData?.filter((it) => it.status === selectedStatus);
    }

    return eventsTableData;
  }, [eventsTableData, selectedStatus]);

  const statuses = useMemo<IStatus[]>(
    () => [
      {
        status: "Выполнено",
        amount:
          eventsTableData?.filter((it) => it.status === "Выполнено").length ||
          0,
      },
      {
        status: "В реализации",
        amount:
          eventsTableData?.filter((it) => it.status === "В реализации")
            .length || 0,
      },
      {
        status: "Не начато",
        amount:
          eventsTableData?.filter((it) => it.status === "Не начато").length ||
          0,
      },
      {
        status: "Под риском",
        amount:
          eventsTableData?.filter((it) => it.status === "Под риском").length ||
          0,
      },
      {
        status: "ЧС",
        amount: eventsTableData?.filter((it) => it.status === "ЧС").length || 0,
      },
    ],
    [eventsTableData]
  );

  const { tabs, components }: IProgramSummaryContent = useMemo(() => {
    const result: IProgramSummaryContent = {
      tabs: [],
      components: [],
    };

    if (structures && structures.length > 0) {
      result.tabs.push({
        value: 0,
        label: "Структурные элементы",
      });

      result.components.push(
        <StructureCard structures={structures} statuses={statuses} />
      );
    }

    if (eventsTableDataFiltered) {
      result.tabs.push({
        value: 1,
        label: "Мероприятия",
      });

      result.components.push(
        <Box sx={{ marginTop: "-1rem" }}>
          {openEventCard ? (
            <>
              <Box
                sx={css.backContainer}
                onClick={() => setOpenEventCard(false)}
              >
                <SmallArrowLeft style={css.arrow} />
                <Typography sx={css.backText as SxProps}>назад</Typography>
              </Box>
              <EventCard />
            </>
          ) : (
            <EventsSection
              items={eventsTableDataFiltered}
              withoutButton
              setOpenEventCard={setOpenEventCard}
              statuses={statuses}
            />
          )}
        </Box>
      );
    }

    return result;
  }, [events, structures, openEventCard, selectedStatus]);

  const handleChangeTab = (
    _onChangeTabEvent: SyntheticEvent,
    newValue: number
  ) => {
    setActiveTab(newValue);
  };

  return (
    <CustomTabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={tabs}
      withBgr
      rightNode={
        <Counter
          totalDataLength={eventsTableData?.length}
          tableDataLength={eventsTableDataFiltered?.length}
          isBig
        />
      }
      components={components}
    />
  );
};
