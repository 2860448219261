import { Box, Button, SxProps } from "@mui/material";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { css } from "./MethodSwitcher.styled";

const MethodSwitcher = () => {
  const { setCalculationMethod, calculationMethod } = useFilters();

  return (
    <Box sx={css.switch}>
      <Button
        sx={css.methodButton(calculationMethod === "effect") as SxProps}
        onClick={() => setCalculationMethod("effect")}
      >
        Эффективность
      </Button>
      <Button
        sx={css.methodButton(calculationMethod === "result") as SxProps}
        onClick={() => setCalculationMethod("result")}
      >
        Результативность
      </Button>
    </Box>
  );
};

export default MethodSwitcher;
