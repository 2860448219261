export const css = {
  ratingContainer: {
    pt: "3rem",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
