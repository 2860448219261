import { styled, Tab, Tabs, Theme } from "@mui/material";
import theme from "SRC/theme";

export const css = {};

export const indicatorTabsContainer = () => ({
  '& .Mui-selected[aria-selected="true"]': {
    color: (theme: Theme) => theme.palette.main.gray,
  },
  "& .Mui-disabled[disabled]": {
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  "& .MuiButtonBase-root": {
    minHeight: "0rem",
    padding: "0rem 0.625rem 0.5rem 0.625rem",
  },
  [theme.breakpoints.up("xl")]: {
    "& .MuiButtonBase-root": {
      minHeight: "3.1rem !important",
    },
  },
  "& .MuiTabs-root": {
    minHeight: "2.3rem",
  },
});

export const IndicatorTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: (theme: Theme) => theme.palette.primary.main,
    borderRadius: "2px 2px 0 0",
    height: "0.25rem",
  },
});

export const IndicatorTab = styled(Tab)({
  fontWeight: "bold",
  fontSize: "1.125rem",
  textTransform: "capitalize",
  maxWidth: "100%",
  color: "#58637b",
  padding: 0,
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.125rem !important",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1,1667rem !important",
  },
});

export const indicatorTabStyles = {
  "&.MuiButtonBase-root": {
    fontSize: "1.125rem",
    padding: "0 0.6rem",
  },
};
