import { Theme } from "@mui/material";

export const css = {
  modalBox: (theme: Theme) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "calc(100% - 5rem)",
    borderRadius: "0.75rem",
    border: `1px solid ${theme.palette.divider}`,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
      minWidth: "unset",
      width: "calc(100% - 5rem)",
    },
    background: theme.palette.background.default,
  }),
};
