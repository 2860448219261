import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import React from "react";
import { FilterValue } from "react-table";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";

import {
  NumOfResultsContainer,
  NumOfResultsLabel,
  NumOfResultsText,
  SearchContainer,
  tableSearchStyled,
} from "./TableSearch.styled";

interface IProps {
  setGlobalFilter: (filterValue: FilterValue) => void;
  globalFilter: any;
  dataLength: number;
}

export const TableSearch = (props: IProps) => {
  const { globalFilter, dataLength, setGlobalFilter } = props;

  const theme = useTheme();

  return (
    <SearchContainer>
      <FormControl
        sx={css.uiSearch}
        variant="filled"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <FilledInput
          startAdornment={
            <InputAdornment position="start">
              <Search
                sx={{
                  color: theme.palette.main.gray,
                  width: "1.25rem",
                  height: "1.25rem",
                  ml: "10px",
                }}
              />
            </InputAdornment>
          }
          className="filledInput"
          sx={tableSearchStyled.filledInput}
          placeholder="Поиск мероприятий"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </FormControl>
      <NumOfResultsContainer>
        <NumOfResultsText>{dataLength}</NumOfResultsText>
        <NumOfResultsLabel>
          Всего
          <br />
          мероприятий
        </NumOfResultsLabel>
      </NumOfResultsContainer>
    </SearchContainer>
  );
};
