import { Box, Typography } from "@mui/material";
import React from "react";
import { CanvasLines } from "SRC/pages/Program/common/CanvasLines/CanvasLines";
import GoalsTab from "SRC/pages/Program/common/GoalsTab/GoalsTab";
import { GovProgramInfoWidget } from "SRC/pages/Program/common/GovProgramInfoWidget/GovProgramInfoWidget";
import { NationalGoalsConnect } from "SRC/pages/Program/common/NationalGoalsConnect/NationalGoalsConnect";
import { ProgramSummary } from "SRC/pages/Program/common/ProgramSummary";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/programEvents";
import { IStatistics } from "SRC/redux/slices/gosprogram/slices/statistics";
import { IGoal } from "SRC/redux/slices/main/slices/goals";
import { IProgram } from "SRC/redux/slices/main/slices/programs";
import { IProgramGoal } from "SRC/types";

import { css } from "./center-layout.styled";

interface ICenterLayoutProps {
  isExtraLarge: boolean;
  programGoals: IProgramGoal[];
  events: IProgramEvent[];
  programInfo: IProgram;
  statistics: IStatistics[];
  goals: IGoal[];
}
interface ICenterLayoutProps {
  isExtraLarge: boolean;
}
export const CenterLayout = ({
  programGoals,
  events,
  isExtraLarge,
  programInfo,
  statistics,
  goals,
}: ICenterLayoutProps) => {
  return (
    <Box sx={css.centerContentWrapper}>
      <Box className="program-card">
        {programInfo && statistics.length > 0 && (
          <GovProgramInfoWidget
            programInfo={programInfo}
            statistics={statistics[0]}
          />
        )}
      </Box>
      <Box className="program-state">
        {isExtraLarge ? (
          <Box sx={css.goalsContainer}>
            <Box sx={css.goalsHeaderContainer}>
              <Box sx={css.goalsCount}>{programGoals.length}</Box>
              <Typography sx={css.goalsTitle}>Цели</Typography>
            </Box>
            <GoalsTab items={programGoals} />
          </Box>
        ) : (
          <ProgramSummary programGoals={programGoals} {...{ events }} />
        )}
      </Box>
    </Box>
  );
};
