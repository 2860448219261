import React, { SyntheticEvent, useMemo, useState } from "react";
import { useParams } from "react-router";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { getInfoRowsEvents } from "SRC/helpers/getInfoRows";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { EventsSection } from "SRC/pages/Program/common/EventsSection/EventsSection";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import GoalsTab from "SRC/pages/Program/common/GoalsTab/GoalsTab";
import { useAppSelector } from "SRC/redux/hooks";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/useStructureElements";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/programEvents";
import { usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import { IStructureElement } from "SRC/types";

import {
  IProgramSummaryContent,
  IProgramSummaryProps,
  IStatus,
} from "./interfaces";

export const ProgramSummary = ({
  events,
  programGoals,
}: IProgramSummaryProps) => {
  const [activeTab, setActiveTab] = useState(1);
  const filters = useAppSelector((state) => state.gosprogram.programFilters);
  const { selectedStructureId, selectedStructureRow } = filters;
  const [openTableModal, setOpenTableModal] = useState(false);
  const { items: structures } = useStructureElements();
  const { items: programs } = usePrograms();
  const { selectedStatus, selectedEventFeature } = useProgramFilters();
  const { id } = useParams();

  const structureElement = useMemo<IStructureElement | undefined>(
    () => structures.find((item) => item.code === selectedStructureRow),
    [selectedStructureRow, structures]
  );
  const eventsTableData = useMemo<IEventItem[] | undefined>(() => {
    if (events && events.length > 0) {
      return events
        .filter(
          (item) =>
            !structureElement ||
            structureElement?.eventsCode?.includes(String(item?.code))
        )
        .map((item: IProgramEvent, index) => ({
          id: item.code,
          index: index + 1,
          name: item.NAME,
          units: item["Ед. измерения результата"],
          structureElement: item.data.find((it) => Number(it.code) === 120)
            ?.text,
          structureElementName: item["Наименование проекта"],
          plan: roundNumbersToFixed(
            Number(item.data.find((it) => Number(it.code) === 0)?.sum),
            0
          ),
          fact: roundNumbersToFixed(
            Number(item.data.find((it) => Number(it.code) === 1)?.sum),
            0
          ),
          done: roundNumbersToFixed(
            Number(item.data.find((it) => Number(it.code) === 2)?.sum),
            0
          ),
          effect: roundNumbersToFixed(
            Number(item.data.find((it) => Number(it.code) === 29)?.sum),
            0
          ),
          atRisk: item.data.find((it) => Number(it.code) === 17)?.sum || 0,
          status: item.data.find((it) => Number(it.code) === 16)?.sum
            ? "Выполнено"
            : item.data.find((it) => Number(it.code) === 117)?.sum
            ? "ЧС"
            : item.data.find((it) => Number(it.code) === 14)?.sum
            ? "В реализации"
            : item.data.find((it) => Number(it.code) === 18)?.sum
            ? "Не начато"
            : "",
          feature: item.data.find((it) => Number(it.code) === 34)?.sum
            ? "Признак_основные"
            : item.data.find((it) => Number(it.code) === 35)?.sum
            ? "Признак_не основные"
            : item.data.find((it) => Number(it.code) === 36)?.sum
            ? "Признак_ОКС онлайн"
            : item.data.find((it) => Number(it.code) === 37)?.sum
            ? "Признак_Реализация в субъектах"
            : item.data.find((it) => Number(it.code) === 38)?.sum
            ? "Признак_СЗП"
            : item.data.find((it) => Number(it.code) === 39)?.sum
            ? "Признак_ФХД"
            : item.data.find((it) => Number(it.code) === 40)?.sum
            ? "Признак_КТ"
            : item.data.find((it) => Number(it.code) === 41)?.sum
            ? "Признак_ОКС"
            : item.data.find((it) => Number(it.code) === 42)?.sum
            ? "Признак_ЛЮГ"
            : item.data.find((it) => Number(it.code) === 43)?.sum
            ? "Признак_СМНП"
            : "",
        }));
    }
  }, [events, selectedStructureId, selectedEventFeature]);

  const eventsTableDataFiltered = useMemo<IEventItem[] | undefined>(() => {
    if (
      selectedStatus &&
      selectedStatus !== "Под риском" &&
      !selectedEventFeature
    ) {
      return eventsTableData?.filter((it) => it.status === selectedStatus);
    } else if (selectedStatus === "Под риском" && !selectedEventFeature) {
      return eventsTableData?.filter((it) => it.atRisk === 1);
    } else if (
      selectedStatus &&
      selectedStatus !== "Под риском" &&
      selectedEventFeature
    ) {
      return eventsTableData?.filter(
        (it) =>
          it.status === selectedStatus && it.feature === selectedEventFeature
      );
    } else if (selectedStatus === "Под риском" && selectedEventFeature) {
      return eventsTableData?.filter(
        (it) => it.atRisk === 1 && it.feature === selectedEventFeature
      );
    } else if (!selectedStatus && selectedEventFeature) {
      return eventsTableData?.filter(
        (it) => it.feature === selectedEventFeature
      );
    }

    return eventsTableData;
  }, [eventsTableData, selectedStatus, selectedEventFeature]);

  const statuses = useMemo<IStatus[]>(
    () => [
      {
        status: "Выполнено",
        amount:
          eventsTableDataFiltered?.filter((it) => it.status === "Выполнено")
            .length || 0,
      },
      {
        status: "В реализации",
        amount:
          eventsTableDataFiltered?.filter((it) => it.status === "В реализации")
            .length || 0,
      },
      {
        status: "Не начато",
        amount:
          eventsTableDataFiltered?.filter((it) => it.status === "Не начато")
            .length || 0,
      },
      {
        status: "Под риском",
        amount:
          eventsTableDataFiltered?.filter((it) => it.atRisk === 1).length || 0,
      },
      {
        status: "ЧС",
        amount:
          eventsTableDataFiltered?.filter((it) => it.status === "ЧС").length ||
          0,
      },
    ],
    [eventsTableDataFiltered]
  );

  const eventsTableDataExport = eventsTableData?.map(
    ({ index, atRisk, feature, ...item }) => item
  );

  const programItem = programs?.filter((item) => item.gp_code === id)[0];
  const programType = Number(programItem?.["Секретная"])
    ? "Секретная"
    : Number(programItem?.["Отраслевая"])
    ? "Отраслевая"
    : Number(programItem?.["Комплексная"])
    ? "Комплексная"
    : " ";

  const infoRowsEvents = useMemo(
    () =>
      getInfoRowsEvents(
        programs?.filter((item) => item.gp_code === id)[0]?.gp_name,
        programType,
        structureElement?.name
      ),
    [programs, id, structureElement]
  );

  const { tabs, components }: IProgramSummaryContent = useMemo(() => {
    const result: IProgramSummaryContent = {
      tabs: [],
      components: [],
    };

    if (programGoals && programGoals.length > 0) {
      result.tabs.push({
        value: 0,
        label: "Показатели ГП",
        numberOfItems: programGoals.length,
      });

      result.components.push(<GoalsTab items={programGoals} />);
    }

    if (eventsTableData && eventsTableDataExport && eventsTableDataFiltered) {
      result.tabs.push({
        value: programGoals && programGoals.length === 0 ? 0 : 1,
        label: "Мероприятия",
        numberOfItems: eventsTableData.length,
      });

      result.components.push(
        <EventsSection
          items={eventsTableDataFiltered}
          downloadData={eventsTableDataExport}
          openTableModal={openTableModal}
          setTableModalOpen={setOpenTableModal}
          statuses={statuses}
          infoRows={infoRowsEvents}
        />
      );
    }

    return result;
  }, [
    events,
    programGoals,
    openTableModal,
    selectedStatus,
    selectedStructureId,
    selectedEventFeature,
  ]);

  const handleChangeTab = (
    _onChangeTabEvent: SyntheticEvent,
    newValue: number
  ) => {
    setActiveTab(newValue);
  };

  const { headings, mergeCells, wscols } = useTableExportParams(
    eventsTableDataExport || [],
    "programEvents"
  );

  return (
    <CustomTabs
      value={activeTab}
      onChange={handleChangeTab}
      tabs={tabs}
      components={components}
      withTableButtons={
        activeTab === (programGoals && programGoals.length === 0 ? 0 : 1)
      }
      buttonsStyle={{ alignSelf: "center" }}
      isTableModalOpen={openTableModal}
      setTableModalOpen={setOpenTableModal}
      downloadData={eventsTableDataExport}
      fileName="Мероприятия"
      wscols={wscols}
      headings={headings}
      mergeCells={mergeCells}
      infoRows={infoRowsEvents}
    />
  );
};
