import { Theme } from "@mui/material";

export const css = {
  container: (theme: Theme) => ({
    position: "relative",
    display: "flex",
    gap: "0.5rem",
    width: "100%",
    maxWidth: "19.75rem",
    height: "9.25rem",
    background: theme.palette.background.paper,
    borderRadius: "1rem 8.5rem 8.5rem 1rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      height: "6.25rem",
      maxWidth: "18.75rem",
    },
    [theme.breakpoints.up("lg")]: {
      height: "20rem",
      maxWidth: "21.125rem",
      borderRadius: "1rem 1rem 8.5rem 8.5rem",
      flexDirection: "column",
      padding: "0.5rem",
    },
  }),
  icon: (theme: Theme) => ({
    gap: "0.25rem",
    position: "absolute",
    maxHeight: "5.5rem",
    top: "50%",
    transform: "translateY(-50%)",
    left: "-1rem",
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    backgroundColor: theme.palette.main.darkBlue,
    border: `2px solid ${theme.palette.background.paper}`,
    borderRadius: "1.5rem",
    alignItems: "center",
    padding: "0.2rem",
    justifyContent: "space-between",
    "& span": {
      fontSize: "1.125rem",
      fontWeight: "bold",
      color: `${theme.palette.text.primary}`,
      [theme.breakpoints.up("lg")]: {
        padding: "0.5rem",
      },
    },
    "& img": {
      height: "1.5rem",
      width: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      height: "2rem",
      maxWidth: "4.5rem",
      width: "100%",
      position: "unset",
      top: 0,
      transform: "translateY(0%)",
    },
  }),
  title: (theme: Theme) => ({
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    fontSize: "1rem",
    fontWeight: 700,
    [theme.breakpoints.up("lg")]: {
      lineHeight: "1.25rem",
    },
  }),
  titleContainer: (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    flex: "0 0 45%",
    position: "relative",
    left: "2.5rem",
    [theme.breakpoints.up("lg")]: {
      left: "0",
      justifyContent: "initial",
      flex: "1",
    },
  }),
  doughnut: (theme: Theme) => ({
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "6rem",
    },
    [theme.breakpoints.up("lg")]: {
      height: "13rem",
      width: "13rem",
      margin: "0 auto",
    },
  }),
  secretProgram: {
    "& svg": {
      width: "1rem",
      height: "1rem",
    },
  },
};
