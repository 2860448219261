import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getModalNewSidebar } from "SRC/redux/slices/gosprogram/api/client";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";

export interface IModalSideBar {
  data: Record<string, any>[];
  requestId: string;
  type: string;
}

export interface IModalSideBarState {
  items: IModalSideBar[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "modalSideBar/get",
    async (params: IEventParams) => await getModalNewSidebar(params)
  ),
};

const initialState: IModalSideBarState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isModalSideBar = (array: any): array is IModalSideBar[] =>
  !!array?.length;

const slice = createSlice({
  name: "modalSideBar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IModalSideBarState, action) => {
          state.items = isModalSideBar(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
