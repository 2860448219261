import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";
import { OpenInFull } from "SRC/assets/svg/index";
import TableExportButton from "SRC/components/TableExportButton/TableExportButton";
import {
  IGovProgramDataItemWithoutIcon,
  IMinistriesDataItem,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import {
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItemWithoutId,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { Range } from "xlsx";

import { css } from "./TableActionsButtons.styled";

interface ITableActionsButtonsProps {
  setOpen?: (value: boolean) => void;
  open?: boolean;
  downloadData:
    | IGovProgramDataItemWithoutIcon[]
    | IMinistriesDataItem[]
    | IVicePremiersDataItem[]
    | IEventItem[]
    | IGeneralInfoDataItemWithoutIcon[]
    | IGeneralInfoPremiersDataItemWithoutId[];
  fileName: string;
  wscols: Record<string, number>[];
  headings: Record<string, string | object>[];
  mergeCells?: Range[] | undefined;
  buttonsStyle?: CSSProperties;
  infoRows?: Record<string, string>[][];
}

export const TableActionsButtons = ({
  setOpen,
  open,
  downloadData,
  fileName,
  wscols,
  headings,
  mergeCells,
  buttonsStyle,
  infoRows,
}: ITableActionsButtonsProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={{ ...css.buttonsContainer, ...buttonsStyle }}>
      <Button sx={css.button}>
        <TableExportButton
          csvData={downloadData}
          fileName={fileName}
          wscols={wscols}
          headings={headings}
          mergeCells={mergeCells}
          infoRows={infoRows}
        />
      </Button>
      {!match && !open && (
        <Button sx={css.button} onClick={() => setOpen?.(true)}>
          <OpenInFull />
        </Button>
      )}
    </Box>
  );
};
