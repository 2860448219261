import { createSlice } from "@reduxjs/toolkit";
import { ECalculationMethod } from "SRC/constants/globals";

export enum EGridTabs {
  PROGRAMS = "PROGRAMS",
  PREMIERS = "PREMIERS",
  REGIONS = "REGIONS",
  DISTRICTS = "DISTRICTS",
}

export interface IPeriod {
  value: null | Date;
  filterValue: string;
}

export interface IFiltersState {
  program: string;
  vp: string;
  ministry: string;
  period: IPeriod;
  complexGridTab: EGridTabs;
  calculationMethod: ECalculationMethod;
}

const initialState: IFiltersState = {
  program: "",
  vp: "",
  ministry: "",
  period: {
    value: new Date(),
    filterValue: "202200",
  },
  complexGridTab: EGridTabs.PROGRAMS,
  calculationMethod: ECalculationMethod.EFFECT,
};

const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    clear: () => initialState,
    selectProgram: (state, action: { payload: string }) => {
      state.program = action.payload;
    },
    selectVP: (state, action: { payload: string }) => {
      state.vp = action.payload;
    },
    selectMinistry: (state, action: { payload: string }) => {
      state.ministry = action.payload;
    },
    selectPeriod: (state, action: { payload: IPeriod }) => {
      state.period = action.payload;
    },
    setComplexGridTab: (state, action: { payload: EGridTabs }) => {
      state.complexGridTab = action.payload;
    },
    setCalculationMethod: (state, action: { payload: ECalculationMethod }) => {
      state.calculationMethod = action.payload;
    },
  },
});

export default slice;
