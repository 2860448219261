import { Box, Grid, styled, Typography } from "@mui/material";

export const BigBlueText = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "bold",
  color: theme.palette.main.blue,
  display: "flex",
}));

export const ColumnContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "0 1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));

export const GridItemCenterText = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  alignSelf: "center",
}));

export const GridItemWithBorder = styled(GridItemCenterText)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.main.darkBlue}`,
  textAlign: "center",
  paddingBottom: "0.5rem",
}));

export const LightBgrContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  background: theme.palette.complementary.darkBlue,
  borderRadius: "1rem",
  padding: "1.5rem",

  [theme.breakpoints.up("xl")]: {
    padding: "1.25rem",
  },
}));
