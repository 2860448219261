import { Box, Grid, SxProps, Typography } from "@mui/material";
import React from "react";

import { css } from "../EventCorrection.styled";

interface IDataItem {
  period: string;
  previousValue: number | string;
  presentValue: number | string;
  difference: {
    value: string;
    color: string;
  };
}

interface IProps {
  data: IDataItem[];
  isTime?: boolean;
}

export const Correction = ({ data, isTime }: IProps) => {
  return (
    <>
      <Grid container sx={css.container as SxProps}>
        <Grid item xs={2} sx={css.column}>
          <Typography className="gray">Корректировка</Typography>
          <Typography className="gray" style={{ lineHeight: "1.2rem" }}>
            Отчетный период
          </Typography>
        </Grid>
        {data?.map((item: IDataItem, i: number) => {
          return (
            <Grid
              item
              container
              xs={isTime ? 3 : 2}
              sx={{ gap: isTime ? "0.5rem" : 0 }}
            >
              <Grid item xs={4}>
                <Typography className="gray">было</Typography>
                <Typography className="blue marginTop">
                  {item.previousValue}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="gray">стало</Typography>
                <Typography className="white marginTop">
                  {item.presentValue}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={css.differenceContainer}>
                <Typography
                  sx={css.difference}
                  className={
                    item.difference.color === "green"
                      ? "greenDifference"
                      : "redDifference"
                  }
                >
                  {item.difference.value}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={css.stepContainer}>
                  <Box
                    sx={css.stepPoint as SxProps}
                    className={
                      item.difference.color === "green"
                        ? "stepGreen"
                        : "stepRed"
                    }
                  />
                  {i === data.length - 1 ? (
                    <Box sx={css.stepLine as SxProps} className="smallLine" />
                  ) : (
                    <Box
                      sx={css.stepLine as SxProps}
                      style={{
                        borderTopLeftRadius: i === 0 ? "1rem" : 0,
                        borderBottomLeftRadius: i === 0 ? "1rem" : 0,
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography className="white">{item.period}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container sx={css.container} style={{ gap: "1rem" }}>
        <Grid item sx={{ display: "flex", gap: "1rem" }}>
          <Box sx={css.legendPoint as SxProps} className="stepGreen" />
          <Typography className="white">Улучшилось</Typography>
        </Grid>
        <Grid item sx={{ display: "flex", gap: "1rem" }}>
          <Box sx={css.legendPoint as SxProps} className="stepRed" />
          <Typography className="white">Ухудшилось</Typography>
        </Grid>
      </Grid>
    </>
  );
};
