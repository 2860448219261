import "moment/locale/ru";

import { CircularProgress, Theme } from "@mui/material";
import { setAuthHeader } from "CORE/api/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { Header } from "SRC/components/Header/Header";
import { MainNew } from "SRC/pages/MainNew/MainNew";
import { ProgramsBoard } from "SRC/pages/ProgramsBoard/ProgramsBoard";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useMain } from "SRC/redux/slices/main/hooks/useMain";
import { actions as goalsActions } from "SRC/redux/slices/main/slices/goals";
import { actions as headerPrograms } from "SRC/redux/slices/main/slices/headerPrograms";
import ministries, {
  actions as ministriesActions,
} from "SRC/redux/slices/main/slices/ministries";
import { actions as premiersActions } from "SRC/redux/slices/main/slices/premiers";
import { actions as programsActions } from "SRC/redux/slices/main/slices/programs";
import { actions as spheres } from "SRC/redux/slices/main/slices/spheres";
import { actions2 as sumSpheres } from "SRC/redux/slices/main/slices/spheres";
import { actions as statsActions } from "SRC/redux/slices/main/slices/stats";
import { actions as premiersActionsFull } from "SRC/redux/slices/summary/slices/premiers";
import { actions as programsActionsFull } from "SRC/redux/slices/summary/slices/programs";
import { GOVPROGRAMS, MAIN, NEW, PROGRAM_DETAIL, SUMMARY } from "SRC/urls";

import { PageContent } from "../components/wrappers/PageContent/PageContent";
import usePrevious from "../hooks/usePrevious";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { actions as tokenActions } from "../redux/slices/auth/tokenSlice";
import { actions as userActions } from "../redux/slices/auth/userSlice";
import { Content, Overlay, RootContainer } from "./App.styled";
import { Main } from "./Main/Main";
import { Program } from "./Program/Program";
import { Summary } from "./Summary/Summary";

moment.locale("ru");

const App = () => {
  const dispatch = useAppDispatch();
  const [isInit, setIsInit] = useState(false);
  const { fetching: tokenFetching, access_token } = useAppSelector(
    (state) => state.auth.token
  );
  const { fetching: userFetching, user } = useAppSelector(
    (state) => state.auth.user
  );
  const prevTokenFetching = usePrevious(tokenFetching);
  const prevUserFetching = usePrevious(userFetching);
  const { loading, hasError } = useMain();

  let location = useLocation();

  const {
    clear,
    program,
    vp,
    period,
    setCalculationMethod,
    ministry,
  }: IFilters = useFilters();

  useEffect(() => {
    clear();
  }, [location, clear]);

  const tokenInfo = localStorage.getItem("tokenInfo");

  useEffect(() => {
    const username = user?.login || "";

    if (tokenInfo && username) {
      const { refresh_token, access_token } = JSON.parse(tokenInfo);

      const accessTokenInfo = atob(access_token.split(".")[1]);
      const { exp } = JSON.parse(accessTokenInfo);
      const expDate = new Date(exp * 1000);

      const updateTimeOffset = expDate.getTime() - Date.now();

      setTimeout(
        () => dispatch(tokenActions.refreshToken({ username, refresh_token })),
        updateTimeOffset
      );
    } else {
      dispatch(tokenActions.getToken());
    }
  }, [dispatch, tokenInfo]);

  useEffect(() => {
    if (prevTokenFetching && !tokenFetching) {
      if (access_token) {
        setAuthHeader(access_token);
      }

      if (!user) {
        dispatch(userActions.getAuthorized());
      } else {
        setIsInit(true);
      }
    }
  }, [access_token, dispatch, tokenFetching, prevTokenFetching, user]);

  useEffect(() => {
    if (prevUserFetching && !userFetching) {
      setIsInit(true);
    }
  }, [prevUserFetching, userFetching]);

  useEffect(() => {
    if (user && !hasError) {
      dispatch(
        statsActions.get({
          gp_code: program || null,
          vp_code: vp || null,
          period: period,
        })
      );
      dispatch(ministriesActions.get(period));
      dispatch(programsActions.get(period));
      dispatch(programsActionsFull.get(period));
      dispatch(premiersActions.get(period));
      dispatch(premiersActionsFull.get(period));
      dispatch(goalsActions.get(period));
      dispatch(headerPrograms.get(period));
      dispatch(spheres.get(period));
      dispatch(sumSpheres.get(period));
    }
    setCalculationMethod("effect");
  }, [user, program, vp, dispatch, ministry, period, hasError]);

  if (tokenFetching || userFetching || !isInit) {
    return (
      <RootContainer
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Header />
        <Content>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Content>
      </RootContainer>
    );
  }

  return (
    <RootContainer
      sx={(theme: Theme) => ({
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up("xl")]: {
          width: "100vw",
          height: "19vw",
        },
      })}
    >
      <Header />
      <Content>
        {user ? (
          <PageContent>
            {loading ? (
              <Overlay>
                <CircularProgress />
              </Overlay>
            ) : (
              <Routes>
                <Route path={MAIN} element={<MainNew />} />
                {/* <Route path={NEW} element={<MainNew />} /> */}
                <Route path={GOVPROGRAMS} element={<ProgramsBoard />} />
                <Route path={PROGRAM_DETAIL} element={<Program />} />
                <Route path={SUMMARY} element={<Summary />} />
                <Route path="*" element={<Navigate to={MAIN} />} />
              </Routes>
            )}
          </PageContent>
        ) : (
          <Overlay sx={{ position: "fixed", height: "100vh" }}>
            Ошибка авторизации
          </Overlay>
        )}
      </Content>
    </RootContainer>
  );
};

export default App;
