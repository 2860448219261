import { styled, Typography } from "@mui/material";

export const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "6.875rem",
}));

export const Arrow = styled("img")(({ theme }) => ({
  width: "0.5rem",
  height: "0.5rem",
}));

export const Button = styled("div")(({ theme }) => ({
  width: "1.5rem",
  height: "1.5rem",
  background: theme.palette.main.darkBlue,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

export const PagesCountText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.125rem",
  lineHeight: "1rem",
  display: "flex",
  alignItems: "flex-end",
  color: theme.palette.complementary.grayBlue,
}));

export const PagesCountTextActive = styled(PagesCountText)(({ theme }) => ({
  fontSize: "1.375rem",
  lineHeight: "1.375rem",
  color: theme.palette.primary.contrastText,
  marginRight: 5,
}));
