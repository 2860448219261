import { Box, Typography } from "@mui/material";
import React from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import {
  BigWhiteText,
  css,
  MediumGrayTextBold,
  PercentageSymbol,
  SpaceBetweenContainer,
} from "SRC/pages/Program/common/GovProgramInfoWidget/GovProgramInfoWidget.styled";

interface IProps {
  doneOf: number;
  totalOf: number;
  percentage: number;
  withoutPercent?: boolean;
}

const SimpleInfo = (props: IProps) => {
  const { doneOf, totalOf, percentage, withoutPercent } = props;
  return (
    <SpaceBetweenContainer>
      <Box sx={{ display: "flex", mb: "0.5rem" }}>
        <BigWhiteText>{doneOf}</BigWhiteText>
        <MediumGrayTextBold
          style={{
            lineHeight: "2rem",
          }}
        >
          &nbsp; / {totalOf}
        </MediumGrayTextBold>
      </Box>
      {!withoutPercent && (
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={css.percentageText(roundNumbersToFixed(percentage, 0) || 0)}
          >
            {roundNumbersToFixed(percentage, 0) || 0}&nbsp;
          </Typography>
          <PercentageSymbol>%</PercentageSymbol>
        </Box>
      )}
    </SpaceBetweenContainer>
  );
};

export default SimpleInfo;
