import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { DoughnutPale } from "SRC/components/Charts";
import { css } from "SRC/pages/MainNew/common/Analitics/Population/Population.styled";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IPopulationData } from "SRC/types/analitics";

export const Population = () => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);
  const populationData = useMemo<IPopulationData>(
    () => ({
      positive: data["Опросы населения, да"],
      negative: data["Опросы населения, нет"],
      percent:
        (data["Опросы населения, да"] / data["Опросы населения, нет"]) * 100 ||
        0,
    }),
    [data]
  );
  return (
    <Box sx={css.container}>
      <Box sx={css.title}>Удовлетворенность результатами ГП</Box>
      <Box sx={css.chartContainer}>
        <Box sx={css.doughnut}>
          <DoughnutPale success={populationData?.positive || 0} />
        </Box>
        <Box sx={css.infoContainer}>
          <Box sx={css.info}>
            <Box sx={css.infoItem}>
              <Box sx={css.circle(true)}></Box>
              <Box sx={css.number}>
                {populationData?.positive}
                <span>%</span>
              </Box>
            </Box>
            <Box sx={css.infoItem}>
              <Box sx={css.circle(false)}></Box>
              <Box sx={css.number}>
                {populationData?.negative}
                <span>%</span>
              </Box>
            </Box>
          </Box>
          <Box sx={css.info}>
            <Box sx={css.infoItem}>
              <Box sx={css.circle(true)}></Box>
              <Box sx={css.statusName}>Да</Box>
            </Box>
            <Box sx={css.infoItem}>
              <Box sx={css.circle(false)}></Box>
              <Box sx={css.statusName}>Нет</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
