import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStats } from "SRC/redux/slices/main/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

export interface IStat {
  data: {
    "Количество ГП": number;
    "Мероприятий всего": number;
    "Создано объектов": number;
    "Уровень достижения": number;
    "Уровень достижения 2": number;
    "Объектов под риском": number;
    "Опросы населения, да": number;
    "Финансирование план": number;
    "Финансирование факт": number;
    "Опросы населения, нет": number;
    "Мероприятий выполнено": number;
    "Мероприятий выполнено, %": number;
    "Создано объектов, план": number;
    "Создано объектов, факт": number;
    "Создано объектов, %": number;
    "Мероприятий под риском": number;
    "Количество показателей": number;
    "Мероприятия под риском, %": number;
    "Финансирование под риском": number;
    "Нормативно правовые акты, %": number;
    "Нормативно правовые акты, под риском": number;
    "Финансирование бюджет план": number;
    "Финансирование бюджет факт": number;
    "Структурные элементы, всего": number;
    "Финансирование под риском, %": number;
    "Федеральных проектов, кол-во": number;
    "Ведомственных проектов, кол-во": number;
    "Нормативно-правовые акты, план": number;
    "Нормативно-правовые акты, факт": number;
    "Финансирование внебюджет план": number;
    "Финансирование внебюджет факт": number;
    "Выполнение мероприятий ВП, план": number;
    "Выполнение мероприятий ВП, %": number;
    "Выполнение мероприятий ФП, план": number;
    "Выполнение мероприятий КПМ, план": number;
    "Выполнение мероприятий КПМ, %": number;
    "Выполнение мероприятий ВП, факт": number;
    "Выполнение мероприятий ФП, факт": number;
    "Выполнение мероприятий ФП, %": number;
    "Выполнение мероприятий КПМ, факт": number;
    "Комплексы процессных мероприятий, кол-во": number;
  };
}

export interface IStatsState {
  items: IStat[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export interface IStatParams {
  gp_code: string | number | null;
  vp_code: string | number | null;
  period: IPeriod;
}

export const extraActions = {
  get: createAsyncThunk(
    "stats/get",
    async (params: IStatParams) => await getStats(params)
  ),
};

const initialState: IStatsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIStat = (array: any): array is IStat[] => !!array?.length;

const slice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IStatsState, action) => {
        state.items = isIStat(action.payload.data) ? action.payload.data : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
