import { Box } from "@mui/material";
import theme from "SRC/theme";

import { css } from "./CounterBox.styled";

interface ICounterBoxProps {
  position: string;
  count: number;
}

export const CounterBox = ({ position, count }: ICounterBoxProps) => {
  return <Box sx={css.counterBox(theme, position)}>{count}</Box>;
};
