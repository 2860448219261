export const css = {
  carouselContainer: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  innerContainer: {
    whiteSpace: "nowrap",
    transition: "transform 0.3s",
    height: "100%",
  },
  carouselItemContainer: (itemWidth: number) => ({
    display: "inline-flex",
    height: "100%",
    width: `${itemWidth}%`,
  }),
};
