import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAuthorized, IUser } from "./api/client";

export interface IUserState {
  user?: IUser;
  fetching: boolean;
}

export const initialState: IUserState = {
  user: undefined,
  fetching: false,
};

export const extraActions = {
  getAuthorized: createAsyncThunk(
    "auth/user/authorized",
    async () => await getAuthorized()
  ),
};

const slice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.getAuthorized.pending, (state) => {
        state.fetching = true;
      })
      .addCase(extraActions.getAuthorized.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.fetching = false;
      })
      .addCase(extraActions.getAuthorized.rejected, (state) => {
        state.fetching = false;
        localStorage.removeItem("tokenInfo");
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
export const reducer = slice.reducer;

export default slice;
