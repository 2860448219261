import { Theme } from "@mui/material";

export const css = {
  counterBox: (theme: Theme, position: string) => ({
    display: "flex",
    padding: "1rem 1.5rem",
    background: theme.palette.complementary.darkBlue,
    position: "absolute",
    right: 0,
    top: position === "top" ? "-1.5rem" : "",
    bottom: position === "bottom" ? "-1.5rem" : "",
    zIndex: position === "top" ? 1 : position === "bottom" ? 2 : "",
    borderRadius: "50%",
    fontSize: "1.5rem",
    fontWeight: 700,
  }),
};
