import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { getNationalGoalsColumns } from "SRC/pages/Main/common/NationalGoalsTable/tableData";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { useGoals } from "SRC/redux/slices/main/hooks/useGoals";

import { INationalGoalsDataItem } from "../ComplexGrid/util/interfaces";

export const NationalGoalsTable = () => {
  const theme = useTheme();
  const { items } = useGoals();
  const { goal, setSelectedGoal, filteredGoals }: IFilters = useFilters();

  const columns: Column[] = useMemo(
    () => getNationalGoalsColumns(theme),
    [theme]
  );

  const data = useMemo<INationalGoalsDataItem[]>(
    () =>
      items
        .filter((goal) => filteredGoals.includes(goal.NT_CODE))
        .map((goal, index) => ({
          id: goal.NT_CODE,
          icon: require(`ASSETS/icons/nationals/icon${index + 1}.gif`).default,
          goalTitle: goal.NAME,
          achievementLevel: goal.data["Уровень достижения"],
          numOfPrograms: goal.data["Количество ГП"],
        })),
    [items, filteredGoals]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <BaseTable
      columns={columns}
      data={data}
      sortedColumns={[
        "Уровень достижения",
        "Количество мероприятий по ГП",
        "Количество ОЗР",
        "Уровень достижения Национальной цели",
        "Количество Госпрограмм",
      ]}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      setSortBy={setSortBy}
      withBigRows
      selectedRow={goal}
      setSelectedRow={setSelectedGoal}
    />
  );
};
