import { Box, SxProps } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "SRC/pages/MainNew/common/Analitics/ProgramList/ProgramList.styled";
import TilesItemElement from "SRC/pages/MainNew/common/GovPrograms/TilesItemElement";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

export const ProgramList = () => {
  const { items } = usePrograms();
  const {
    program,
    filteredPrograms,
    setSelectedProgram,
    vp,
    setSelectedGoal,
    period,
    calculationMethod,
  }: IFilters = useFilters();

  const navigate = useNavigate();

  const toggleSelection = useCallback(
    (code: typeof program) => () => {
      if (!filteredPrograms.includes(code)) {
        setSelectedGoal("");
      }
      setSelectedProgram(program === code ? "" : code);
    },
    [program, setSelectedProgram, setSelectedGoal, filteredPrograms]
  );

  const programs = useMemo(
    (): JSX.Element[] =>
      items.map((item: TProgram) => (
        <Box sx={css.elementSX as SxProps} key={item.gp_code}>
          <TilesItemElement
            {...item}
            secretProgram={Number(item["Секретная"])}
            active={
              program
                ? program === item.gp_code
                : filteredPrograms.includes(item.gp_code)
            }
            onClick={toggleSelection(item.gp_code as typeof program)}
          />
        </Box>
      )),
    [
      items,
      filteredPrograms,
      program,
      toggleSelection,
      period,
      calculationMethod,
    ]
  );

  return <Box sx={css.container}>{programs}</Box>;
};
