import { Box, Grid, SxProps, Typography } from "@mui/material";
import { SmallWhiteArrowDown, SmallWhiteArrowUp } from "ASSETS/svg/arrow";
import { DoubleDownArrow, DoubleUpArrow } from "ASSETS/svg/arrow";
import React, { useState } from "react";
import ProgressDoughnutChart from "SRC/components/Charts/ProgressDoughnut/ProgressDoughnutChart";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

import { css } from "./RectangleWithCircle.styled";

interface Props {
  achievementLevel: number;
  name: string;
  icon: string;
  plan: number;
  fact: number;
  active: boolean;
  programId: string | number;
}

export const RectangleWithCircle = ({
  active,
  name,
  achievementLevel = 0,
  fact,
  icon,
  plan,
  programId,
}: Props) => {
  const [isHintVisible, setHintVisible] = useState(false);

  const hintText = () => {
    if (achievementLevel < 20) {
      return "Низкорезультативная госпрограмма";
    } else if (achievementLevel < 50) {
      return "Результативность ниже среднего";
    } else if (achievementLevel < 80) {
      return "Результативность выше среднего";
    } else {
      return "Высокорезультативная госпрограмма";
    }
  };

  const performanceIndicatorIcon = () => {
    if (achievementLevel < 20) {
      return <DoubleDownArrow style={css.performanceArrow} />;
    } else if (achievementLevel < 50) {
      return <SmallWhiteArrowDown style={css.performanceArrow} />;
    } else if (achievementLevel < 80) {
      return <SmallWhiteArrowUp style={css.performanceArrow} />;
    } else {
      return <DoubleUpArrow style={css.performanceArrow} />;
    }
  };
  const onPointerEnter = () => setHintVisible(true);
  const onPointerLeave = () => setHintVisible(false);

  return (
    <Box sx={css.container(active) as SxProps}>
      <Box sx={css.heading}>
        <Grid alignItems={"center"} flexWrap={"nowrap"} container>
          <Box sx={css.iconContainer}>
            <Typography sx={css.idText}>{programId}</Typography>
            <img src={icon} alt="icon" style={css.icon} />
          </Box>
          <Typography sx={css.programName}>{name}</Typography>
        </Grid>
        <Box
          sx={css.upArrowContainer}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
        >
          {performanceIndicatorIcon()}
        </Box>
        {isHintVisible && (
          <Box sx={css.hintContainer}>
            <Typography sx={css.hintText}>{hintText()}</Typography>
          </Box>
        )}
      </Box>
      <Grid sx={css.circleGraph} container justifyContent={"center"}>
        <ProgressDoughnutChart total={100} value={achievementLevel} withDarkBgr>
          <Typography sx={css.achievementText} color={"text.secondary"}>
            Уровень
            <br />
            достижения
          </Typography>
        </ProgressDoughnutChart>
      </Grid>
      <Box sx={{ margin: "1rem 0 1.5rem 0" }}>
        <Typography
          textAlign={"center"}
          sx={{ fontSize: "1.125rem" }}
          color={"text.primary"}
          variant="h4"
        >
          Касса
        </Typography>
        <Grid container justifyContent={"space-around"}>
          <Grid>
            <Typography
              sx={css.h4}
              textAlign={"center"}
              variant="h4"
              color={"text.secondary"}
            >
              {roundNumbersToFixed(plan, 1)}
            </Typography>
            <Typography
              sx={{ fontSize: "1rem" }}
              textAlign={"center"}
              component="div"
              color={"text.primary"}
            >
              План
            </Typography>
          </Grid>
          <Grid>
            <Typography
              sx={css.h4}
              textAlign={"center"}
              variant="h4"
              color={fact < 80 ? "error.main" : "success.main"}
            >
              {roundNumbersToFixed(fact, 1)}
            </Typography>
            <Typography
              sx={{ fontSize: "1rem" }}
              textAlign={"center"}
              component="div"
              color={"text.primary"}
            >
              Факт
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
