import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSubjects } from "SRC/redux/slices/gosprogram/api/client";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";

export interface ISubject {
  data: Record<string, any>;
  requestId: string;
  type: string;
}

export interface ISubjectsState {
  items: any;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "subjects/get",
    async (params: IEventParams) => await getSubjects(params)
  ),
};

const initialState: ISubjectsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isSubjects = (array: any): array is ISubject[] => !!array?.length;

const slice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: ISubjectsState, action) => {
        state.items = isSubjects(action.payload.data)
          ? action.payload.data
          : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
