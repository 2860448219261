import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 400,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  unitTitle: {
    fontSize: "0.875rem",
    color: (theme: Theme) => theme.palette.complementary.lightWhite,
  },
};
