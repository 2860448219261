import { Box, styled, Typography } from "@mui/material";

export const CuratorSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  width: "calc(100% - 2rem)",
  gap: "1rem",
}));

export const Guide = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "4.8rem",
}));

export const CuratorPhoto = styled("img")(({ theme }) => ({
  width: "4.8rem",
  height: "4.8rem",
  borderRadius: "50%",
  border: `2px solid ${theme.palette.main.gray}`,

  [theme.breakpoints.up("xl")]: {
    width: "4rem",
    height: "4rem",
  },
}));

export const CuratorNameContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "1.5rem",
  justifyContent: "space-between",
  gap: "0.5rem",
}));

export const CuratorText = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  lineHeight: "1.5rem",
  color: theme.palette.main.gray,

  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
}));

export const css = {
  curatorChartContainer: {
    width: "12rem",
    height: "12rem",
    margin: "auto",
  },
  guideContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
};
