import { Box, CircularProgress, Modal, SxProps } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Overlay } from "SRC/pages/App.styled";
import { css } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/ObjectTab.styled";
import { VideosModal } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/VideosModal/VideosModal";
import { useObjectVideos } from "SRC/redux/slices/gosprogram/hooks/useObjectVideos";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { actions as objectVideosActions } from "SRC/redux/slices/gosprogram/slices/objectVideos";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

interface IVideosModalWrapperProps {
  isVideoModalOpen: boolean;
  handleVideoModalClose(): void;
}

export const VideosModalWrapper = ({
  isVideoModalOpen,
  handleVideoModalClose,
}: IVideosModalWrapperProps) => {
  const { period }: IFilters = useFilters();
  const { fetching, fetched, load, error } = useObjectVideos();
  const { selectedObjectId } = useProgramFilters();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetching && !fetched && !error && selectedObjectId) {
      load({
        id: selectedObjectId,
        period: period.filterValue,
      });
    }
  }, [fetching, fetched, error, selectedObjectId]);

  useEffect(() => {
    dispatch(
      objectVideosActions.get({
        id: selectedObjectId,
        period: period.filterValue,
      })
    );
  }, [dispatch, selectedObjectId, period]);

  return (
    <Modal
      open={isVideoModalOpen}
      onClose={handleVideoModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={css.modalContainer as SxProps}>
        {fetching ? (
          <Box sx={{ width: "100%", height: "100%" }}>
            <Overlay>
              <CircularProgress />
            </Overlay>
          </Box>
        ) : (
          <VideosModal handleVideoModalClose={handleVideoModalClose} />
        )}
      </Box>
    </Modal>
  );
};
