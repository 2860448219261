import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 400,
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  chartContainer: {
    display: "flex",
  },
  doughnut: (theme: Theme) => ({
    display: "flex",
    background: (theme: Theme) => theme.palette.charts.darkBlueBackground,
    borderRadius: "50%",
    [theme.breakpoints.up("md")]: {
      minHeight: "11.25rem",
      minWidth: "11.25rem",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "11rem",
      minWidth: "11rem",
    },
  }),
  chart: {
    display: "flex",
    justifyContent: "center",
    flex: "0 0 50%",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    gap: "1rem",
  },
  info: {
    display: "flex",
    gap: "1rem",
  },
  infoItem: {
    display: "flex",
    gap: "0.375rem",
    alignItems: "baseline",
  },
  circle: (circleType: boolean) => ({
    width: "0.75rem",
    height: "0.75rem",
    background: (theme: Theme) =>
      circleType ? theme.palette.pale.green : theme.palette.pale.red,
    borderRadius: "50%",
  }),
  number: {
    fontSize: "1.25rem",
    fontWeight: 400,
    color: (theme: Theme) => theme.palette.primary.contrastText,
    "& span": {
      fontSize: "0.875rem",
      color: (theme: Theme) => theme.palette.complementary.lightWhite,
      display: "flex",
      ml: "0.25rem",
    },
    display: "flex",
    alignItems: "center",
  },
  statusName: {
    fontSize: "1rem",
    color: (theme: Theme) => theme.palette.primary.contrastText,
    fontWeight: 400,
  },
};
