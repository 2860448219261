import { Box, SxProps, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import themeConfig from "SRC/theme";

import { getOption } from "./options";
import { css } from "./RadialPolarChart.styled";

interface IProps {
  categories: string[];
  values: number[];
}

const RadialPolarChart = ({ categories, values }: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const options = useMemo(
    () => getOption(categories, values, theme),
    [theme, categories, values]
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.graphContainer as SxProps}>
        <ReactECharts option={options} style={css.graph} />
      </Box>
    </Box>
  );
};

export default RadialPolarChart;
