import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import {
  Column,
  FilterValue,
  HeaderGroup,
  Row,
  SortingRule,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { BaseTable } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { GovProgramsFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/GovProgramsFilters";
import { govProgramsTabStyled } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/GovProgramsTab.styled";
import {
  IGovProgramDataItem,
  IGovProgramDataItemWithoutIcon,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";

interface IGovProgramsTabProps {
  tableFilter: string;
  setFilter: Dispatch<
    SetStateAction<"Отраслевая" | "Секретная" | "Комплексная" | "">
  >;
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
  columns: Column[];
  data: IGovProgramDataItem[];
  getTableProps: (propGetter?: TablePropGetter<any>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  headerGroups: Array<HeaderGroup<any>>;
  footerGroups: Array<HeaderGroup<any>>;
  rows: Array<Row<any>>;
  prepareRow: (row: Row<any>) => void;
  setSortBy: (sortBy: Array<SortingRule<any>>) => void;
  program: string;
  setSelectedProgram: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  downloadData: IGovProgramDataItemWithoutIcon[];
  infoRows: Record<string, string>[][];
}

export const GovProgramsTab = ({
  tableFilter,
  setFilter,
  globalFilter,
  setGlobalFilter,
  columns,
  data,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  setSortBy,
  program,
  setSelectedProgram,
  isTableModalOpen,
  setTableModalOpen,
  downloadData,
  infoRows,
}: IGovProgramsTabProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();

  const { headings, mergeCells, wscols } = useTableExportParams(
    downloadData,
    "govPrograms"
  );

  const RenderTable = () => {
    return (
      <Box sx={govProgramsTabStyled.container}>
        <Box sx={govProgramsTabStyled.actionsContainer}>
          {(match || isTableModalOpen) && (
            <GovProgramsFilters
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              setFilter={setFilter}
              tableFilter={tableFilter}
            />
          )}
          {isTableModalOpen && (
            <TableActionsButtons
              open={isTableModalOpen}
              setOpen={setTableModalOpen}
              downloadData={downloadData}
              fileName="Государственные программы"
              wscols={wscols}
              headings={headings}
              mergeCells={mergeCells}
              infoRows={infoRows}
            />
          )}
        </Box>
        <BaseTable
          columns={columns}
          data={data}
          sortedColumns={[
            "Уровень достижения",
            "План",
            "Факт",
            "Под риском",
            "Показатели",
          ]}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          footerGroups={footerGroups}
          rows={rows}
          prepareRow={prepareRow}
          setSortBy={setSortBy}
          tableHeight={isTableModalOpen ? "calc(100vh - 17rem)" : "46rem"}
          onDoubleClick={(id) => navigate(`/program/${id}`)}
          selectedRow={program}
          setSelectedRow={setSelectedProgram}
        />
      </Box>
    );
  };

  return (
    <>
      <RenderTable />
      {!match && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Государственные программы"
        >
          <RenderTable />
        </TableModal>
      )}
    </>
  );
};
