import React, { useState } from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { RatingTab } from "SRC/pages/Summary/common/Rating/RatingTab/RatingTab";
import { dataBest, dataWorst } from "SRC/pages/Summary/common/Rating/util/data";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";

interface IRatingTabsProps {
  topBestData: IRatingTopBestData[];
  topWorstData: IRatingTopWorstData[];
}
export const RatingTabs = ({ topBestData, topWorstData }: IRatingTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const components = [
    <RatingTab data={topBestData} />,
    <RatingTab data={topWorstData} />,
  ];
  const tabs = [
    {
      value: 0,
      label: "Лидирующие",
    },
    {
      value: 1,
      label: "Отстающие",
    },
  ];
  return (
    <CustomTabs
      value={activeTab}
      onChange={(event, newValue) => {
        setActiveTab(newValue);
      }}
      tabs={tabs}
      components={components}
      borderType={"dotted"}
      styleContainer={{ fontSize: "1.5rem" }}
    />
  );
};
