import { useCallback } from "react";
import { useHeaderPrograms } from "SRC/redux/slices/main/hooks/useHeaderPrograms";
import { IStatParams } from "SRC/redux/slices/main/slices/stats";

import { useGoals } from "./useGoals";
import { useMinistries } from "./useMInistries";
import { usePremiers } from "./usePremiers";
import { usePrograms } from "./usePrograms";
import { useSpheres } from "./useSpheres";
import { useStats } from "./useStats";

export interface IMainState {
  loading: boolean;
  loaded: boolean;
  init(params: IStatParams): void;
  hasError: any;
}

export const useMain = (): IMainState => {
  const {
    fetching: loadingGoals,
    load: loadGoals,
    fetched: loadedGoals,
    error: errorGoals,
  } = useGoals();
  const {
    fetching: loadingMinistries,
    load: loadMinistries,
    fetched: loadedMinistries,
    error: errorMinistries,
  } = useMinistries();
  const {
    fetching: loadingPremiers,
    load: loadPremiers,
    fetched: loadedPremiers,
    error: errorPremiers,
  } = usePremiers();
  const {
    fetching: loadingPrograms,
    load: loadPrograms,
    fetched: loadedPrograms,
    error: errorProgramms,
  } = usePrograms();
  const {
    fetching: loadingStats,
    load: loadStats,
    fetched: loadedStats,
    error: errorStats,
  } = useStats();
  const {
    fetching: loadingHeaderPrograms,
    load: loadHeaderPrograms,
    fetched: loadedHeaderPrograms,
    error: errorHeaderPrograms,
  } = useHeaderPrograms();
  const {
    fetching: loadingSpheres,
    load: loadSpheres,
    fetched: loadedSpheres,
    error: errorSpheres,
  } = useSpheres();

  const loading =
    loadingGoals ||
    loadingMinistries ||
    loadingPremiers ||
    loadingPrograms ||
    loadingStats ||
    loadingHeaderPrograms ||
    loadingSpheres;
  const loaded =
    loadedGoals &&
    loadedMinistries &&
    loadedPremiers &&
    loadedPrograms &&
    loadedStats &&
    loadedHeaderPrograms &&
    loadedSpheres;
  const hasError =
    errorMinistries ||
    errorProgramms ||
    errorGoals ||
    errorPremiers ||
    errorStats ||
    errorHeaderPrograms ||
    errorSpheres;
  const init = useCallback(
    (params) => {
      const { period } = params;
      if (!loading) {
        loadGoals(period);
        loadMinistries(period);
        loadPremiers(period);
        loadPrograms(period);
        loadStats(params);
        loadHeaderPrograms(params);
        loadSpheres(params);
      }
    },
    [
      loading,
      loadGoals,
      loadMinistries,
      loadPremiers,
      loadPrograms,
      loadStats,
      loadHeaderPrograms,
      loadSpheres,
    ]
  );

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
