import { SxProps } from "@mui/material";
import React, { useState } from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { Correction } from "SRC/pages/Program/common/EventCard/EventCorrection/Correction/Correction";

import { css } from "./EventCorrection.styled";

const valueData = [
  {
    period: "Март 2021",
    previousValue: 70,
    presentValue: 75,
    difference: {
      value: "+5",
      color: "green",
    },
  },
  {
    period: "Октябрь 2021",
    previousValue: 75,
    presentValue: 74,
    difference: {
      value: "-1",
      color: "red",
    },
  },
  {
    period: "Декабрь 2021",
    previousValue: 74,
    presentValue: 73,
    difference: {
      value: "-1",
      color: "red",
    },
  },
  {
    period: "Январь 2021",
    previousValue: 73,
    presentValue: 80,
    difference: {
      value: "+7",
      color: "green",
    },
  },
];

const timeData = [
  {
    period: "Март 2021",
    previousValue: "31.12.2021",
    presentValue: "29.12.2021",
    difference: {
      value: "-2",
      color: "green",
    },
  },
  {
    period: "Октябрь 2021",
    previousValue: "29.12.2021",
    presentValue: "30.12.2021",
    difference: {
      value: "+1",
      color: "red",
    },
  },
  {
    period: "Декабрь 2021",
    previousValue: "30.12.2021",
    presentValue: "30.03.2022",
    difference: {
      value: "+89",
      color: "red",
    },
  },
];

export const EventCorrection = () => {
  const [activeTab, setActiveTab] = useState(0);

  const components = [
    <Correction data={valueData} />,
    <Correction data={timeData} isTime />,
  ];

  const tabs = [
    {
      value: 0,
      label: "Значение",
    },
    {
      value: 1,
      label: "Сроки",
    },
  ];

  return (
    <CustomTabs
      value={activeTab}
      onChange={(event, newValue) => {
        setActiveTab(newValue);
      }}
      tabs={tabs}
      components={components}
      style={css.tabs as SxProps}
      stylePanel={css.tabPanel}
      widthFull={true}
      borderType={"solid"}
    />
  );
};
