export const css = {
  connectWrapper: {
    position: "relative",
    paddingTop: "50px",
    display: "flex",
    width: "100%",
  },
  itemsLeftContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "31rem",
    position: "relative",
    maxHeight: "56rem",
    flex: "0 0 40%",
  },
  linesConnect: (height: number | undefined) => ({
    display: "flex",
    flex: 1,
    canvas: {
      width: "100%",
      height: `${height}px`,
    },
  }),
  itemsLeftList: {
    overflowY: "scroll",
    direction: "rtl",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "3.5rem 0 3.5rem 1.375rem",
    scrollBehavior: "smooth",
  },
  itemsRightList: {
    display: "flex",
    flex: "0 0 30%",
    flexDirection: "column",
    gap: "0.5rem",
    justifyContent: "center",
  },
};
