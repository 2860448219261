import { Theme } from "@mui/material";

import { selectorMixin } from "../SummaryComplexGrid/SummaryComplexGrid.styled";

export const css = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "1rem",
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    borderBottom: "2px solid transparent",
  },
  selectorsWrapper: {
    display: "flex",
    flex: 1,
    gap: "1rem",
    justifyContent: "end",
    width: "100%",
  },
  label: {
    fontSize: "0.8rem",
    lineHeight: "2em",
    color: "text.secondary",
  },
  indicator: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
  },
  selector: selectorMixin,
};
