import { Theme } from "@mui/material";
import { graphic } from "echarts";

const getFormattedValuesArray = (values: number[], theme: Theme) =>
  values.map((item: number) => ({
    value: item,
    itemStyle: {
      color: new graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color:
            item < 100
              ? theme.palette.charts.whiteTransparent
              : theme.palette.charts.greenTransparent,
        },
        {
          offset: 1,
          color: "transparent",
        },
      ]),
    },
  }));

export const getOption = (
  categories: string[],
  values: number[],
  theme: Theme
) => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  polar: {
    radius: ["15%", "95%"],
  },
  radiusAxis: {
    max: 100,
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: theme.palette.charts.grayTransparent,
      },
    },
    axisLabel: {
      color: theme.palette.main.gray,
      formatter: "{value} %",
      showMinLabel: false,
      showMaxLabel: false,
    },
  },
  angleAxis: {
    type: "category",
    data: categories,
    startAngle: 75,
    axisTick: {
      show: false,
    },
    axisLabel: {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
      fontSize: "1rem",
      margin: 0,
    },
    axisLine: {
      lineStyle: {
        color: theme.palette.complementary.darkBlue,
        width: 3,
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: theme.palette.complementary.darkBlue,
        width: 3,
      },
    },
  },
  tooltip: {},
  series: {
    type: "bar",
    data: getFormattedValuesArray(values, theme),
    coordinateSystem: "polar",
    tooltip: {
      valueFormatter: (value: number) => value + " %",
    },
  },
  backgroundColor: "transparent",
  animation: true,
});
