import { Box } from "@mui/material";
import { useMemo } from "react";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/MainNew/common/Analitics/Financing/Financing.styled";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import theme from "SRC/theme";
import { IFinancingData } from "SRC/types/analitics";

export const Financing = () => {
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);

  const financingData = useMemo<IFinancingData>(
    () => ({
      extrabudgetary: {
        plan: data["Финансирование внебюджет план"],
        fact: data["Финансирование внебюджет факт"],
      },
      budget: {
        plan: data["Финансирование бюджет план"],
        fact: data["Финансирование бюджет факт"],
      },
      totalFinancing: {
        plan: data["Финансирование план"],
        fact: data["Финансирование факт"],
      },
    }),
    [data]
  );
  return (
    <Box sx={css.container}>
      <Box sx={css.item}>
        <Box sx={css.title}>
          Бюджет
          <Box sx={css.unitTitle}>млрд ₽</Box>
        </Box>
        <ProgressBar
          value={
            roundNumbersToFixed(
              (financingData?.budget.fact * 100) / financingData?.budget.plan,
              1
            ) || 0
          }
          circleLabel={financingData?.budget.fact}
          bottomLeftLabel={0}
          bottomRightLabel={financingData?.budget.plan}
          withLabel
          progressColor={theme.palette.pale.blue}
        />
      </Box>
      <Box sx={css.item}>
        <Box sx={css.title}>
          Внебюджет
          <Box sx={css.unitTitle}>млрд ₽</Box>
        </Box>
        <ProgressBar
          value={
            roundNumbersToFixed(
              (financingData?.extrabudgetary.fact * 100) /
                financingData?.extrabudgetary.plan,
              1
            ) || 0
          }
          circleLabel={financingData?.extrabudgetary.fact}
          bottomLeftLabel={0}
          bottomRightLabel={financingData?.extrabudgetary.plan}
          withLabel
          progressColor={theme.palette.pale.blue}
        />
      </Box>
    </Box>
  );
};
