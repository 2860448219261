import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";
import { getHeaderPrograms } from "SRC/redux/slices/main/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
export interface IHeaderPrograms {
  data: Record<string, any>;
  requestId: string;
  type: string;
}

export interface IHeaderProgramsState {
  items: IHeaderPrograms[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "headerPrograms/get",
    async (period: IPeriod) => await getHeaderPrograms(period)
  ),
};

const initialState: IHeaderProgramsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isHeaderPrograms = (array: any): array is IHeaderPrograms[] =>
  !!array?.length;

const slice = createSlice({
  name: "headerPrograms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IHeaderProgramsState, action) => {
          state.items = isHeaderPrograms(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
