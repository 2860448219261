import { Theme } from "@mui/material";

export const css = {
  uiProgressBarStyle: (progressColor?: string) => ({
    backgroundColor: (theme: Theme) => theme.palette.background.default,
    borderRadius: 10,
    margin: "auto",
    maxWidth: "100%",
    width: "100%",
    display: "flex",
  }),
  progressLineFirst: (value: number, progressColor?: string) => ({
    height: 8,
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) =>
      progressColor ? progressColor : theme.palette.success.light,
  }),
  progressLineSecond: (value: number, progressColor?: string) => ({
    height: 8,
    width: `${value}%`,
    borderRadius: 4,
    backgroundColor: (theme: Theme) =>
      progressColor ? progressColor : theme.palette.error.light,
  }),
  labelsContainer: {
    display: "flex",
    width: "100%",
    position: "relative",
    marginTop: "0.2rem",

    span: {
      color: (theme: Theme) => theme.palette.charts.whiteTransparent,
    },
  },
  firstLabel: (value: number) => ({
    width: `${value}%`,
  }),
  secondLabel: (value: number) => ({
    width: `${value}%`,
  }),
  totalLabel: {
    position: "absolute",
    right: 0,
  },
};
