import { Theme } from "@mui/material";

export const css = {
  tableHeaderWrapper: {
    display: "flex",
    justifyContent: "space-between",
    height: "96px",
    gap: "1rem",
    marginBottom: "1.5rem",
  },
  tableTitle: {
    flex: "1 0 80%",
    padding: "0.75rem 2rem",
    color: (theme: Theme) => theme.palette.text.onAccent,
  },
  divider: {
    backgroundImage:
      "linear-gradient(to right, #2E364A 33%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "bottom",
    backgroundSize: "1rem 0.4rem",
    backgroundRepeat: "repeat-x",
    height: "7px",
    marginBottom: "0.7rem",
  },
  ratingTabs: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
  rightLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
  },
};
