import { Box, Button, Grid, styled, Theme, Typography } from "@mui/material";

interface IProps {
  disabled?: boolean;
}

export const StatusCardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  gap: "0.5rem",
  flex: 1,
}));

export const ButtonGridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  padding: "0 20px",
  flex: 1,
}));

export const ButtonStyled = styled(Button)<IProps>(
  ({ disabled, theme }: IProps & { theme: Theme }) => ({
    width: "100%",
    height: "2.5rem",
    color: theme.palette.primary.contrastText,
    background: theme.palette.main.blue,
    borderRadius: theme.shape.borderRadius,
    fontSize: "1rem !important",
    lineHeight: "1rem !important",
    fontWeight: "bold",
    textTransform: "none",
    cursor: disabled ? "default" : "pointer",
    marginBottom: "1rem",

    [theme.breakpoints.up("lg")]: {
      fontSize: "0.8rem !important",
      lineHeight: "0.8rem !important",
    },
  })
);

export const TableContainer = styled(Box)(({ theme }) => ({
  maxHeight: "57.2rem",
  marginTop: "1rem",
}));

export const SelectedElemFilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const SelectedElemLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  lineHeight: "0.75rem",
  color: theme.palette.complementary.grayBlue,
}));

export const SelectedElemInputContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "0.5rem 2.5rem 0.5rem 1rem",
  background: theme.palette.complementary.darkBlue,
  border: "1px solid rgba(255, 255, 255, 0.1)",
  borderRadius: "8px",
  marginTop: "0.25rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  maxHeight: "5.75rem",
  overflow: "hidden",
}));

export const SelectedElemInputText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: theme.palette.primary.contrastText,
}));

export const SelectedElemInputImage = styled("img")(({ theme }) => ({
  width: "0.73rem",
  height: "0.73rem",
  position: "absolute",
  right: "1rem",
  cursor: "pointer",
}));

export const css = {
  btnHintContainer: {
    position: "absolute",
    bottom: "95%",
    width: "70%",
    left: "-15%",
  },
  hint: {
    background: (theme: Theme) => theme.palette.background.paper,
    boxShadow: "0px 4px 8px -4px rgba(1, 59, 121, 0.08)",
    borderRadius: "8px",
    padding: "1rem",
    fontSize: "0.7rem",
    position: "relative",

    "&::before": {
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      top: "100%",
      left: "50%",
      border: ".5rem solid transparent",
      borderBottom: "none",
      borderTopColor: (theme: Theme) => theme.palette.background.paper,
      filter: "drop-shadow(0 0.2rem 0.06rem rgba(0, 0, 0, .1))",
      transform: "translateX(-50%)",
    },
  },
};
