import { Box, SxProps, Typography, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import themeConfig from "SRC/theme";

import { css } from "./DoughnutChart.styled";
import { getOption } from "./options";

interface IProps {
  success: number;
}

const DoughnutChart = ({ success }: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const options = useMemo(() => getOption(success, theme), [theme, success]);

  return (
    <Box sx={css.container}>
      <Box sx={css.textContainer as SxProps}>
        <Typography sx={css.symbol}>%</Typography>
        <Typography sx={css.text}>{roundNumbersToFixed(success, 1)}</Typography>
      </Box>
      <ReactECharts option={options} style={css.graph} />
    </Box>
  );
};

export default DoughnutChart;
