import { Theme } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/elements/Percent/Percent";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getSatisfactionColumns = (theme: Theme) => {
  return [
    {
      Header: "Удовлетворенность населения",
      accessor: "populationSatisfaction",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.populationSatisfactionColumn.cellWrapperDashboard}>
          <TableCellBigText
            sx={css.populationSatisfactionColumn.cellText(
              theme,
              tableProps.row.original.populationSatisfaction
            )}
          >
            {`${tableProps.row.original.populationSatisfaction}`}
            <Percent theme={theme} />
          </TableCellBigText>
          <ProgressBar
            value={tableProps.row.original.populationSatisfaction || 0}
          />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.populationSatisfaction + sum,
            0
          );

          return roundNumbersToFixed(total / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.populationSatisfactionColumn.footerWrapperDashboard}>
            <TableFooterText
              sx={css.performanceColumn.footerText(theme, average)}
            >
              {`${average}`}
              <Percent theme={theme} />
            </TableFooterText>
            <ProgressBar value={average} />
          </div>
        );
      },
    },
  ];
};

export { getSatisfactionColumns };
