export const styles = {
  wrapper: {
    margin: "1.3rem 0 0 0",
    justifyContent: "space-between",
  },
  elementName: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
};
