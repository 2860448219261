import { Box, styled, Theme } from "@mui/material";

export const PopulationBox = styled(Box)({
  flex: "0 0 40%",
  paddingLeft: 0,
  flexDirection: "column",
  display: "flex",
  justifyContent: "space-between",
  rowGap: "0.5rem",
});

export const populationWrapStatistic = () => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100%",
});

export const populationScreenStatistic = (theme: Theme) => ({
  backgroundColor: theme.palette.main.darkBlue,
  padding: "1.5rem",
  borderRadius: "0.5rem",
  height: "100%",
  "& svg": {
    flexShrink: "1",
    position: "relative",
    top: "8px",
    marginRight: "30px",
    fill: theme.palette.success.main,
  },
  [theme.breakpoints.only("xs")]: {
    padding: "1rem",
  },
});

export const css = {
  heading: {
    pb: 4,
    borderBottom: (theme: Theme) => `3px dotted ${theme.palette.main.darkBlue}`,
  },
  tabsHeading: {
    borderBottom: 1,
    borderColor: "divider",
    "& .MuiTab-root": {
      color: (theme: Theme) => theme.palette.complementary.grayBlue,
      fontWeight: "bold",
      textTransform: "lowercase",
      '&[aria-selected="true"]': {
        color: "white",
      },
    },
  },
  graph: {
    width: "100%",
  },
  fistScreenTable: (theme: Theme) => ({
    backgroundColor: theme.palette.main.darkBlue,
    padding: "1.5rem",
    borderRadius: "0.5rem",
    "& svg": {
      flexShrink: "1",
      position: "relative",
      top: "8px",
      marginRight: "30px",
      fill: theme.palette.success.main,
    },
    [theme.breakpoints.only("xs")]: {
      padding: "1rem",
    },
  }),
  fistTabContentPaddings: {
    padding: "30px",
  },
  chartContainer: (theme: Theme) => ({
    display: "flex",
    flex: "0 0 30%",
    [theme.breakpoints.up("xl")]: {
      flex: "0 0 45%",
    },
  }),
  variantWrapper: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  variantTitle: (theme: Theme) => ({
    fontSize: "1.125rem",
    color: theme.palette.text.onAccent,
  }),
  variantSubtitle: (theme: Theme) => ({
    lineHeight: "1",
    [theme.breakpoints.up("xl")]: {
      lineHeight: "1.3",
      fontWeight: 400,
      fontSize: "1.125rem",
    },
    color: theme.palette.text.secondary,
  }),
  variantContainer: {
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    gap: "0.5rem",
  },
};

export const populationVariantStyles = {
  wrapper: (isVariant: boolean, { palette }: Theme) => {
    const fill = isVariant ? palette.success.main : palette.error.main;

    return {
      "& > svg": {
        overflow: "visible",
      },
      "& > svg > circle": {
        fill,
        filter: `drop-shadow(0 0 2px ${fill})`,
      },
    };
  },
  variant: (theme: Theme) => ({
    fontSize: "1rem",
    color: theme.palette.text.primary,
  }),
  total: {
    pt: "0.5rem",
  },
};
