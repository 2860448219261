import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  Column,
  FilterValue,
  HeaderGroup,
  Row,
  SortingRule,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { BaseTable } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";

import { IMinistriesDataItem } from "../../../../Main/common/ComplexGrid/util/interfaces";
import { MinistriesFilters } from "../filterComponents/MinistriesFilters";

interface IMinistriesTabProps {
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
  ministriesTableColumns: Column[];
  ministriesTableData: IMinistriesDataItem[];
  getTableProps: (propGetter?: TablePropGetter<any>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  headerGroups: Array<HeaderGroup<any>>;
  rows: Array<Row<any>>;
  prepareRow: (row: Row<any>) => void;
  setSortBy: (sortBy: Array<SortingRule<any>>) => void;
  ministries: string;
  setSelectedMinistry: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  infoRows: Record<string, string>[][];
}

export const MinistriesTab = ({
  globalFilter,
  setGlobalFilter,
  ministriesTableColumns,
  ministriesTableData,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  setSortBy,
  ministries,
  setSelectedMinistry,
  isTableModalOpen,
  setTableModalOpen,
  infoRows,
}: IMinistriesTabProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  const { headings, mergeCells, wscols } = useTableExportParams(
    ministriesTableData,
    "ministries"
  );

  const RenderTable = () => {
    return (
      <Box>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          {(match || isTableModalOpen) && (
            <MinistriesFilters
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          {isTableModalOpen && (
            <TableActionsButtons
              open={isTableModalOpen}
              setOpen={setTableModalOpen}
              downloadData={ministriesTableData}
              fileName="Министерства"
              wscols={wscols}
              headings={headings}
              mergeCells={mergeCells}
              infoRows={infoRows}
            />
          )}
        </Box>
        <BaseTable
          columns={ministriesTableColumns}
          data={ministriesTableData}
          sortedColumns={[
            "Министерства",
            "Мероприятия",
            "План",
            "Факт",
            "Под риском",
            "Уровень достижения",
          ]}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          setSortBy={setSortBy}
          isSmall
          tableHeight={isTableModalOpen ? "calc(100vh - 15rem)" : "46rem"}
          selectedRow={ministries}
          setSelectedRow={setSelectedMinistry}
        />
      </Box>
    );
  };

  return (
    <>
      <RenderTable />
      {!match && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Министерства"
        >
          <RenderTable />
        </TableModal>
      )}
    </>
  );
};
