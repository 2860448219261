import { Theme } from "@mui/material";

export const css = {
  switch: (theme: Theme) => ({
    border: `1px solid ${theme.palette.complementary.grayBlue}`,
    borderRadius: "0.5rem",
    padding: "0.25rem",
    flex: "0 0 auto",
    width: "fit-content",

    [theme.breakpoints.up("lg")]: {
      display: "flex",
      gap: "1rem",
      padding: "0",
      paddingLeft: "1rem",
      marginRight: "-0.5rem",
      border: "none",
      borderRadius: 0,
      borderLeft: `4px solid ${theme.palette.background.default}`,
    },
  }),
  methodButton: (active: boolean) => (theme: Theme) => ({
    backgroundColor: !active ? "transparent" : theme.palette.primary.main,
    color: "text.onAccent",
    borderRadius: "0.5rem",
    height: "2rem",
    padding: "0.7rem 1rem 0.5rem 1rem !important",
    flex: "0 0 auto",
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    textTransform: "capitalize",
    fontWeight: "bold",

    [theme.breakpoints.up("lg")]: {
      height: "2.7rem",
      backgroundColor: theme.palette.background.default,
      borderRadius: "50%",
    },
  }),
};
