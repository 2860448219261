import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  SxProps,
  Typography,
} from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { useState } from "react";
import { ColumnInstance } from "react-table";

import { css } from "./ColumnsTableModal.styled";

interface ITableModalProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  allColumns: ColumnInstance<any>[];
  includesByDefault?: string[];
}

export const ColumnsTableModal = ({
  open = false,
  setOpen,
  allColumns,
  includesByDefault,
}: ITableModalProps) => {
  const handleClose = () => {
    setOpen?.(false);
  };

  const [columns, setColumns] = useState(allColumns);

  const columnsWithoutDefault = allColumns.filter(
    (item) => !includesByDefault?.includes(item.id)
  );
  const columnsByDefault = allColumns.filter((item) =>
    includesByDefault?.includes(item.id)
  );

  const chooseAll = () => {
    setColumns(allColumns);
  };
  const resetAll = () => {
    setColumns(columnsByDefault);
  };

  const submit = () => {
    allColumns
      .filter((item) => !columns.includes(item))
      .map((item) => item.toggleHidden(true));
    allColumns
      .filter((item) => columns.includes(item))
      .map((item) => item.toggleHidden(false));
    setOpen?.(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={css.modalBox as SxProps}>
        <Grid container sx={css.modalHeader}>
          <Grid item>
            <Typography sx={css.modalName}>Столбцы</Typography>
          </Grid>
          <Grid item>
            <Box sx={css.closeButton} onClick={handleClose}>
              <Close />
            </Box>
          </Grid>
        </Grid>
        <Box sx={css.checkboxes}>
          {columnsWithoutDefault.map((column) => {
            return (
              <Box key={column.id} sx={css.checkBoxContainer}>
                <Checkbox
                  checked={Boolean(columns.find((el) => el.id === column.id))}
                  onChange={() => {
                    if (columns.find((el) => el.id === column.id)) {
                      setColumns(columns.filter((el) => el.id !== column.id));
                    } else {
                      setColumns([...columns, column]);
                    }
                  }}
                />
                <Typography sx={css.label}>{column.Header}</Typography>
              </Box>
            );
          })}
        </Box>
        <Grid container sx={css.modalFooter}>
          <Grid item xs={3}>
            <Button sx={css.button as SxProps} onClick={chooseAll}>
              Выбрать все
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button sx={css.button as SxProps} onClick={resetAll}>
              Снять все выделения
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button sx={css.outlinedButton as SxProps} onClick={submit}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
