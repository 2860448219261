import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getControlPoints } from "SRC/redux/slices/gosprogram/api/client";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";

export interface ICheckpoint {
  COMMENT: string | null;
  NAME: string;
  R_ID: string;
  code: string;
  data: any[];
  SORT_ORDER: string;
}

export interface IControlPoint {
  data: ICheckpoint[];
  requestId: string;
  type: string;
}

export interface IControlPointsState {
  items: IControlPoint[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "controlPoints/get",
    async (params: IEventParams) => await getControlPoints(params)
  ),
};

const initialState: IControlPointsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isControlPoint = (array: any): array is IControlPoint[] =>
  !!array?.length;

const slice = createSlice({
  name: "controlPoints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IControlPointsState, action) => {
          state.items = isControlPoint(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
