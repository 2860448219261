import { Theme } from "@mui/material";

export const css = {
  cashTabWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "2rem 0 1rem 0",
    minHeight: "27rem",
  },
  cashTabContainer: {
    padding: "0 2.5rem",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  innerGridItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
    maxHeight: "6.75rem",
  },
  circle: (theme: Theme) => ({
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "50%",
    border: `0.25rem solid ${theme.palette.main.darkBlue}`,
  }),
  smallText: {
    fontSize: "1.25rem",
    lineHeight: "1em",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
  mainText: (theme: Theme) => ({
    fontSize: "1.25rem",
    lineHeight: "1.3rem",
    color: theme.palette.text.onAccent,
    whiteSpace: "nowrap",
    textAlign: "center",
  }),
  countText: {
    fontSize: "1.5rem",
    lineHeight: "1.3rem",
    color: (theme: Theme) => theme.palette.success.main,
    fontWeight: "bold",
  },
  achievementContainer: {
    border: (theme: Theme) =>
      `1px solid ${theme.palette.background.paperBorderGray}`,
    padding: "0.7rem 1rem 0.4rem 1rem",
    width: "fit-content",
    background: (theme: Theme) => theme.palette.background.paper,
    borderRadius: "8px",
  },
  divider: {
    height: "1px",
    width: "100%",
    background: (theme: Theme) => theme.palette.charts.whiteTransparent,
  },
  legendContainer: {
    fontSize: "1rem",
    gap: "2rem",
    color: (theme: Theme) => theme.palette.main.gray,
    padding: "2rem 2.5rem 0 2.5rem",
  },
  legendItemContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "baseline",

    "& p.white": {
      color: (theme: Theme) => theme.palette.text.onAccent,
    },
    "&.center": {
      alignItems: "center",
    },
  },
  legendItem: {
    width: "2rem",
    height: "0.3rem",
    borderRadius: "1px",

    "&.white": {
      background: (theme: Theme) => theme.palette.primary.contrastText,
    },
    "&.blue": {
      background: (theme: Theme) => theme.palette.main.blue,
    },
  },
  styledPoint: {
    width: "0.7rem",
    height: "0.7rem",
    borderRadius: "50%",

    "&.green": {
      background: (theme: Theme) => theme.palette.success.main,
    },
    "&.red": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.gray": {
      background: (theme: Theme) => theme.palette.main.gray,
    },
    "&.dark": {
      background: (theme: Theme) => theme.palette.background.default,
    },
  },
};
