import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  css,
  TableCellBigText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const relatedIndicatorsColumns = [
  {
    Header: "Название показателя",
    accessor: "indicatorName",
    Cell: (tableProps: CustomTableProps) => (
      <div style={{ position: "relative", padding: "0 20px" }}>
        <Box
          sx={css.tableCellText}
          className="with-right-border withMarginLeft"
        >
          <Typography sx={css.tableCellText} className="fontLight">
            {tableProps.row.original.indicatorName}
          </Typography>
        </Box>
      </div>
    ),
  },
  {
    Header: "Уровень достижения, %",
    accessor: "achievementLevel",
    Cell: (tableProps: CustomTableProps) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          gap: "0.7rem",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "0.8rem",
            height: "0.8rem",
            borderRadius: "50%",
            background:
              tableProps.row.original.achievementLevel < 100
                ? "#cc0c31"
                : "#4cd961",
          }}
        />
        <TableCellBigText align="center" className="resetMargin">
          {tableProps.row.original.achievementLevel}
        </TableCellBigText>
      </Box>
    ),
  },
  {
    Header: "Степень влияния, %",
    accessor: "effect",
    Cell: (tableProps: CustomTableProps) => (
      <Typography
        sx={css.tableCellText}
        className="bigText with-right-border"
        align="center"
      >
        {tableProps.row.original.effect}
      </Typography>
    ),
  },
];

export { relatedIndicatorsColumns };
