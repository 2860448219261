import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface ITableTypeState {
  tableType: number;
}

const initialState: ITableTypeState = {
  tableType: 0,
};

const slice = createSlice({
  name: "tableType",
  initialState,
  reducers: {
    changeTableType: (state, action) => {
      state.tableType = action.payload;
    },
  },
});

export const actions = {
  ...slice.actions,
};

export default slice;
