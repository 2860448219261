import { SxProps, useTheme } from "@mui/material";
import { iteratorSymbol } from "immer/dist/internal";
import { useMemo } from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { StructureElementsTab } from "SRC/pages/Program/common/StructureCard/StructureElementsTab/StructureElementsTab";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/useStructureElements";
import themeConfig from "SRC/theme";
import { IStructureElement } from "SRC/types";

import { getStructureColumns } from "../util/columns";
import { css } from "./StructureTabs.styled";

interface IStructureTabs {
  activeTab: number;
  onChange: (value: number | ((prevVar: number) => number)) => void;
  // setStructureElementsCode: Dispatch<SetStateAction<string>>;
}

export const StructureTabs = ({ activeTab, onChange }: IStructureTabs) => {
  const theme = useTheme<typeof themeConfig>();
  const structureTableColumns = useMemo(
    () => getStructureColumns(theme),
    [theme]
  );
  const { items: structures } = useStructureElements();

  const dataFederal: IStructureElement[] = useMemo(() => {
    return structures.filter((item) => item["Признак, ФП"] === 1);
  }, [structures]);
  const dataDepartment: IStructureElement[] = useMemo(() => {
    return structures.filter((item) => item["Признак, ВП"] === 1);
  }, [structures]);
  const dataComplex: IStructureElement[] = useMemo(() => {
    return structures.filter((item) => item["Признак, КПМ"] === 1);
  }, [structures]);

  const components = [
    <StructureElementsTab
      columns={structureTableColumns}
      dataStructure={structures}
    />,
    <StructureElementsTab
      columns={structureTableColumns}
      dataStructure={dataFederal}
    />,
    <StructureElementsTab
      columns={structureTableColumns}
      dataStructure={dataDepartment}
    />,
    <StructureElementsTab
      columns={structureTableColumns}
      dataStructure={dataComplex}
    />,
  ];
  const tabs = [
    {
      value: 0,
      label: "Все",
      numberOfItems: structures.length,
    },
    {
      value: 1,
      label: "Федеральные проекты",
      numberOfItems: dataFederal.length,
      borderColor: theme.palette.labelTab.violet,
    },
    {
      value: 2,
      label: "Ведомственные проекты",
      numberOfItems: dataDepartment.length,
      borderColor: theme.palette.labelTab.blue,
    },
    {
      value: 3,
      label: "Комплексы процессных мероприятий",
      numberOfItems: dataComplex.length,
      borderColor: theme.palette.labelTab.pink,
    },
  ];

  return (
    <CustomTabs
      value={activeTab}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      tabs={tabs}
      style={css.tabs as SxProps}
      stylePanel={css.tabPanel}
      components={components}
      borderType={"solid"}
      isModal={true}
    />
  );
};
