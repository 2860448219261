import { Theme } from "@mui/material";

export const css = {
  iconContainer: {
    background: (theme: Theme) => `${theme.palette.main.darkBlue}`,
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: (iconColor: string | undefined) => ({
    background: `${iconColor}`,
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    boxShadow: `0 0 0.375rem ${iconColor}`,
  }),
  cellContainer: {
    padding: "1.25rem",
    maxWidth: "43.8rem",
    textAlign: "center",
    fontWeight: "bold",
    "&.cellProgress": {
      color: (theme: Theme) => `${theme.palette.main.yellow}`,
    },
    "&.cellDone": {
      color: (theme: Theme) => `${theme.palette.success.main}`,
    },
  },
  cellTitleContainer: {
    textAlign: "left",
    padding: "1.25rem",
    fontWeight: "normal",
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
  },
  abbreviate: {
    color: (theme: Theme) => `${theme.palette.main.gray}`,
    width: "100%",
    maxWidth: "2rem",
  },
  type: {
    position: "absolute",
    width: "1.5rem",
    height: "1.5rem",
    left: "-0.75rem",
    top: "50%",
    transform: "translateY(-50%)",
  },
};
