import { Box, Grid, SxProps, Typography, useTheme } from "@mui/material";
import smallArrowLeft from "ASSETS/svg/arrow/smallArrowLeft.svg";
import ReactECharts from "echarts-for-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Arrow } from "SRC/components/Carousel/CarouselButtons/CarouselButtons.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IIndicatorsInfo } from "SRC/pages/Program/common/GoalsTab/interfaces";

import { css } from "../GoalItem.styled";
import { chartCss } from "./ChartView.styled";
import getOptions from "./util/options";

interface IChartViewProps {
  indicatorsInfo?: IIndicatorsInfo;
  plan: number;
  fact: number;
  indicatorName: string;
  chartPlanData: number[];
  chartFactData: number[];
  setChartViewIndex: Dispatch<SetStateAction<null>>;
}

const ChartView = ({
  plan,
  fact,
  indicatorName,
  chartPlanData,
  chartFactData,
  setChartViewIndex,
}: IChartViewProps) => {
  const theme = useTheme();

  const [isHintVisible, setHintVisible] = useState(false);

  const onPointerEnter = () => setHintVisible(true);
  const onPointerLeave = () => setHintVisible(false);
  const onBackClick = () => setChartViewIndex(null);

  return (
    <Box style={{ width: "100%" }}>
      <Grid sx={chartCss.gridContainer} container>
        <Grid item sm={9}>
          <Box sx={chartCss.topLeftContainer}>
            <Box sx={chartCss.button} onClick={onBackClick}>
              <Arrow src={smallArrowLeft} alt="left" />
            </Box>
            <Typography sx={css.indicatorLabelText}>Показатель</Typography>
          </Box>
          <Box onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
            <Typography
              sx={css.indicatorName as SxProps}
              className="chartViewName"
            >
              {indicatorName}
            </Typography>
          </Box>
          <Typography sx={css.percentSmallText} style={{ marginBottom: 0 }}>
            %
          </Typography>
        </Grid>
        <Grid sx={chartCss.gridItem} item sm={3}>
          <Box sx={chartCss.innerGridItem as SxProps}>
            <Box sx={chartCss.circle} />
            <Typography sx={css.smallText}>Факт</Typography>
            <Typography sx={css.factText as SxProps}>
              {roundNumbersToFixed(fact, 2)}
            </Typography>
          </Box>
          <Box sx={chartCss.innerGridItem as SxProps}>
            <Box sx={chartCss.blueCircle} />
            <Typography sx={css.smallText}>План</Typography>
            <Typography sx={css.planText as SxProps}>
              {roundNumbersToFixed(plan, 2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {isHintVisible && (
        <Box sx={css.hintContainer(true) as SxProps}>
          <Typography sx={css.hintText as SxProps}>{indicatorName}</Typography>
        </Box>
      )}
      <ReactECharts
        style={chartCss.chartStyle}
        option={getOptions(chartPlanData, chartFactData, theme)}
      />
    </Box>
  );
};

export default ChartView;
