import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  css,
  TableImage,
} from "SRC/components/Table/BaseTable/BaseTable.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
}

const getNationalGoalsColumns = (theme: Theme) => {
  return [
    {
      Header: " ",
      accessor: "icon",
      Cell: (tableProps: CustomTableProps) => (
        <Box sx={css.tableImageContainer(theme, true)}>
          <TableImage src={tableProps.row.original.icon} alt="govProgram" />
        </Box>
      ),
    },
    {
      Header: "Наименование цели",
      accessor: "goalTitle",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="left"
          sx={css.tableCellText}
          className="with-right-border"
        >
          {tableProps.row.original.goalTitle}
        </Typography>
      ),
    },
    {
      Header: "Уровень достижения Национальной цели",
      accessor: "achievementLevel",
      Cell: (tableProps: CustomTableProps) => (
        <div style={{ padding: "20px 30px" }}>
          <Typography
            align="center"
            sx={css.tableCellText(theme, "", false, undefined, true)}
            className="bigText with-left-border marginBottom"
          >
            {`${tableProps.row.original.achievementLevel}%`}
          </Typography>
          <ProgressBar value={tableProps.row.original.achievementLevel || 0} />
        </div>
      ),
    },
    {
      Header: "Количество Госпрограмм",
      accessor: "numOfPrograms",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="center"
          sx={css.tableCellText(theme, "", false, undefined, true)}
          className="bigText with-left-border"
        >
          {tableProps.row.original.numOfPrograms}
        </Typography>
      ),
    },
  ];
};

export { getNationalGoalsColumns };
