import { Box, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import BaseTable from "SRC/components/Table/BaseTable/BaseTable";
import { IRelatedIndicatorsDataItem } from "SRC/pages/Program/common/EventCard/EventTabs/RelatedIndicators/util/interfaces";

import relatedIndicatorsData from "./util/data";

interface IProps {
  columns: Column[];
}

export const RelatedIndicators = ({ columns }: IProps) => {
  const theme = useTheme();

  // const data = useMemo<IRelatedIndicatorsDataItem[]>(
  //   () =>
  //     relatedIndicatorsData?.map((item: IRelatedIndicatorsDataItem) => ({
  //       id: item.id,
  //       indicatorName: item.indicatorName,
  //       achievementLevel: item.achievementLevel,
  //       effect: item.effect,
  //     })),
  //   [relatedIndicatorsData]
  // );

  const data: any = [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        padding: "0.5rem 2.5rem",
      }}
    >
      <BaseTable
        columns={columns}
        data={data}
        sortedColumns={["План", "Факт"]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={setSortBy}
        tableHeight={"31rem"}
      />
    </Box>
  );
};
