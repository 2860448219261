import { styled } from "@mui/material";
import theme from "SRC/theme";

export const CardWrapDiv = styled("div")((props) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  rowGap: "0.5rem",
  position: "relative",
  padding: "1.5rem",
  backgroundColor: props.theme.palette.complementary.darkBlueLight,
  borderRadius: "1rem",

  [theme.breakpoints.up("xl")]: {
    padding: "1rem",
  },
}));

export const CardContent = styled("div")({
  display: "flex",
  flex: 1,
});

export const HeaderLeft = () => ({
  fontSize: "1.125rem",
  fontWeight: "700",
});

export const HeaderRight = () => ({
  fontSize: "1.125rem",
  fontWeight: "400",
});
