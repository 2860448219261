import { FC } from "react";

import { PageContentStyled } from "./PageContent.styled";

export interface IMainContainerProps {
  withMenu?: boolean;
}

export const PageContent: FC<IMainContainerProps> = ({ children }) => {
  return <PageContentStyled>{children}</PageContentStyled>;
};
