import { Theme } from "@mui/material";
import React, { Fragment, MutableRefObject } from "react";
import { Rating } from "SRC/pages/Summary/common/Rating/Rating";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";
import { SummaryComplexGrid } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid";
import { ITableDataItem } from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";

interface ICenterLayoutProps {
  theme: Theme;
  isLargeSize: boolean;
  forwardRef: MutableRefObject<HTMLElement | undefined> | undefined;
  onScroll?: ((scroll: any) => void) | undefined;
  setSortBy: (sortBy: any) => void;
  tableData: ITableDataItem[];
  topBestData: IRatingTopBestData[];
  topWorstData: IRatingTopWorstData[];
}
export const LeftLayout = ({
  theme,
  isLargeSize,
  forwardRef,
  onScroll,
  setSortBy,
  tableData,
  topBestData,
  topWorstData,
}: ICenterLayoutProps) => {
  return (
    <Fragment>
      {!isLargeSize && (
        <Rating topBestData={topBestData} topWorstData={topWorstData} />
      )}
      <SummaryComplexGrid
        setSortBy={setSortBy}
        forwardRef={forwardRef}
        onScroll={onScroll}
        tableData={tableData}
      />
    </Fragment>
  );
};
