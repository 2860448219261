import { useCallback } from "react";
import { useDispatch } from "react-redux";
import slice, {
  IProgramFiltersState,
} from "SRC/redux/slices/gosprogram/slices/programFilters";

import { useAppSelector } from "../../../hooks";

export interface IFilters extends IProgramFiltersState {
  clear(): void;
  setSelectedEventId(id: string | number): void;
  setSelectedStatus(status: string): void;
  setSelectedStructureId(id: string | number): void;
  setSelectedStructureRow(id: string | number): void;
  setSelectedStructureCodes(codes: string[]): void;
  setSelectedObjectId(id: string): void;
  setSelectedProgramGoalFactorsId(id: number[]): void;
  setSelectedProgramGoalId(id: number | string): void;
  setSelectedProgramGoalCodes(codes: number[]): void;
  setSelectedGoalId(id: number | string): void;
  setSelectedGoalProgramId(id: number[][]): void;
  setSelectedGoalFactorsId(id: number[]): void;
  setSelectedHiddenFactorsId(id: number | string): void;
  setRemoveHiddenFactorsId(id: number): void;
  setSelectedEventFeature(feature: string): void;
}

export const useProgramFilters = (): IFilters => {
  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.gosprogram.programFilters);

  const setSelectedEventId = (id: string) =>
    dispatch(slice.actions.selectEventId(id));

  const setSelectedStatus = (status: string) =>
    dispatch(slice.actions.selectStatus(status));

  const setSelectedStructureRow = (id: string) =>
    dispatch(slice.actions.selectStructureRow(id));

  const setSelectedStructureId = (id: string) =>
    dispatch(slice.actions.selectStructureId(id));

  const setSelectedStructureCodes = (codes: string[]) =>
    dispatch(slice.actions.selectStructureCodes(codes));

  const setSelectedObjectId = (id: string) =>
    dispatch(slice.actions.selectObjectId(id));

  const setSelectedProgramGoalFactorsId = (id: number[]) =>
    dispatch(slice.actions.selectProgramGoalFactorsId(id));

  const setSelectedProgramGoalCodes = (codes: number[]) =>
    dispatch(slice.actions.selectProgramGoalCodes(codes));

  const setSelectedProgramGoalId = (id: number) =>
    dispatch(slice.actions.selectProgramGoalId(id));

  const setSelectedGoalId = (id: string) =>
    dispatch(slice.actions.selectGoalId(id));

  const setSelectedGoalProgramId = (id: number[][]) =>
    dispatch(slice.actions.selectGoalProgramId(id));

  const setSelectedGoalFactorsId = (id: number[]) =>
    dispatch(slice.actions.selectGoalFactorsId(id));

  const setSelectedHiddenFactorsId = (id: string) =>
    dispatch(slice.actions.selectHiddenFactorsId(id));

  const setRemoveHiddenFactorsId = (id: number) =>
    dispatch(slice.actions.removeHiddenFactorsId(id));

  const setSelectedEventFeature = (feature: string) =>
    dispatch(slice.actions.selectEventFeature(feature));

  const clear = useCallback(() => dispatch(slice.actions.clear()), [dispatch]);

  return {
    ...filters,
    setSelectedEventId,
    setSelectedStatus,
    setSelectedStructureRow,
    setSelectedStructureId,
    setSelectedStructureCodes,
    setSelectedObjectId,
    setSelectedProgramGoalFactorsId,
    setSelectedProgramGoalId,
    setSelectedProgramGoalCodes,
    setSelectedGoalProgramId,
    setSelectedGoalId,
    setSelectedGoalFactorsId,
    setSelectedHiddenFactorsId,
    setRemoveHiddenFactorsId,
    setSelectedEventFeature,
    clear,
  };
};
