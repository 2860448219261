import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import {
  BigWhiteText,
  css,
  PercentageSymbol,
  PercentageSymbolSmall,
  PercentageText,
  SmallWhiteText,
  SpaceBetweenContainer,
  ThinMainText,
} from "SRC/pages/Program/common/GovProgramInfoWidget/GovProgramInfoWidget.styled";

import { styles } from "./StructureElementsCard.styled";

interface IElements {
  name: string;
  amount: number;
  total: number;
  done: number;
  percentage: number;
}

interface IProps {
  elements: IElements[];
  total: number;
  percentage: number;
}

const StructureElementsCard = (props: IProps) => {
  const { elements, total, percentage } = props || {};

  return (
    <>
      <SpaceBetweenContainer>
        <BigWhiteText>{total}</BigWhiteText>
        <Box sx={{ display: "flex" }}>
          <PercentageText percentageValue={percentage}>
            {roundNumbersToFixed(percentage || 0, 0)}
            &nbsp;
          </PercentageText>
          <PercentageSymbol>%</PercentageSymbol>
        </Box>
      </SpaceBetweenContainer>
      {elements.map((elem: any) => {
        return (
          <Grid key={elem.name} sx={styles.wrapper} container>
            <Grid item xs={9}>
              <ThinMainText>{elem.name}</ThinMainText>
              <SmallWhiteText sx={{ lineHeight: "2rem" }}>
                {elem.amount}
              </SmallWhiteText>
            </Grid>
            <Grid item xs={3} sx={styles.elementName}>
              <Typography
                sx={css.percentageText(
                  roundNumbersToFixed(elem.percentage, 0) || 0
                )}
                className="percentageSmallText"
              >
                {roundNumbersToFixed(elem.percentage, 0) || 0}
                &nbsp;
              </Typography>
              <PercentageSymbolSmall>%</PercentageSymbolSmall>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default StructureElementsCard;
