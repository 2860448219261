import { createSlice } from "@reduxjs/toolkit";

export interface IEventParams {
  id: number | string;
  period: string;
}

export interface IProgramFiltersState {
  selectedEventId: string | number;
  selectedStatus: string;
  selectedStructureId: string;
  selectedStructureRow: string;
  selectedStructureCodes: string[];
  selectedObjectId: string;
  selectedProgramGoalId: number | string;
  selectedProgramGoalFactorsId: number[];
  selectedProgramGoalCodes: number[];
  selectedGoalId: number | string;
  selectedGoalProgramId: number[][];
  selectedGoalFactorsId: number[];
  selectedHiddenFactorsId: (string | number)[];
  selectedHiddenCountTop: number | string;
  selectedHiddenCountBottom: number | string;
  selectedEventFeature: string;
}

const initialState: IProgramFiltersState = {
  selectedEventId: "",
  selectedStatus: "",
  selectedStructureRow: "",
  selectedStructureId: "",
  selectedStructureCodes: [],
  selectedObjectId: "",
  selectedProgramGoalId: "",
  selectedProgramGoalFactorsId: [],
  selectedProgramGoalCodes: [],
  selectedGoalId: "",
  selectedGoalProgramId: [],
  selectedGoalFactorsId: [],
  selectedHiddenFactorsId: [],
  selectedHiddenCountTop: "",
  selectedHiddenCountBottom: "",
  selectedEventFeature: "",
};

const slice = createSlice({
  name: "programFilters",
  initialState,
  reducers: {
    clear: () => initialState,
    selectEventId: (state, action: { payload: string | number }) => {
      state.selectedEventId = action.payload;
    },
    selectStatus: (state, action: { payload: string }) => {
      state.selectedStatus = action.payload;
    },
    selectStructureRow: (state, action: { payload: string }) => {
      state.selectedStructureRow = action.payload;
    },
    selectStructureId: (state, action: { payload: string }) => {
      state.selectedStructureId = action.payload;
    },
    selectStructureCodes: (state, action: { payload: string[] }) => {
      state.selectedStructureCodes = action.payload;
    },
    selectObjectId: (state, action: { payload: string }) => {
      state.selectedObjectId = action.payload;
    },
    selectProgramGoalId: (state, action: { payload: number }) => {
      state.selectedProgramGoalId = action.payload;
    },
    selectProgramGoalCodes: (state, action: { payload: number[] }) => {
      state.selectedProgramGoalCodes = action.payload;
    },
    selectProgramGoalFactorsId: (state, action: { payload: number[] }) => {
      state.selectedProgramGoalFactorsId = action.payload;
    },
    selectGoalId: (state, action: { payload: string }) => {
      state.selectedGoalId = action.payload;
    },
    selectGoalProgramId: (state, action: { payload: number[][] }) => {
      state.selectedGoalProgramId = action.payload;
    },
    selectGoalFactorsId: (state, action: { payload: number[] }) => {
      state.selectedGoalFactorsId = action.payload;
    },
    selectHiddenFactorsId: (state, action: { payload: number | string }) => {
      state.selectedHiddenFactorsId.push(action.payload);
      if (action.payload === "") {
        state.selectedHiddenFactorsId = [];
      }
    },
    removeHiddenFactorsId: (state, action: { payload: number }) => {
      state.selectedHiddenFactorsId = state.selectedHiddenFactorsId.filter(
        (item) => action.payload !== item
      );
    },
    selectEventFeature: (state, action: { payload: string }) => {
      state.selectedEventFeature = action.payload;
    },
  },
});

export default slice;
