import { Box, SxProps, Typography } from "@mui/material";
import { WhiteArrowLeft } from "ASSETS/svg/arrow";
import { WhiteArrowRight } from "ASSETS/svg/arrow";
import { Close } from "ASSETS/svg/close";
import React, { useEffect, useMemo, useState } from "react";
import Carousel, { CarouselItem } from "SRC/components/Carousel/Carousel";
import { useObjectPhotos } from "SRC/redux/slices/gosprogram/hooks/useObjectPhotos";

import { css } from "../ObjectTab.styled";

interface IPhoto {
  sum: string;
  code: string;
}

interface IPhotosModalProps {
  handlePhotoModalClose(): void;
}

export const PhotosModal = ({ handlePhotoModalClose }: IPhotosModalProps) => {
  const [objectPhotosData, setObjectPhotosData] = useState<IPhoto[]>([]);

  const { items: objectPhotos } = useObjectPhotos();
  const { data: data1 } = objectPhotos[0] || {};

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      const { data: data3 } = data2[0] || {};
      setObjectPhotosData(data3);
    }
  }, [data1]);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = objectPhotosData?.length - 1;
    } else if (newIndex >= objectPhotosData?.length) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const isPrevBtnDisabled = activeIndex === 0;
  const isNextBtnDisabled = objectPhotosData?.length - activeIndex === 1;

  const onPrevClick = () => {
    if (!isPrevBtnDisabled) {
      updateIndex(activeIndex - 1);
    }
  };
  const onNextClick = () => {
    if (!isNextBtnDisabled) {
      updateIndex(activeIndex + 1);
    }
  };

  const photoItems = useMemo(
    (): JSX.Element[] =>
      objectPhotosData?.map((item: IPhoto) => (
        <CarouselItem key={item.code} visibleItems={1}>
          <Box sx={css.imageBigItemContainer}>
            <Box sx={css.imageItem}>
              <img
                src={item.sum}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Box>
        </CarouselItem>
      )),
    [objectPhotosData]
  );

  const smallPhotoItems = useMemo(
    (): JSX.Element[] =>
      objectPhotosData?.map((item: IPhoto) => (
        <CarouselItem key={item.code} visibleItems={3}>
          <Box sx={css.imageSmallItemContainer}>
            <Box sx={css.imageItem}>
              <img
                src={item.sum}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Box>
        </CarouselItem>
      )),
    [objectPhotosData]
  );

  return (
    <>
      <Box sx={css.modalHeader}>
        <Typography sx={css.title as SxProps}>Фотогалерея</Typography>
        <Box sx={{ cursor: "pointer" }} onClick={handlePhotoModalClose}>
          <Close />
        </Box>
      </Box>
      <Box sx={css.container}>
        {objectPhotosData?.length > 1 && (
          <Box
            onClick={onPrevClick}
            sx={css.button(isPrevBtnDisabled)}
            style={{ transform: "translateX(-15%)" }}
          >
            <WhiteArrowLeft style={css.arrow} />
          </Box>
        )}
        <Box sx={css.carouselContainer}>
          <Carousel activeIndex={activeIndex} visibleItems={1}>
            {photoItems}
          </Carousel>
        </Box>
        {objectPhotosData?.length > 1 && (
          <Box
            onClick={onNextClick}
            sx={css.button(isNextBtnDisabled)}
            style={{ transform: "translateX(-60%)" }}
          >
            <WhiteArrowRight style={css.arrow} />
          </Box>
        )}
      </Box>
      <Box sx={css.secondContainer}>
        {objectPhotosData?.length > 3 && (
          <Box
            onClick={onPrevClick}
            sx={css.button(isPrevBtnDisabled)}
            style={{ transform: "translateX(-15%)" }}
          >
            <WhiteArrowLeft style={css.arrow} />
          </Box>
        )}
        <Box sx={css.carouselContainer}>
          <Carousel activeIndex={activeIndex} visibleItems={3}>
            {smallPhotoItems}
          </Carousel>
        </Box>
        {objectPhotosData?.length > 3 && (
          <Box
            onClick={onNextClick}
            sx={css.button(isNextBtnDisabled)}
            style={{ transform: "translateX(-60%)" }}
          >
            <WhiteArrowRight style={css.arrow} />
          </Box>
        )}
      </Box>
    </>
  );
};
