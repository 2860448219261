import { Box, SxProps, Typography } from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { useEffect, useState } from "react";
import { useObjectVideos } from "SRC/redux/slices/gosprogram/hooks/useObjectVideos";

import { css } from "../ObjectTab.styled";

interface IVideoModalProps {
  handleVideoModalClose(): void;
}

const user = "ventuz";
const password = "FMG5mboLj2sg";

export const VideosModal = ({ handleVideoModalClose }: IVideoModalProps) => {
  const [videoToken, setVideoToken] = useState<string>("");
  const [cameraId, setCameraId] = useState<string>("");

  const { items: objectVideos } = useObjectVideos();
  const { data: data1 } = objectVideos[0] || {};

  const authenticateUser = (user: any, password: any) => {
    const token = user + ":" + password;
    const hash = btoa(token);

    return "Basic " + hash;
  };

  const getAuthToken = () => {
    const request = new XMLHttpRequest();
    request.open("POST", "https://enpv.ru/key", false);
    request.setRequestHeader("Authorization", authenticateUser(user, password));
    request.send();

    const result = JSON.parse(request.response);
    setVideoToken(result.data.key);
  };

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      const { data: data3 } = data2[0] || {};
      setCameraId(data3[0]?.sum);
    }
    getAuthToken();
  }, [data1]);

  return (
    <>
      <Box sx={css.modalHeader}>
        <Typography sx={css.title as SxProps}>Видео галерея</Typography>
        <Box sx={{ cursor: "pointer" }} onClick={handleVideoModalClose}>
          <Close />
        </Box>
      </Box>
      {videoToken && cameraId && (
        <Box sx={css.videoBigItemContainer}>
          <Box sx={css.imageItem}>
            <iframe
              style={{ display: "block", width: "100%", height: "100%" }}
              src={`https://enpv.ru/embed/byKey?at=${videoToken}&id=${cameraId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded"
            />
          </Box>
        </Box>
      )}
    </>
  );
};
