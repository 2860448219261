import { Box, Grid, Modal, SxProps, Typography } from "@mui/material";
import { Close } from "ASSETS/svg/close";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

import { css } from "./FullPageTableModal.styled";

interface ITableModalProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  tableName: string;
  children: ReactNode;
  backButton?: ReactNode;
}

export const TableModal = ({
  open = false,
  setOpen,
  setEventModalMode,
  tableName,
  children,
  backButton,
}: ITableModalProps) => {
  const handleClose = () => {
    setOpen?.(false);
    setEventModalMode?.("default");
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={css.modalBox as SxProps}>
        <Grid container sx={css.modalHeader}>
          {backButton}
          <Grid item>
            <Typography sx={css.tableName}>{tableName}</Typography>
          </Grid>
          <Grid item>
            <Box sx={css.closeButton} onClick={handleClose}>
              <Close />
            </Box>
          </Grid>
        </Grid>
        {children}
      </Box>
    </Modal>
  );
};
