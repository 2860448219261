import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getListOfObjects } from "SRC/redux/slices/gosprogram/api/client";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";

export interface IListOfObjects {
  data: Record<string, any>;
  requestId: string;
  type: string;
}

export interface IListOfObjectsState {
  items: any;
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "listOfObjects/get",
    async (params: IEventParams) => await getListOfObjects(params)
  ),
};

const initialState: IListOfObjectsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isListOfObjects = (array: any): array is IListOfObjects[] =>
  !!array?.length;

const slice = createSlice({
  name: "listOfObjects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IListOfObjectsState, action) => {
          state.items = isListOfObjects(action.payload.data)
            ? action.payload.data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
