import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { IHeaderPrograms } from "SRC/redux/slices/main/slices/headerPrograms";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../../gosprogram/actions";

export interface IHeaderProgramsProps {
  fetching: boolean;
  fetched: boolean;
  items: IHeaderPrograms[];
  load(period: IPeriod): void;
  error: SerializedError | null;
}

export const useHeaderPrograms = (): IHeaderProgramsProps => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: headerPrograms = [],
    fetched,
  } = useAppSelector((state) => state.gosprogram.headerPrograms);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.headerPrograms.get(params)).then((action) => {
          if (actions.headerPrograms.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: headerPrograms,
    load,
    error,
  };
};
