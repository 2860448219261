import { actions as ministries } from "./slices/ministries";
import { actions as premiers } from "./slices/premiers";
import { actions as programs } from "./slices/programs";
import { actions as programTopBest } from "./slices/programsTopBest";
import { actions as programTopWorst } from "./slices/programsTopWorst";

export interface ISummaryActions {
  ministries: typeof ministries;
  programs: typeof programs;
  premiers: typeof premiers;
  programTopBest: typeof programTopBest;
  programTopWorst: typeof programTopWorst;
}

const actions: ISummaryActions = {
  ministries,
  programs,
  premiers,
  programTopBest,
  programTopWorst,
};

export default actions;
