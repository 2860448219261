import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Equalizer } from "SRC/components/Charts";
import GraphSwitcher from "SRC/components/GraphSwitcher/GraphSwitcher";
import { ComplexGrid } from "SRC/pages/MainNew/common/ComplexGrid/ComplexGrid";
import { Effectiveness } from "SRC/pages/MainNew/common/GovPrograms/Effectiveness/Effectiveness";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import themeConfig from "SRC/theme";

import { css } from "./GovPrograms.styled";

const GovPrograms = () => {
  const theme = useTheme<typeof themeConfig>();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  const { viewChart } = useFilters();

  return (
    <Box sx={css.wrapper}>
      {!match && <GraphSwitcher />}
      <Box sx={css.container}>
        {viewChart === 2 ? (
          <Equalizer />
        ) : viewChart === 3 && !match ? (
          <ComplexGrid />
        ) : (
          <Effectiveness />
        )}
      </Box>
    </Box>
  );
};

export default GovPrograms;
