import { combineReducers } from "@reduxjs/toolkit";
import ministriesSlice, {
  IMinistriesState,
} from "SRC/redux/slices/summary/slices/ministries";
import {
  IPremiersState,
  slice as premiersSlice,
} from "SRC/redux/slices/summary/slices/premiers";
import {
  IProgramsState,
  slice as programsSlice,
} from "SRC/redux/slices/summary/slices/programs";
import programsTopBest, {
  IProgramsTopBestState,
} from "SRC/redux/slices/summary/slices/programsTopBest";
import programsTopWorst, {
  IProgramsTopWorstState,
} from "SRC/redux/slices/summary/slices/programsTopWorst";

import filtersSlice, { IFiltersState } from "./slices/filters";

export interface ISummaryReducer {
  filters: IFiltersState;
  programs: IProgramsState;
  premiers: IPremiersState;
  ministries: IMinistriesState;
  programsTopBest: IProgramsTopBestState;
  programsTopWorst: IProgramsTopWorstState;
}

const reducer = combineReducers<ISummaryReducer>({
  filters: filtersSlice.reducer,
  programs: programsSlice.reducer,
  premiers: premiersSlice.reducer,
  ministries: ministriesSlice.reducer,
  programsTopBest: programsTopBest.reducer,
  programsTopWorst: programsTopWorst.reducer,
});

export default reducer;
