import { styled, Theme } from "@mui/material";
import { NavLink } from "react-router-dom";

export const css = {
  wrapper: {
    position: "fixed",
    width: "100%",
    left: 0,
    top: 0,
    flex: "0 0 auto",
    zIndex: 3,
  },
  content: (theme: Theme) => ({
    position: "relative",
    width: "100%",
    height: "4.5rem",
    minHeight: "4.5rem",
    zIndex: 1,
    overflow: "hidden",

    [theme.breakpoints.up("lg")]: {
      height: "6rem",
    },

    ":after": {
      width: "100vw",
      height: "2rem",
      position: "absolute",
      top: "0",
      left: "0",
      backgroundColor: theme.palette.background.header,
      content: "''",
      zIndex: -1,
    },
  }),
  contentWrapper: (theme: Theme) => ({
    position: "relative",
    margin: "auto",
    backgroundColor: theme.palette.background.header,
    height: "100%",
    width: "100%",
    zIndex: 1,
    minHeight: "4.5rem",

    [theme.breakpoints.up("lg")]: {
      borderRadius: "0 0 2rem 2rem",
      maxWidth: "50%",

      ":before": {
        border: "1rem solid transparent",
        borderTop: `1rem solid ${theme.palette.background.header}`,
        borderRight: `1rem solid ${theme.palette.background.header}`,
        position: "absolute",
        top: "2rem",
        content: "''",
        zIndex: 0,
        left: "-2rem",
      },
      ":after": {
        border: "1rem solid transparent",
        borderTop: `1rem solid ${theme.palette.background.header}`,
        borderLeft: `1rem solid ${theme.palette.background.header}`,
        position: "absolute",
        top: "2rem",
        content: "''",
        zIndex: 0,
        right: "-2rem",
      },
    },

    [theme.breakpoints.up("xl")]: { maxWidth: "calc(50% + 4rem)" },
  }),
  leftRoundedBorder: {
    position: "absolute",
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
    background: (theme: Theme) => theme.palette.background.default,
    left: "-4rem",
    top: "2rem",
    zIndex: 1,
  },
  rightRoundedBorder: {
    position: "absolute",
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
    background: (theme: Theme) => theme.palette.background.default,
    right: "-4rem",
    top: "2rem",
    zIndex: 1,
  },
  containerSX: (theme: Theme) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
    minHeight: "4.5rem",
    padding: "0 2.5rem !important",

    [theme.breakpoints.up("lg")]: {
      padding: "0 5rem !important",
    },
  }),
  logo: (theme: Theme) => ({
    display: "inline-flex",
    height: "140%",
    alignItems: "center",
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    gap: "1rem",
    fontSize: "2rem",
    fontWeight: "bold",

    "& img": {
      height: "100%",
    },
  }),
  largeNavsWrapper: {
    display: "flex",
    gap: "0.5rem",
  },
  selectedSection: (theme: Theme) => ({
    background: theme.palette.complementary.darkRed,
    borderRadius: "0.5rem",
    padding: "0.5rem",
    width: "35rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
  }),
  selectWrapper: (theme: Theme) => ({
    background: theme.palette.complementary.darkRed,
    borderRadius: "0.5rem",
    padding: "0.5rem",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    height: "2.5rem",
  }),
  formControl: (theme: Theme) => ({
    border: "none",
    position: "relative",
    width: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  menuIcon: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  navOnLarge: {
    padding: "0.5rem !important",
    border: "none !important",
  },
  select: (theme: Theme) => ({
    backgroundColor: "transparent",
    padding: "0rem",
    border: "none",

    "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        backgroundColor: "transparent",
        padding: 0,
      },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    svg: {
      display: "none",
    },
    "& > div": {
      backgroundColor: "transparent !important",
    },
  }),
  menuItem: (theme: Theme) => ({
    fontSize: "1rem",
    lineHeight: "1rem",
    padding: "0.7rem 1rem",
    borderRadius: "0.2rem",
  }),
};

export const NavTab = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  opacity: 0.8,
  textDecoration: "none",
  padding: "1.3rem",
  fontSize: "1.125rem",
  fontWeight: "bold",
  '&[aria-current="page"]': {
    color: theme.palette.primary.contrastText,
    opacity: 1,
    borderBottom: `4px solid ${theme.palette.primary.contrastText}`,
  },

  [theme.breakpoints.up("lg")]: {
    padding: "1.3rem 1.3rem 2.3rem 1.3rem",
  },
}));
