import { Theme } from "@mui/material";

export const css = {
  flexContainer: (active: boolean) => ({
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 1rem",
    background: (theme: Theme) =>
      active
        ? theme.palette.background.paperBorderGray
        : theme.palette.background.paper,
    fontSize: "1rem",
    gap: "0.5rem",
    borderRadius: "0.5rem",
    border: (theme: Theme) =>
      active ? `1px solid ${theme.palette.primary.contrastText}` : "none",
    cursor: "pointer",

    "& span.grayText": {
      color: (theme: Theme) => theme.palette.main.gray,
    },
  }),
  styledDot: {
    width: "0.8rem",
    height: "0.8rem",
    borderRadius: "50%",

    "&.green": {
      background: (theme: Theme) => theme.palette.success.main,
    },
    "&.red": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.blue": {
      background: (theme: Theme) => theme.palette.background.default,
    },
  },
};
