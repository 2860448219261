import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMinistries } from "SRC/redux/slices/main/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

export interface IMinistryData {
  DatePart: string;
  NAME: string;
  PERIOD2: string;
  PERIOD_CODE: string;
  PeriodEnd: string;
  PeriodStart: string;
  code: string;
  data: IDataItem[];
}
export interface IDataItem {
  data: {
    [x: string]: any;
    [id: number]: {
      sum?: number;
      code: string;
      text?: string;
    };
  };
}
export interface IMinistry {
  code: string;
  NAME: string;
  data: IMinistryData[];
}

export interface IDataItemEl {
  sum?: number;
  code: string;
  text?: string;
}

export interface IMinistriesState {
  items: IMinistryData[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "ministries/get",
    async (period: IPeriod) => await getMinistries(period)
  ),
};

const initialState: IMinistriesState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIMinistry = (array: any): array is IMinistry[] => !!array?.length;

const slice = createSlice({
  name: "ministries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(
        extraActions.get.fulfilled,
        (state: IMinistriesState, action) => {
          state.items = isIMinistry(action.payload.data)
            ? action.payload.data[0].data
            : [];
          state.fetching = false;
          state.fetched = true;
        }
      )
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
