import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IStructureElement } from "SRC/types/structure/structure";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IStructureElements {
  fetching: boolean;
  fetched: boolean;
  items: IStructureElement[];
  load(id: string | number): void;
  error: SerializedError | null;
}

export const useStructureElements = (): IStructureElements => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: structure = [],
    fetched,
  } = useAppSelector((state) => state.gosprogram.structureElements);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.structureElements.get(params)).then((action) => {
          if (actions.structureElements.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  return {
    fetching,
    fetched,
    items: structure,
    load,
    error,
  };
};
