import { Theme } from "@mui/material";

export const css = {
  button: {
    width: "2.5rem",
    minWidth: "2.5rem",
    height: "2.5rem",
    backgroundColor: (theme: Theme) => theme.palette.main.blue,
    borderRadius: "8px",
    padding: 0,

    svg: {
      width: "1rem",
    },
  },
};
