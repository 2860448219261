import { combineReducers } from "@reduxjs/toolkit";

import auth, { IAuthReducer } from "./slices/auth/reducer";
import gosprogram, { IGosprogramReducer } from "./slices/gosprogram/reducer";
import main, { IMainReducer } from "./slices/main/reducer";
import summary, { ISummaryReducer } from "./slices/summary/reducer";

export interface IState {
  auth: IAuthReducer;
  main: IMainReducer;
  gosprogram: IGosprogramReducer;
  summary: ISummaryReducer;
}

const reducer = combineReducers<IState>({
  auth,
  main,
  gosprogram,
  summary,
});

export default reducer;
