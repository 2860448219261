import { SxProps } from "@mui/material";
import React, { useMemo, useState } from "react";
import SelectWithSearch from "SRC/components/SelectWithSearch/SelectWithSearch";

type TOptionValue = string | number;

export interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface ISelect {
  value?: string;
  onChange(value: TOptionValue): void;
  options: IOption[];
  hasAllOption?: boolean;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style: IStyle;
}

const SelectWithSearchMinistry = ({
  value,
  onChange,
  options,
  staticLabel = true,
  label,
  hasAllOption = true,
  colorizeActiveOption = true,
  style,
}: ISelect) => {
  const customOptions = [
    {
      value: "",
      label: label || "Все",
    },
    ...options,
  ];
  const validValue = useMemo(
    () => customOptions.filter((el) => el.value === value)[0],
    [value]
  );

  const [selectValue, setSelectValue] = useState(validValue);

  return (
    <SelectWithSearch
      onChange={onChange}
      customOptions={customOptions}
      selectValue={selectValue}
      setSelectValue={setSelectValue}
      getOptionLabel={(option: IOption) => option.label && option.label}
      staticLabel={staticLabel}
      label={label}
      colorizeActiveOption={colorizeActiveOption}
      style={style}
      hasCloseIcon={Boolean(value)}
      value={value}
    />
  );
};

export default SelectWithSearchMinistry;
