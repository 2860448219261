import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { SmallPlus } from "ASSETS/svg";
import ReactECharts from "echarts-for-react";
import React, { Dispatch, SetStateAction } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

import { css } from "../GoalItem.styled";
import { indicatorCss } from "./IndicatorInfo.styled";
import getOption from "./util/option";

interface IProps {
  index: number;
  setChartViewIndex: Dispatch<SetStateAction<any>>;
  plan: number;
  fact: number;
  indicatorName: string;
  chartPlanData: number[];
  chartFactData: number[];
}

const IndicatorInfo = (props: IProps) => {
  const {
    index,
    plan,
    fact,
    indicatorName,
    chartPlanData,
    chartFactData,
    setChartViewIndex,
  } = props;

  const theme = useTheme();

  return (
    <Box sx={indicatorCss.container}>
      <Box>
        <Typography sx={css.indicatorName as SxProps}>
          {indicatorName}
        </Typography>
      </Box>
      <Box>
        <Typography sx={css.percentSmallText}>%</Typography>
        <Box sx={indicatorCss.boxWithChart as SxProps}>
          <Box
            sx={indicatorCss.plusContainer as SxProps}
            onClick={() => setChartViewIndex(index)}
          >
            <SmallPlus />
          </Box>
          <Box sx={indicatorCss.infoItem}>
            <Typography sx={css.smallText}>Факт</Typography>
            <Typography sx={css.factText as SxProps}>
              {roundNumbersToFixed(fact, 2)}
            </Typography>
          </Box>
          <Box sx={indicatorCss.infoItem}>
            <Typography sx={css.smallText}>План</Typography>
            <Typography sx={css.planText as SxProps}>
              {roundNumbersToFixed(plan, 2)}
            </Typography>
          </Box>
          <Box sx={indicatorCss.chartItem as SxProps}>
            <ReactECharts
              option={getOption(chartPlanData, chartFactData, theme)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IndicatorInfo;
