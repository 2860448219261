import { Box, Paper, SxProps } from "@mui/material";
import { useAppSelector } from "SRC/redux/hooks";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPrograms,
  usePrograms,
} from "SRC/redux/slices/main/hooks/usePrograms";

import { css } from "./index.styled";

interface IProgramsCounterProps {
  tableDataLength?: number;
  totalDataLength?: number;
  isBig?: boolean;
}

const ProgramsCounter = ({
  tableDataLength,
  totalDataLength,
  isBig,
}: IProgramsCounterProps) => {
  const { program, clear, filteredPrograms, filteredType }: IFilters =
    useFilters();
  const { items: prItems }: IPrograms = usePrograms();
  const filters = useAppSelector((state) => state.main.filters);
  const { typeProgram } = filters;

  return (
    <Paper sx={css.indicatorValue(isBig) as SxProps} onClick={clear}>
      {tableDataLength
        ? tableDataLength
        : program
        ? 1
        : typeProgram === ""
        ? filteredPrograms.length
        : filteredType.length}
      <Box component="span" sx={css.indicatorTotal}>
        /{totalDataLength ? totalDataLength : prItems.length}
      </Box>
    </Paper>
  );
};

export default ProgramsCounter;
