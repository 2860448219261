import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DoubleDownArrow, DoubleUpArrow } from "ASSETS/svg/arrow/index";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkpoints } from "SRC/components/Checkpoints/Checkpoints";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { EventCardSidebar } from "SRC/pages/Program/common/EventCard/EventCardSidebar/EventCardSidebar";
import { EventCorrection } from "SRC/pages/Program/common/EventCard/EventCorrection/EventCorrection";
import {
  IDataCheckpoints,
  IDataCodeEventCard,
  IDataHeader,
  IDataSideBar,
  IHeader,
} from "SRC/pages/Program/common/EventCard/util/interfaces";
import { useCashExecution } from "SRC/redux/slices/gosprogram/hooks/useCashExecution";
import { useControlPoints } from "SRC/redux/slices/gosprogram/hooks/useControlPoints";
import { useEventData } from "SRC/redux/slices/gosprogram/hooks/useEventData";
import { useModalSideBar } from "SRC/redux/slices/gosprogram/hooks/useModalSideBar";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import themeConfig from "SRC/theme";

import { css } from "./EventCard.styled";
import { EventTabs } from "./EventTabs/EventTabs";

interface IWidgetDate {
  nameDate: string;
  date: string;
  colorDate?: string;
}

interface IHeaderProps {
  setOpen?: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  eventModalMode?: "default" | "eventCard" | "eventsFilter";
  data?: Record<string, any>[];
  header?: IHeader[];
}

interface IResultValue {
  id: number;
  name: string;
  unitName: string;
  total: number;
  done: number;
  percentage: number;
}

interface IDeadlineValue {
  name: string;
  plan: string;
  fact: string;
}

type TSidebarData = [IResultValue[], IDeadlineValue];

const WidgetDate = ({ nameDate, date, colorDate }: IWidgetDate) => (
  <Box sx={css.widgetDateContainer}>
    <Typography component={"div"} color={"text.secondary"} sx={css.textDate}>
      {nameDate}
    </Typography>
    <Typography
      component={"div"}
      color={colorDate ? "success.main" : "text.onAccent"}
      fontWeight={"bold"}
      sx={css.textDate}
    >
      {date}
    </Typography>
  </Box>
);

const Header = ({
  setOpen,
  open,
  setEventModalMode,
  eventModalMode,
  data,
  header,
}: IHeaderProps) => {
  const theme = useTheme<typeof themeConfig>();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const width1500 = useMediaQuery("(min-width: 1500px)");

  const { setSelectedEventId } = useProgramFilters();
  const [openTitle, setOpenTitle] = useState<boolean>(false);

  const maxLength = lg ? 500 : width1500 ? 300 : 250;

  const truncatedString =
    data && data.length > 0 ? data[0].NAME.substring(0, maxLength) + "..." : "";
  const isLargeString =
    data && data.length > 0 && data[0].NAME.length > maxLength;
  const displayText = openTitle
    ? data && data.length > 0
      ? data[0].NAME
      : ""
    : truncatedString;

  return (
    <>
      <Box sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <Grid container>
          {data && data.length > 0 ? (
            <Box sx={css.titleBottom(openTitle, isLargeString) as SxProps}>
              {isLargeString ? (
                <>
                  {displayText}
                  {openTitle ? (
                    <DoubleUpArrow
                      style={css.expandIcon}
                      onClick={() => setOpenTitle(!openTitle)}
                    />
                  ) : (
                    <DoubleDownArrow
                      style={css.expandIcon}
                      onClick={() => setOpenTitle(!openTitle)}
                    />
                  )}
                </>
              ) : (
                data[0].NAME
              )}
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Box>
      <Box sx={css.event}>
        {header &&
          header[0].headerIndicators.map((item: any, i: number) => {
            return (
              <Grid item container sx={css.headerEvent} key={i}>
                <Grid item>
                  <Typography sx={css.topText as SxProps} className="grayText">
                    {item.indicator || ""}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={css.topText as SxProps} className="whiteText">
                    {item.value || ""}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        <Grid item container sx={css.headerEvent}>
          <Grid item sx={css.event} className="alignCenter smallGap">
            <Typography sx={css.topText as SxProps} className="grayText">
              Реализация в субъектах
            </Typography>
            <Typography sx={css.topText as SxProps} className="whiteText">
              {header ? header[0].realization.total : 0}
            </Typography>
          </Grid>
          <Box sx={css.event}>
            <Box sx={css.event} className="smallGap">
              <Typography sx={css.topText as SxProps} className="grayText">
                Выполнено{" "}
              </Typography>
              <Typography sx={css.topText as SxProps} className="greenText">
                {header ? header[0].realization.done : 0}
              </Typography>
            </Box>
            <Box sx={css.event} className="smallGap">
              <Typography sx={css.topText as SxProps} className="grayText">
                Не выполнено{" "}
              </Typography>
              <Typography sx={css.topText as SxProps} className="redText">
                {header ? header[0].realization.notDone : 0}
              </Typography>
            </Box>
            <Box sx={css.event} className="smallGap">
              <Typography sx={css.topText as SxProps} className="grayText">
                Не предоставлена отчетность{" "}
              </Typography>
              <Typography sx={css.topText as SxProps} className="grayText">
                {header ? header[0].realization.noData : 0}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box sx={css.headerEventContainer}>
        {(open || eventModalMode === "default") && (
          <Box flex={"0"}>
            <Box
              sx={css.closeButton as SxProps}
              onClick={() => {
                setOpen?.(false);
                setEventModalMode?.("default");
                setSelectedEventId("");
              }}
            >
              Закрыть
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export const EventCard = ({
  setOpen,
  open,
  setEventModalMode,
  eventModalMode,
}: IHeaderProps) => {
  const { loading } = useEventData();

  const { items: controlPoints } = useControlPoints();
  const { items: modalSideBar } = useModalSideBar();
  const { items: cashExecution } = useCashExecution();

  const { data: data1 } = cashExecution[0] || {};
  const { data: modalSideBarData1 } = modalSideBar[0] || {};

  const [headerData, setHeaderData] = useState<IDataHeader[]>([]);
  const [checkpointsData, setCheckpointsData] = useState<IDataCheckpoints[]>(
    []
  );
  const [modalSideBarData, setModalSideBarData] = useState<
    IDataCodeEventCard[]
  >([]);
  const [modalSideBarMetaData, setModalSideBarMetaData] = useState<
    IDataHeader[]
  >([]);
  const [footerView, setFooterView] = useState<
    "controlPoints" | "reason" | "correction"
  >("controlPoints");
  const { data } = controlPoints[0] || {};

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      setHeaderData(data2);
    }
    if (data && data.length > 0) {
      const { data: checkpoints } = data[0] || {};
      setCheckpointsData(checkpoints);
    }
    if (modalSideBarData1 && modalSideBarData1.length > 0) {
      const { data: sideBar } = modalSideBarData1[0] || {};
      setModalSideBarMetaData(sideBar);
      const { data: sideBar1 } = sideBar[0] || {};
      setModalSideBarData(sideBar1);
    }
  }, [data1, data, modalSideBarData1, controlPoints]);

  const header = useMemo<IHeader[]>(
    () => [
      {
        code: modalSideBarMetaData[0]?.code,
        name: modalSideBarMetaData[0]?.NAME,
        headerIndicators: [
          {
            indicator: "Ответственный",
            value: modalSideBarMetaData[0]?.["Ответственный исполнитель"],
          },
          {
            indicator: "Федеральный проект",
            value: modalSideBarMetaData[0]?.["Наименование проекта"],
          },
          {
            indicator: "Уровень мероприятия",
            value: " - ",
          },
          {
            indicator: "Единица измерения",
            value: modalSideBarMetaData[0]?.["Ед. измерения результата"],
          },
        ],
        realization: {
          total: 0,
          done: 0,
          notDone: 0,
          noData: 0,
        },
      },
    ],
    [modalSideBarMetaData]
  );
  const [unit, setUnit] = useState<string>("млрд");

  const sidebarData = useMemo<IDataSideBar[]>(
    () => [
      {
        achievementLevel:
          roundNumbersToFixed(
            modalSideBarData?.find((it) => it.code === "1359")?.sum || 0,
            2
          ) || 0,
        corrections: {
          value: {
            worse: modalSideBarData?.find((it) => it.code === "1825")?.sum || 0,
            better:
              modalSideBarData?.find((it) => it.code === "1823")?.sum || 0,
          },
          time: {
            worse: modalSideBarData?.find((it) => it.code === "1826")?.sum || 0,
            better:
              modalSideBarData?.find((it) => it.code === "1824")?.sum || 0,
          },
        },
        event: {
          plan:
            roundNumbersToFixed(
              modalSideBarData?.find((it) => it.code === "1352")?.sum || 0,
              2
            ) || "-",
          fact:
            roundNumbersToFixed(
              modalSideBarData?.find((it) => it.code === "1358")?.sum || 0,
              2
            ) || "-",
          forecast:
            roundNumbersToFixed(
              modalSideBarData?.find((it) => it.code === "1827")?.sum || 0,
              2
            ) || "-",
        },
        realization: {
          plan: modalSideBarData?.find((it) => it.code === "1366")?.sum || "-",
          fact: modalSideBarData?.find((it) => it.code === "1367")?.text || "-",
        },
        financing: {
          plan:
            unit === "млрд"
              ? roundNumbersToFixed(
                  modalSideBarData?.find((it) => it.code === "1360")?.sum || 0,
                  2
                )
              : unit === "млн"
              ? roundNumbersToFixed(
                  (modalSideBarData?.find((it) => it.code === "1360")?.sum ||
                    0) * 1000,
                  2
                )
              : roundNumbersToFixed(
                  (modalSideBarData?.find((it) => it.code === "1360")?.sum ||
                    0) * 1000000,
                  2
                ),
          fact:
            unit === "млрд"
              ? roundNumbersToFixed(
                  modalSideBarData?.find((it) => it.code === "1361")?.sum || 0,
                  2
                )
              : unit === "млн"
              ? roundNumbersToFixed(
                  (modalSideBarData?.find((it) => it.code === "1361")?.sum ||
                    0) * 1000,
                  2
                )
              : roundNumbersToFixed(
                  (modalSideBarData?.find((it) => it.code === "1361")?.sum ||
                    0) * 1000000,
                  2
                ),
        },
      },
    ],
    [unit, modalSideBarData]
  );
  if (loading) {
    return (
      <Box sx={{ width: "90vw", height: "70vh" }}>
        <Overlay>
          <CircularProgress />
        </Overlay>
      </Box>
    );
  }

  return (
    <Box sx={css.eventWrapper}>
      <Grid sx={css.headerWrapper as SxProps}>
        <Header
          setOpen={setOpen}
          open={open}
          setEventModalMode={setEventModalMode}
          eventModalMode={eventModalMode}
          data={modalSideBarMetaData}
          header={header}
        />
      </Grid>
      <Grid container sx={css.contentEvent as SxProps}>
        <Grid item xs={3} sx={css.sideBarEvent as SxProps}>
          <EventCardSidebar
            data={sidebarData[0]}
            setUnit={setUnit}
            unit={unit}
          />
        </Grid>
        <Grid item xs={9} sx={css.tabsEvent}>
          <EventTabs
            result={modalSideBarMetaData[0]?.["Характеристика результата"]}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Box
            sx={css.eventWidgetContainer as SxProps}
            className="eventWidgetBottom"
          >
            {/*<Button*/}
            {/*  sx={css.sideBarButton(footerView === "controlPoints")}*/}
            {/*  onClick={() => setFooterView("controlPoints")}*/}
            {/*>*/}
            {/*  Контрольные точки*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  sx={css.sideBarButton(footerView === "reason")}*/}
            {/*  onClick={() => setFooterView("reason")}*/}
            {/*>*/}
            {/*  Причина отклонения*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  sx={css.sideBarButton(footerView === "correction")}*/}
            {/*  onClick={() => setFooterView("correction")}*/}
            {/*>*/}
            {/*  Корректировка значения/срока*/}
            {/*</Button>*/}
          </Box>
        </Grid>
        <Grid item xs={9}>
          {footerView === "controlPoints" ? (
            checkpointsData &&
            checkpointsData.length > 0 && (
              <Box sx={css.checkpointContainer}>
                <Checkpoints checkpoints={checkpointsData} />
              </Box>
            )
          ) : footerView === "correction" ? (
            <Box sx={css.checkpointContainer}>
              <EventCorrection />
            </Box>
          ) : (
            <Box sx={css.checkpointContainer}>
              <Box>reason</Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
