import {
  Box,
  Button,
  Checkbox,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { MutableRefObject, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { ColumnsTableModal } from "SRC/components/ColumnsTableModal/ColumnsTableModal";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { BaseTable } from "SRC/components/Table";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { GeneralInfoTabFilters } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralFullInfoTab/GeneralInfoTabFilters/GeneralInfoTabFilters";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import {
  IGeneralInfoDataItem,
  IGeneralInfoDataItemWithoutIcon,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";

import { getGeneralInfoColumns } from "./tableData";

interface IGeneralInfoTabProps {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  tableData: IGeneralInfoDataItem[];
  program: string;
  setSelectedProgram: (code: string) => void;
  isTableModalOpen?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  generalInfoVicePremiersFilter: string;
  setGeneralInfoVicePremiersFilter: (value: string) => void;
  generalInfoFoivFilter: string;
  setGeneralInfoFoivFilter: (value: string) => void;
  generalInfoVicePremiersFilterValues: (string | number | undefined)[];
  generalInfoFoivFilterValues: (string | undefined)[];
  downloadData: IGeneralInfoDataItemWithoutIcon[];
  infoRows: Record<string, string>[][];
  isColumnsModalOpen?: boolean;
  setColumnsModalOpen?: (value: boolean) => void;
}

export const GeneralFullInfoTab = ({
  posTable,
  forwardRef,
  onScroll,
  tableData,
  program,
  isTableModalOpen,
  setTableModalOpen,
  setSelectedProgram,
  generalInfoVicePremiersFilter,
  setGeneralInfoVicePremiersFilter,
  generalInfoFoivFilter,
  setGeneralInfoFoivFilter,
  generalInfoVicePremiersFilterValues,
  generalInfoFoivFilterValues,
  downloadData,
  infoRows,
  isColumnsModalOpen,
  setColumnsModalOpen,
}: IGeneralInfoTabProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(
    () => getGeneralInfoColumns(theme, matches),
    [theme, matches]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy,
    allColumns,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { headings, mergeCells, wscols } = useTableExportParams(
    downloadData,
    "summaryPrograms"
  );

  const RenderTable = () => {
    return (
      <>
        {isTableModalOpen && (
          <Box
            sx={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            {(matches || isTableModalOpen) && <GeneralInfoTabFilters />}
            <Box sx={css.actionButtonsContainer}>
              <TableActionsButtons
                open={isTableModalOpen}
                setOpen={setTableModalOpen}
                downloadData={downloadData}
                fileName="Сводная таблица. Госпрограммы"
                wscols={wscols}
                headings={headings}
                mergeCells={mergeCells}
                infoRows={infoRows}
              />
              <Button
                sx={css.columnsButton as SxProps}
                onClick={() => setColumnsModalOpen?.(true)}
              >
                Столбцы
              </Button>
            </Box>
          </Box>
        )}
        <Box sx={css.tablesWrapper(true)}>
          <BaseTable
            columns={columns}
            data={tableData}
            sortedColumns={[
              "Вице-премьер",
              "Министерства",
              "Уровень достижения",
              "Удовлетворенность населения",
            ]}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            footerGroups={footerGroups}
            rows={rows}
            prepareRow={prepareRow}
            setSortBy={setSortBy}
            tableHeight={
              isTableModalOpen
                ? "calc(100vh - 17rem)"
                : matches
                ? css.tableHeightLong
                : css.tableHeight
            }
            selectedRow={program}
            setSelectedRow={setSelectedProgram}
            wrapSort={matches}
            positionTable={posTable}
            forwardRef={forwardRef}
            onScroll={onScroll}
            scrollX={true}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <RenderTable />
      {!matches && (
        <TableModal
          open={isTableModalOpen}
          setOpen={setTableModalOpen}
          tableName="Сводная таблица. Госпрограммы"
        >
          <RenderTable />
        </TableModal>
      )}
      <ColumnsTableModal
        setOpen={setColumnsModalOpen}
        open={isColumnsModalOpen}
        allColumns={allColumns}
        includesByDefault={["id", "icon", "govProgram", "vicePremier", "foiv"]}
      />
    </>
  );
};
