import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPrograms } from "SRC/redux/slices/main/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

interface IDataItem {
  sum?: number;
  code: string;
  text?: string;
}

export interface IProgram {
  code: string;
  "Вице-премьер": string | number;
  "Вице-премьер, фото": string;
  Отраслевая: string;
  Комплексная: string;
  Секретная: string;
  "Вице-премьер, код": string;
  gp_code: string;
  gp_short_name: string;
  gp_icon_url: string;
  Министерство: string;
  gp_name: string;
  data: IDataItem[];
}

export interface IProgramsState {
  items: IProgram[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "programs/get",
    async (period: IPeriod) => await getPrograms(period)
  ),
};

const initialState: IProgramsState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIProgram = (array: any): array is IProgram[] => !!array?.length;

export const slice = createSlice({
  name: "programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IProgramsState, action) => {
        state.items = isIProgram(action.payload.data)
          ? action.payload.data
          : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
