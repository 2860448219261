import { Box, SxProps, Typography, useTheme } from "@mui/material";
import ReactECharts from "echarts-for-react";
import React, { useMemo } from "react";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import themeConfig from "SRC/theme";

import { getOption } from "./options";
import { css } from "./ProgressDoughnutChart.styled";

interface IProps {
  total: number;
  value: number;
  chartSmall?: boolean;
  withDarkBgr?: boolean | undefined;
  children?: React.ReactNode;
}

const ProgressDoughnutChart = ({
  total,
  value,
  withDarkBgr,
  children,
  chartSmall,
}: IProps) => {
  const theme = useTheme<typeof themeConfig>();

  const option = useMemo(
    () => getOption(value, total, withDarkBgr, chartSmall, theme),
    [theme, withDarkBgr, value, total, chartSmall]
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.textContainer(withDarkBgr) as SxProps}>
        {!chartSmall ? (
          <>
            <Typography sx={css.symbol as SxProps}>%</Typography>
            <Typography sx={css.text as SxProps}>
              {roundNumbersToFixed(value, 1)}
            </Typography>
          </>
        ) : (
          <Typography sx={css.textSmall}>
            {value}
            <span>%</span>
          </Typography>
        )}
        {children}
      </Box>
      <ReactECharts option={option} style={css.graph} />
    </Box>
  );
};

export default ProgressDoughnutChart;
