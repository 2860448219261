import { Box, CircularProgress } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Overlay } from "SRC/pages/App.styled";
import { EventCard } from "SRC/pages/Program/common/EventCard/EventCard";
import { useEventData } from "SRC/redux/slices/gosprogram/hooks/useEventData";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { actions as cashExecutionActions } from "SRC/redux/slices/gosprogram/slices/cashExecution";
import { actions as controlPointsActions } from "SRC/redux/slices/gosprogram/slices/controlPoints";
import { actions as listOfObjectsActions } from "SRC/redux/slices/gosprogram/slices/listOfObjects";
import { actions as modalSideBarActions } from "SRC/redux/slices/gosprogram/slices/modalSideBar";
import { actions as subjectsActions } from "SRC/redux/slices/gosprogram/slices/subjects";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

interface IEventCardWrapperProps {
  setOpen?: Dispatch<SetStateAction<boolean>>;
  open?: boolean;
  setEventModalMode?: Dispatch<
    SetStateAction<"default" | "eventCard" | "eventsFilter">
  >;
  eventModalMode?: "default" | "eventCard" | "eventsFilter";
}

export const EventCardWrapper = ({
  setEventModalMode,
  eventModalMode,
  open,
  setOpen,
}: IEventCardWrapperProps) => {
  const { period }: IFilters = useFilters();

  const { selectedEventId } = useProgramFilters();
  const dispatch = useDispatch();

  const { loading } = useEventData();

  // useEffect(() => {
  //   if (!loading && !loaded && !hasError && selectedEventId) {
  //     init({
  //       id: selectedEventId,
  //       period: period.filterValue,
  //     });
  //   }
  // }, [loading, loaded, hasError, selectedEventId]);

  useEffect(() => {
    dispatch(
      controlPointsActions.get({
        id: selectedEventId,
        period: period.filterValue,
      })
    );
    dispatch(
      cashExecutionActions.get({
        id: selectedEventId,
        period: period.filterValue,
      })
    );
    dispatch(
      subjectsActions.get({
        id: selectedEventId,
        period: period.filterValue,
      })
    );
    dispatch(
      modalSideBarActions.get({
        id: selectedEventId,
        period: period.filterValue,
      })
    );
    dispatch(
      listOfObjectsActions.get({
        id: selectedEventId,
        period: period.filterValue,
      })
    );
  }, [dispatch, selectedEventId, period]);

  if (loading) {
    return (
      <Box sx={{ width: "100%", height: "70vh" }}>
        <Overlay>
          <CircularProgress />
        </Overlay>
      </Box>
    );
  }

  return (
    <EventCard
      setEventModalMode={setEventModalMode}
      eventModalMode={eventModalMode}
      open={open}
      setOpen={setOpen}
    />
  );
};
