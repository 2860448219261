import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { CounterBox } from "SRC/pages/Program/common/NationalGoalsConnect/CounterBox/CounterBox";
import { GoalItem } from "SRC/pages/Program/common/NationalGoalsConnect/GoalItem/GoalItem";
import {
  INationalGoals,
  IStateProgramIndicators,
} from "SRC/pages/Program/common/NationalGoalsConnect/interfaces";
import { ProgramGoalItem } from "SRC/pages/Program/common/NationalGoalsConnect/ProgramGoalItem/ProgramGoalItem";
import { useGoalConnect } from "SRC/redux/slices/gosprogram/hooks/useGoalConnect";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";

import { ConnectorLine } from "./ConnectorLine/ConnectorLine";
import { css } from "./NationalGoalsConnect.styled";
import { ScrollButton } from "./ScrollButton/ScrollButton";

export interface IDataGoal {
  id: number;
  icon: string;
  goalTitle: string;
  factors: IFactorInfo[];
}

export interface IFactorInfo {
  idFactor: number;
  pointFactor: string;
  nameFactor: string;
  programId: number[];
}

export interface IDataProgramGoal {
  id: number;
  title: string;
  unit: string;
  goalsCode: number[];
  idGoalsFactor: number[];
}

export interface ICordsItemLeft {
  x: number;
  y: number;
  dataInfo: IDataProgramGoal;
  heightItem: number;
}

export interface ICordsItemRight {
  x: number;
  y: number;
  heightItem: number;
  dataInfo: IDataGoal;
}

export const NationalGoalsConnect = () => {
  const {
    selectedProgramGoalCodes,
    selectedProgramGoalId,
    selectedGoalId,
    selectedGoalProgramId,
    selectedHiddenFactorsId,
    selectedGoalFactorsId,
    selectedHiddenCountBottom,
  } = useProgramFilters();

  const refLeft = useRef<HTMLElement>();
  const refRight = useRef<HTMLElement>();
  const refWrapper = useRef<HTMLElement>();
  const refCanvasContainer = useRef<HTMLElement>();
  const [activeFilter, setActiveFilter] = useState(false);
  //--------canvas-------------
  const [leftList, setLeftList] = useState<HTMLElement | undefined>();

  const [rightItems, setRightItems] = useState<HTMLCollection | undefined>();
  const [leftItems, setLeftItems] = useState<HTMLCollection | undefined>();

  const [cordsLeftItems, setCordsLeftItems] = useState<ICordsItemLeft[]>([]);
  const [cordsRightItems, setCordsRightItems] = useState<ICordsItemRight[]>([]);
  const [scrollTopList, setScrollTopList] = useState<number | undefined>(0);

  const [wrapperCords, setWrapperCords] = useState<DOMRect | undefined>();

  const [widthWindow, setWidthWindow] = useState<number | undefined>();
  const [widthCanvas, setWidthCanvas] = useState<number | undefined>();
  const [heightCanvas, setHeightCanvas] = useState<number | undefined>();
  const hiddenCountItemsRef = useRef([0, 0]);
  const { items } = useGoalConnect();
  const data = useMemo(() => items[0]?.data?.data[0] || {}, [items]);

  const dataGoals = useMemo<IDataGoal[]>(
    () =>
      data?.NationalGoals.map((goal: INationalGoals, index: number) => ({
        id: goal.NationalGoalId, //NationalGoalId
        icon: require(`ASSETS/icons/nationals/icon${index + 1}.gif`).default,
        goalTitle: goal.NationalGoalName, //NationalGoalName
        factors: goal.GoalIndicators.map((indicator) => ({
          idFactor: indicator.GoalIndicatorId,
          pointFactor: indicator.GoalIndicatorNum,
          nameFactor: indicator.GoalIndicatorName,
          programId: indicator.StateIndicatorIds,
        })).filter((indicator) => indicator.programId !== null),
      })),
    [items]
  );

  const dataProgramGoals = useMemo<IDataProgramGoal[]>(
    () =>
      data?.StateProgramIndicators.map(
        (programGoal: IStateProgramIndicators) => ({
          id: programGoal.StateIndicatorId, //StateIndicatorId
          title: programGoal.StateIndicatorName, //StateIndicatorName
          unit: programGoal.StateIndicatorOKEIName, //StateIndicatorOKEIName
          goalsCode: programGoal?.Nationalgoalids,
          idGoalsFactor: programGoal?.IndGoalsIds,
        })
      ),
    [items]
  );
  useEffect(() => {
    const leftList = refLeft.current;
    setLeftList(leftList);
    if (leftList === null) return;
    const childLeft = leftList?.children;
    setLeftItems(childLeft);
    const childRight = refRight.current?.children;
    setRightItems(childRight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => setWidthWindow(window.innerWidth));
    setHeightCanvas(refLeft.current?.getBoundingClientRect().height);
    setWidthCanvas(refCanvasContainer.current?.getBoundingClientRect().width);
  }, [widthWindow, widthCanvas]);

  useEffect(() => {
    leftList?.addEventListener("scroll", () =>
      setScrollTopList(leftList?.scrollTop)
    );
  }, [leftList]);

  useEffect(() => {
    const wrapper = refWrapper.current?.getBoundingClientRect();
    setWrapperCords(wrapper);
    let arrayCords = [];
    if (leftItems !== undefined && wrapperCords !== undefined) {
      arrayCords = [];
      for (let i = 0; i < leftItems?.length; i++) {
        let cordLeftX = leftItems[i].getBoundingClientRect()?.x;
        let cordLeftY =
          leftItems[i].getBoundingClientRect()?.y - wrapperCords?.y;
        let heightItem = leftItems[i].getBoundingClientRect().height;
        arrayCords.push({
          x: cordLeftX,
          y: cordLeftY,
          heightItem: heightItem,
          dataInfo: dataProgramGoals[i],
        });
      }
      setCordsLeftItems(arrayCords);
    }
    if (rightItems !== undefined && wrapperCords !== undefined) {
      arrayCords = [];
      for (let i = 0; i < rightItems?.length; i++) {
        let cordRightX = rightItems[i].getBoundingClientRect()?.x;
        let cordRightY =
          rightItems[i].getBoundingClientRect()?.y - wrapperCords?.y;
        let heightItem = rightItems[i].getBoundingClientRect().height;
        arrayCords.push({
          x: cordRightX,
          y: cordRightY,
          heightItem: heightItem,
          dataInfo: dataGoals[i],
        });
      }
      setCordsRightItems(arrayCords);
    }
  }, [scrollTopList, leftList, rightItems, leftItems, widthCanvas]);

  useEffect(() => {
    if (selectedProgramGoalId !== "" || selectedGoalId !== "") {
      setActiveFilter(true);
    } else {
      setActiveFilter(false);
    }
  }, [selectedProgramGoalId, selectedGoalId]);

  const handleScrollUp = () => {
    if (refLeft.current) {
      refLeft.current.scrollTop = refLeft.current.scrollTop - 100;
    }
  };
  const handleScrollDown = () => {
    if (refLeft.current) {
      refLeft.current.scrollTop = refLeft.current.scrollTop + 100;
    }
  };
  const checkFilterGoal = (programGoal: IDataProgramGoal) => {
    let programActive = false;
    selectedGoalProgramId.map((item) => {
      if (item.includes(programGoal.id)) {
        programActive = true;
      }
    });
    let selectedProgramFactors = programGoal.idGoalsFactor.filter(
      (factorId) => {
        if (selectedGoalFactorsId.includes(factorId)) return true;
      }
    );
    if (
      selectedProgramFactors.every((factor) => {
        return selectedHiddenFactorsId.includes(factor);
      })
    ) {
      programActive = false;
    }
    return programActive;
  };
  return (
    <Box sx={css.connectWrapper}>
      <Box sx={css.itemsLeftContainer} ref={refWrapper}>
        <ScrollButton position={"top"} handleScroll={handleScrollUp} />
        <CounterBox position={"top"} count={hiddenCountItemsRef?.current[0]} />
        <Box sx={css.itemsLeftList} ref={refLeft} className="programGoalsList">
          {dataProgramGoals.map((programGoal, index) => (
            <ProgramGoalItem
              id={index}
              item={programGoal}
              activeFilter={activeFilter}
              filterProgram={
                !selectedGoalProgramId.length
                  ? false
                  : checkFilterGoal(programGoal)
              }
            />
          ))}
        </Box>
        <CounterBox
          position={"bottom"}
          count={hiddenCountItemsRef?.current[1]}
        />
        <ScrollButton position={"bottom"} handleScroll={handleScrollDown} />
      </Box>
      <Box sx={css.linesConnect(heightCanvas)} ref={refCanvasContainer}>
        <ConnectorLine
          cordsLeft={cordsLeftItems}
          cordsRight={cordsRightItems}
          widthCanvas={widthCanvas}
          heightCanvas={heightCanvas}
          activeFilter={activeFilter}
          hiddenCountItemsRef={hiddenCountItemsRef}
        />
      </Box>
      <Box sx={css.itemsRightList} ref={refRight}>
        {/*{dataGoals.map((goalItem, index) => (*/}
        {/*  <GoalItem*/}
        {/*    id={index}*/}
        {/*    item={goalItem}*/}
        {/*    activeFilter={activeFilter}*/}
        {/*    filterGoal={*/}
        {/*      !selectedProgramGoalCodes.length*/}
        {/*        ? false*/}
        {/*        : selectedProgramGoalCodes.includes(goalItem.id)*/}
        {/*    }*/}
        {/*  />*/}
        {/*))}*/}
        {dataGoals.map((item, index) => (
          <GoalItem
            id={index}
            item={item}
            activeFilter={activeFilter}
            filterGoal={
              !selectedProgramGoalCodes.length
                ? false
                : selectedProgramGoalCodes.includes(item.id)
            }
          />
        ))}
      </Box>
    </Box>
  );
};
