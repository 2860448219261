import { Box, Theme } from "@mui/material";
import React, { Fragment, SyntheticEvent } from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import { Analitics } from "SRC/pages/MainNew/common/Analitics/Analitics";
import GovPrograms from "SRC/pages/MainNew/common/GovPrograms/GovPrograms";
import { useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { NationalGoalsTable } from "../../common/NationalGoalsTable/NationalGoalsTable";
import { css } from "./center-layout.styled";

interface ICenterLayoutProps {
  theme: Theme;
  isLargeSize: boolean;
}
export const CenterLayout = ({ theme, isLargeSize }: ICenterLayoutProps) => {
  const { setSelectedCenterTab, centerTab } = useFilters();

  return (
    <Fragment>
      <GovPrograms />
      {!isLargeSize && (
        <Box sx={css.tabs}>
          <CustomTabs
            value={centerTab}
            onChange={(_: SyntheticEvent, value: any) =>
              setSelectedCenterTab(value)
            }
            tabs={[
              {
                value: "analytics",
                label: "Сводная аналитика",
                sx: css.tabSX(theme),
              },
              {
                value: "goals",
                label: "Национальные цели",
                sx: css.tabSX(theme),
              },
            ]}
            components={[<Analitics />, <NationalGoalsTable />]}
            style={{ width: "100%" }}
          />
        </Box>
      )}
      {isLargeSize && <Analitics />}
    </Fragment>
  );
};
