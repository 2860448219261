export const MAIN = "/";
export const NEW = "/new";
export const GOVPROGRAMS = "/programs";
export const SUMMARY = "/summary";
export const PROGRAM_DETAIL = "/program/:id";

interface IRoute {
  title: string;
  link: string;
}

export const urlList: IRoute[] = [
  { title: "Все госпрограммы", link: MAIN },
  { title: "Сводная таблица", link: SUMMARY },
];
