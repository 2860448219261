import { Theme } from "@mui/material";

export const getOption = (
  value: number,
  total: number,
  withDarkBgr: boolean | undefined,
  chartSmall: boolean | undefined,
  theme: Theme
) => ({
  grid: {
    borderWidth: 0,
    width: "100%",
    height: "100%",
    left: "0",
    bottom: "0",
  },
  tooltip: {
    show: false,
  },
  series: [
    {
      type: "pie",
      radius: withDarkBgr
        ? ["90%", "95%"]
        : chartSmall
        ? ["70%", "80%"]
        : ["85%", "95%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        scale: false,
      },
      labelLine: {
        show: false,
      },
      emptyCircleStyle: true,
      data: [
        {
          value: value,
          itemStyle: {
            color:
              Number(value) < 80
                ? theme.palette.error.main
                : theme.palette.success.main,
            borderRadius: theme.shape.borderRadius,
          },
        },
        {
          value: total - Number(value),
          itemStyle: {
            color: withDarkBgr
              ? theme.palette.complementary.darkBlue
              : theme.palette.main.darkBlue,
          },
        },
      ],
    },
  ],
});
