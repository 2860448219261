import { Box, SxProps } from "@mui/material";
import React, { useMemo, useState } from "react";
import { ProgressDoughnutPale } from "SRC/components/Charts";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  useSpheres,
  useSumSpheres,
} from "SRC/redux/slices/main/hooks/useSpheres";
import theme from "SRC/theme";

import { css } from "./Effectiveness.styled";

export const Effectiveness = () => {
  const { calculationMethod }: IFilters = useFilters();
  const { items: spheres } = useSpheres();
  const { items: sumSpheres } = useSumSpheres();
  const dataSphere = useMemo(() => spheres[0]?.data[0].data || [], [spheres]);
  const dataSumSphere = useMemo(
    () =>
      calculationMethod === "effect"
        ? roundNumbersToFixed(
            getDataSumByCodeGP(sumSpheres[0]?.data[0] || [], 1753) || 0,
            1
          )
        : roundNumbersToFixed(
            getDataSumByCodeGP(sumSpheres[0]?.data[0] || [], 1796) || 0,
            1
          ),
    [sumSpheres, calculationMethod]
  );

  const effectItems = useMemo(
    (): JSX.Element[] =>
      dataSphere.map((item: any) => {
        const progress =
          calculationMethod === "effect"
            ? roundNumbersToFixed(getDataSumByCodeGP(item, 1753) || 0, 0)
            : roundNumbersToFixed(getDataSumByCodeGP(item, 1796) || 0, 0);
        return (
          <Box sx={css.effectItem as SxProps}>
            <Box sx={css.itemTop}>
              <Box sx={css.title}>{item.code}</Box>
              <Box sx={css.num}>{getDataSumByCodeGP(item, 1837) || 0}</Box>
            </Box>
            <Box sx={css.itemBottom}>
              <Box sx={css.barContainer}>
                <Box sx={css.barTitle}>Уровень достижения</Box>
                <Box sx={css.barUnit}>{progress}</Box>
              </Box>
              <ProgressBar
                value={progress}
                progressColor={theme.palette.pale.green}
              />
            </Box>
          </Box>
        );
      }),
    [calculationMethod]
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.generalEffect}>
        <Box sx={css.title}>Эффективность выполнения программ</Box>
        <Box sx={css.chart}>
          <ProgressDoughnutPale total={100} value={dataSumSphere} />
        </Box>
      </Box>
      <Box sx={css.effectsContainer}>{effectItems}</Box>
    </Box>
  );
};
