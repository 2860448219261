import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import {
  BigWhiteText,
  MediumGrayTextBold,
  ThinMainText,
} from "SRC/pages/Program/common/GovProgramInfoWidget/GovProgramInfoWidget.styled";

import {
  BigBlueText,
  ColumnContainer,
  GridItemCenterText,
  GridItemWithBorder,
  LightBgrContainer,
} from "./FinancingCard.styled";

interface IFinancing {
  fact: number;
  plan: number;
  budget: {
    total: number;
    done: number;
  };
  extrabudget: {
    total: number;
    done: number;
  };
  units: string;
}

interface IProps {
  financing: IFinancing;
}

const FinancingCard = (props: IProps) => {
  const { financing } = props;

  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={3} md={2} lg={3}>
        <LightBgrContainer>
          <Grid container justifyContent="space-between">
            <GridItemCenterText item xs={6} style={{ alignSelf: "baseline" }}>
              <ThinMainText
                style={{
                  lineHeight: "2rem",
                }}
              >
                Факт
              </ThinMainText>
            </GridItemCenterText>
            <GridItemWithBorder item xs={6}>
              <BigWhiteText className="centered">
                {(financing.fact && roundNumbersToFixed(financing.fact, 1)) ||
                  0}
              </BigWhiteText>
            </GridItemWithBorder>
          </Grid>
          <Grid container justifyContent="space-between">
            <GridItemCenterText item xs={6} style={{ alignSelf: "end" }}>
              <ThinMainText
                style={{
                  lineHeight: "1.25rem",
                }}
              >
                План
              </ThinMainText>
            </GridItemCenterText>
            <GridItemCenterText
              item
              xs={6}
              style={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <MediumGrayTextBold>
                {(financing.plan && roundNumbersToFixed(financing.plan, 1)) ||
                  0}
              </MediumGrayTextBold>
            </GridItemCenterText>
          </Grid>
        </LightBgrContainer>
      </Grid>
      <Grid item xs={6} md={8} lg={6}>
        <ColumnContainer>
          <ThinMainText
            style={{
              lineHeight: "1.25rem",
            }}
          >
            Бюджет
          </ThinMainText>
          <Box sx={{ display: "flex" }}>
            <BigWhiteText
              style={{
                display: "flex",
              }}
            >
              {(financing.budget.done &&
                roundNumbersToFixed(financing.budget.done, 1)) ||
                0}
            </BigWhiteText>
            <MediumGrayTextBold
              style={{
                lineHeight: "2.2rem",
              }}
            >
              &nbsp;/{" "}
              {(financing.budget.total &&
                roundNumbersToFixed(financing.budget.total, 1)) ||
                0}
            </MediumGrayTextBold>
          </Box>
          <ProgressBar
            value={
              roundNumbersToFixed(
                (financing.budget.done / financing.budget.total) * 100,
                0
              ) || 0
            }
            progressColor={theme.palette.primary.contrastText}
            style={{ margin: "0.5rem 0" }}
          />
        </ColumnContainer>
      </Grid>
      <Grid item xs={3} md={2} lg={3}>
        <ColumnContainer>
          <ThinMainText
            style={{
              lineHeight: "1.25rem",
            }}
          >
            Внебюджет
          </ThinMainText>
          <Box sx={{ display: "flex" }}>
            <BigBlueText>
              {(financing.extrabudget.done &&
                roundNumbersToFixed(financing.extrabudget.done, 1)) ||
                0}
            </BigBlueText>
            <MediumGrayTextBold
              style={{
                lineHeight: "2.2rem",
              }}
            >
              &nbsp;/{" "}
              {(financing.extrabudget.total &&
                roundNumbersToFixed(financing.extrabudget.total, 1)) ||
                0}
            </MediumGrayTextBold>
          </Box>
          <ProgressBar
            value={
              roundNumbersToFixed(
                (financing.extrabudget.done / financing.extrabudget.total) *
                  100,
                0
              ) || 0
            }
            progressColor={theme.palette.main.blue}
            style={{ margin: "0.5rem 0" }}
          />
        </ColumnContainer>
      </Grid>
    </Grid>
  );
};

export default FinancingCard;
