import { Search } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { FilterValue } from "react-table";
import Select from "SRC/components/Select/Select";
import { css } from "SRC/pages/Main/common/ComplexGrid/ComplexGrid.styled";
import slice from "SRC/redux/slices/main/slices/filters";
import { dispatch } from "SRC/redux/store";

import { govProgramsTabStyled } from "../GovProgramsTab/GovProgramsTab.styled";

interface IGovProgramsFiltersProps {
  tableFilter: string;
  setFilter: Dispatch<
    SetStateAction<"Отраслевая" | "Секретная" | "Комплексная" | "">
  >;
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
}

const programTypes = ["Комплексная", "Отраслевая", "Секретная"];

const selectOptions = programTypes.map((label) => ({ value: label, label }));

export const GovProgramsFilters = ({
  tableFilter,
  setFilter,
  globalFilter,
  setGlobalFilter,
}: IGovProgramsFiltersProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    dispatch(slice.actions.selectTypeProgram(tableFilter));
  });
  return (
    <Grid
      container
      justifyContent={match ? "space-between" : "flex-start"}
      sx={{
        marginBottom: match ? "1rem" : "0rem",
        maxWidth: match ? "100%" : "60%",
      }}
    >
      <Grid item sm={5} lg={4}>
        <Select
          options={selectOptions}
          value={tableFilter}
          onChange={(value: any) => setFilter(value)}
          label="Тип госпрограммы"
        />
      </Grid>
      <Grid
        item
        sm={5}
        lg={7}
        sx={{
          textAlign: "right",
          pl: 10,
          alignSelf: "flex-end",
        }}
      >
        <FormControl
          sx={{ ...css.uiSearch, ...govProgramsTabStyled.searchInputWrapper }}
          variant="filled"
        >
          <FilledInput
            startAdornment={
              <InputAdornment position="start">
                <Search sx={govProgramsTabStyled.search} />
              </InputAdornment>
            }
            placeholder="Поиск госпрограммы"
            autoFocus={Boolean(globalFilter)}
            value={globalFilter}
            sx={govProgramsTabStyled.searchInput}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
