import { Theme } from "@mui/material";
import { ChartBgr } from "ASSETS/svg/backgrounds";

const textMixin = {
  fontSize: "2.5rem",
  lineHeight: "2.5rem",
  fontWeight: "bold",
};

export const css = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    background: `url(${ChartBgr}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  graph: {
    width: "100%",
    height: "100%",
  },
  textContainer: {
    width: "38%",
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.2rem",
  },
  text: {
    ...textMixin,
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  grayText: {
    ...textMixin,
    fontSize: "2rem",
    lineHeight: "2rem",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
  },
};
