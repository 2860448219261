import { Box, styled, Theme } from "@mui/material";

export const TabsWrapper = styled(Box)({
  width: "100%",
});

export const css = {
  informer: {
    height: 40,
    display: "flex",
    alignItems: " center",
    px: 6,
    py: 2,
    color: (theme: Theme) => theme.palette.text.secondary,
  },
  numberCurrent: {
    color: (theme: Theme) => theme.palette.text.primary,
  },
  numberLine: {
    px: 1,
  },

  search: {
    maxWidth: (theme: Theme) => theme.spacing(150),
    "& .MuiFilledInput-root": {
      background: (theme: Theme) => theme.palette.background.paper,
      borderRadius: (theme: Theme) => theme.shape.borderRadius,
      overflow: "hidden",
      px: 4,
      py: 0,
      height: 45,
      "&:hover": {
        background: (theme: Theme) => theme.palette.background.paper,
      },
    },
    "& .MuiFilledInput-input": {
      p: 0,
      height: 45,
    },
    "& .MuiInputAdornment-root": {
      m: "0 !important",
    },
  },

  headSubTitle: {
    "& .MuiTypography-root": {
      color: (theme: Theme) => theme.palette.text.secondary,
    },
  },

  uiSearch: {
    maxWidth: (theme: Theme) => theme.spacing(150),
    "& .MuiFilledInput-root": {
      background: (theme: Theme) => theme.palette.background.paper,
      borderRadius: "0.5rem",
      overflow: "hidden",
      px: 4,
      py: 0,
      height: "2.5rem",
      "&:hover": {
        background: (theme: Theme) => theme.palette.background.paper,
        borderBottom: "none",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
    },
    "& .MuiFilledInput-input": {
      p: 0,
    },
    "& .MuiInputAdornment-root": {
      m: "0 !important",
    },
  },
};
