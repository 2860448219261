import React, { useMemo, useState } from "react";
import { CustomTabs } from "SRC/components/CustomTabsNew/CustomTabs";
import { Financing } from "SRC/pages/MainNew/common/Analitics/Financing/Financing";
import { Risks } from "SRC/pages/MainNew/common/Analitics/Risks/Risks";
import { useStats } from "SRC/redux/slices/main/hooks/useStats";
import { IIndicatorRisksData } from "SRC/types/analitics";

export const RisksTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { items: stats } = useStats();
  const data = useMemo(() => stats[0]?.data || {}, [stats]);

  const { objects, events, indicatiorRegulations, indicatorRegulationsAtRisk } =
    useMemo<IIndicatorRisksData>(
      () => ({
        objects: {
          plan: data["Создано объектов, план"],
          fact: data["Создано объектов, факт"],
          risks: data["Объектов под риском"],
          percent: data["Создано объектов, %"],
        },
        events: {
          plan: data["Мероприятий всего"],
          fact: data["Мероприятий под риском"],
          risks: data["Мероприятий под риском"],
          percent: data["Мероприятия под риском, %"],
        },
        indicatiorRegulations: {
          plan: data["Нормативно-правовые акты, план"],
          fact: data["Нормативно-правовые акты, факт"],
          percent: data["Нормативно правовые акты, %"],
          risks: data["Нормативно правовые акты, %"],
        },
        indicatorRegulationsAtRisk: {
          plan: data["Нормативно-правовые акты, план"],
          fact: data["Нормативно правовые акты, под риском"],
          percent: data["Нормативно правовые акты, %"],
          risks: data["Нормативно правовые акты, под риском"], // TODO НПА под риском
        },
      }),
      [data]
    );
  return (
    <CustomTabs
      isSmall={true}
      value={activeTab}
      onChange={(event, newValue) => {
        setActiveTab(newValue);
      }}
      tabs={[
        {
          value: 0,
          label: "OKC",
          // sx: indicatorTabStyles,
        },
        {
          value: 1,
          label: "Риски",
          // sx: indicatorTabStyles,
        },
      ]}
      components={[
        <Risks
          topName={"Создано объектов"}
          dataTop={objects}
          dataBottom={indicatiorRegulations}
        />,
        <Risks
          topName={"Мероприятия"}
          dataTop={events}
          dataBottom={indicatorRegulationsAtRisk}
        />,
      ]}
    />
  );
};
