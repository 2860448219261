import { Theme, Typography } from "@mui/material";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import {
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getEventsColumns = (theme: Theme) => {
  return [
    {
      Header: "План",
      width: css.eventsGoalsColumn.width,
      accessor: "eventsPlan",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText align="center">
          {tableProps.row.original.eventsPlan}
        </TableCellBigText>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.eventsPlan + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <TableFooterText sx={css.goalsColumn.plan.footerWrapper(theme)}>
            {total}
          </TableFooterText>
        );
      },
    },
    {
      Header: "Выполнено",
      width: css.eventsGoalsColumn.width,
      accessor: "eventsDone",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText
          align="center"
          sx={css.goalsColumn.done.cellWrapper(theme)}
        >
          {tableProps.row.original.eventsDone}
        </TableCellBigText>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.eventsDone + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <TableFooterText
            align="center"
            sx={css.goalsColumn.done.footerWrapper(theme)}
          >
            {total}
          </TableFooterText>
        );
      },
    },
    {
      Header: "Не выполнено",
      width: css.eventsGoalsColumn.width,
      accessor: "eventsFail",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          align="center"
          sx={tableCss.tableCellText}
          className="bigText with-right-border redText"
        >
          {tableProps.row.original.eventsFail}
        </Typography>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const total = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          return rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.eventsFail + sum,
            0
          );
        }, [tableProps.rows]);

        return (
          <TableFooterText
            align="center"
            sx={tableCss.tableCellText}
            className="with-right-border tableFooterText redText"
          >
            {total}
          </TableFooterText>
        );
      },
    },
  ];
};

export { getEventsColumns };
