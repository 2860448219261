import { Theme } from "@mui/material";

export const css = {
  backWrapper: {
    width: "100%",
    padding: "1rem 6rem 0 6rem",
  },
  backContainer: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer",
  },
  arrow: {
    width: "0.5rem",
    height: "1rem",
    marginRight: "1rem",
  },
  backText: {
    fontSize: "1rem",
    lineHeight: "1rem",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: "2px",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
};
