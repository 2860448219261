import { Theme } from "@mui/material";

export const css = {
  container: (active: boolean) => ({
    opacity: active ? 1 : 0.4,
    position: "relative",
    width: "100%",
    flexShrink: "0",
    background: (theme: Theme) =>
      `linear-gradient(180deg, ${theme.palette.background.paperDarkBlue} 0%, ${theme.palette.background.paperDarkGray} 100%)`,
    borderRadius: "22px",
  }),
  heading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "1.5rem",
    justifyContent: "space-between",
  },
  iconContainer: (theme: Theme) => ({
    height: "5rem",
    width: "2.9rem",
    borderRadius: "3rem",
    position: "relative",
    backgroundColor: theme.palette.main.darkBlue,
    transform: "translateX(-50%)",
    border: `2px solid ${theme.palette.complementary.darkBlue}`,
    flexShrink: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2px 0",
  }),
  idText: (theme: Theme) => ({
    fontSize: "1.125rem",
    lineHeight: "1.8em",
    fontWeight: "bold",
    color: theme.palette.main.gray,
  }),
  icon: {
    height: "2.25rem",
    width: "auto",
  },
  programName: (theme: Theme) => ({
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    whiteSpace: "break-spaces",
  }),
  circleGraph: {
    height: "15rem",
    padding: "1.6rem 0",
    borderBottom: (theme: Theme) => `3px dotted ${theme.palette.main.darkBlue}`,
  },
  achievementText: {
    fontSize: "1rem",
    lineHeight: "1rem",
    textAlign: "center",
  },
  h4: {
    mt: 5,
    fontSize: "1.75rem",
  },
  upArrowContainer: (theme: Theme) => ({
    height: "1.9rem",
    width: "1.9rem",
    backgroundColor: theme.palette.main.darkBlue,
    borderRadius: "8px",
    marginRight: "1rem",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexShrink: 0,
    zIndex: 2,
    cursor: "pointer",
  }),
  performanceArrow: {
    height: "0.6rem",
    width: "0.6rem",
  },
  hintContainer: (theme: Theme) => ({
    position: "absolute",
    top: "4.25rem",
    right: "0",
    background: theme.palette.text.secondary,
    padding: "1.5rem",
    borderRadius: "1rem",
    zIndex: 1,
    maxWidth: "20rem",
  }),
  hintText: (theme: Theme) => ({
    fontSize: "1.5rem",
    lineHeight: "1.2em",
    color: theme.palette.main.gray,
    whiteSpace: "break-spaces",
  }),
};
