import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: (theme: Theme) => theme.palette.background.paper,
    borderRadius: "1rem",
    padding: "0.75rem 1.5rem 1.125rem 1.5rem",
    gap: "1.875rem",
    minHeight: "22.65rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "0.5rem",
    gap: "1rem",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.opacityWhite}`,
  },
  headerLeft: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
  headerRight: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
};
