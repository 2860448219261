import { Box, useMediaQuery, useTheme } from "@mui/material";
import { MutableRefObject, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import {
  IGeneralInfoDataItem,
  ITableDataItem,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

import { getGeneralInfoColumns } from "./tableData";

interface IGeneralInfoTabProps {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  setSortBy: (sortBy: any) => void;
  tableData: ITableDataItem[];
}

export const GeneralInfoTab = ({
  posTable,
  forwardRef,
  onScroll,
  setSortBy,
  tableData,
}: IGeneralInfoTabProps) => {
  const theme = useTheme();
  const { program, setSelectedProgram }: IFilters = useFilters();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(
    () => getGeneralInfoColumns(theme, matches),
    [theme, matches]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy: baseSortBy,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Box sx={css.tablesWrapper(false)}>
      <BaseTable
        columns={columns}
        data={tableData}
        sortedColumns={[
          "Вице-премьер",
          "Министерства",
          "Уровень достижения",
          "Удовлетворенность населения",
        ]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={(sortBy) => {
          setSortBy(sortBy);
          baseSortBy(sortBy);
        }}
        tableHeight={matches ? css.tableHeightLong : css.tableHeight}
        selectedRow={program}
        setSelectedRow={setSelectedProgram}
        wrapSort={matches}
        positionTable={posTable}
        forwardRef={forwardRef}
        onScroll={onScroll}
        scrollX={false}
      />
    </Box>
  );
};
