import { Box, SxProps, Theme, Typography } from "@mui/material";
import { SecretProgram, WarningTriangle } from "ASSETS/svg";
import React, { CSSProperties } from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/elements/Percent/Percent";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getGeneralInfoColumns = (theme: Theme, isLgWidth: boolean) => {
  const data = [
    {
      Header: "№",
      accessor: "id",
      width: css.idColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <>
          <Box sx={css.idCell}>
            <Box sx={css.iconContainer}>
              {tableProps.row.original.secretProgram === 1 && (
                <Box sx={css.secretProgram}>
                  <SecretProgram />
                </Box>
              )}
              {tableProps.row.original.atRisk > 0 && (
                <Box>
                  <WarningTriangle
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  />
                </Box>
              )}
            </Box>
            <Typography
              sx={tableCss.tableCellText}
              align="center"
              className="grayText"
            >
              {tableProps.row.original.id}
            </Typography>
          </Box>
        </>
      ),
      Footer: " ",
    },
    {
      Header: " ",
      accessor: "icon",
      width: css.iconColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.iconColumn.cellWrapper as CSSProperties}>
          <img
            style={tableCss.tableImage(true)}
            src={tableProps.row.original.icon}
            alt="govProgram"
          />
        </div>
      ),
      Footer: " ",
    },
    {
      Header: <Box sx={css.nameColumn.headerWrapper}>Госпрограмма</Box>,
      accessor: "govProgram",
      width: css.nameColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <Typography sx={tableCss.tableCellText} className="cellWrapper">
          {tableProps.row.original.govProgram}
        </Typography>
      ),
      Footer: (
        <TableFooterText align="left" sx={css.nameColumn.footerWrapper(theme)}>
          Итого
        </TableFooterText>
      ),
    },
    {
      Header: "Вице-премьер",
      accessor: "vicePremier",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText sx={css.vicePremierColumn.cellText(theme) as SxProps}>
          {tableProps.row.original.vicePremier}
        </TableCellBigText>
      ),
      Footer: " ",
    },
    {
      Header: "Министерство",
      accessor: "foiv",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.cashColumn.fact.cellWrapper as CSSProperties}>
          <TableCellBigText sx={css.foivColumn.cellText(theme) as SxProps}>
            {tableProps.row.original.foiv}
          </TableCellBigText>
        </div>
      ),
      Footer: " ",
    },
    {
      Header: "Уровень достижения",
      accessor: "performance",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.performanceColumn.cellWrapper}>
          <TableCellBigText
            sx={css.performanceColumn.text(
              theme,
              tableProps.row.original.performance
            )}
          >
            {`${tableProps.row.original.performance}`}
            <Percent theme={theme} />
          </TableCellBigText>
          <ProgressBar value={tableProps.row.original.performance || 0} />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.performance + sum,
            0
          );
          return roundNumbersToFixed(total / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.govProgramsColumn.footerWrapper}>
            <TableFooterText
              sx={css.performanceColumn.footerText(theme, average)}
            >
              {`${average}`}
              <Percent theme={theme} />
            </TableFooterText>
            <ProgressBar value={average} />
          </div>
        );
      },
    },
  ];

  if (!isLgWidth) {
    data.push({
      Header: "Удовлетворенность населения",
      accessor: "populationSatisfaction",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.populationSatisfactionColumn.cellWrapper}>
          <TableCellBigText
            sx={css.populationSatisfactionColumn.cellText(
              theme,
              tableProps.row.original.populationSatisfaction
            )}
          >
            {`${tableProps.row.original.populationSatisfaction}`}
            <Percent theme={theme} />
          </TableCellBigText>
          <ProgressBar
            value={tableProps.row.original.populationSatisfaction || 0}
          />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.populationSatisfaction + sum,
            0
          );

          return roundNumbersToFixed(total / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.populationSatisfactionColumn.footerWrapper}>
            <TableFooterText
              sx={css.performanceColumn.footerText(theme, average)}
            >
              {`${average}`}
              <Percent theme={theme} />
            </TableFooterText>
            <ProgressBar value={average} />
          </div>
        );
      },
    });
  }

  return data;
};

export { getGeneralInfoColumns };
