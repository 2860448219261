import { combineReducers } from "@reduxjs/toolkit";
import headerPrograms, {
  IHeaderProgramsState,
} from "SRC/redux/slices/main/slices/headerPrograms";

import filtersSlice, { IFiltersState } from "./slices/filters";
import goalsSlice, { IGoalsState } from "./slices/goals";
import ministriesSlice, { IMinistriesState } from "./slices/ministries";
import { IPremiersState, slice as premiersSlice } from "./slices/premiers";
import { IProgramsState, slice as programsSlice } from "./slices/programs";
import {
  ISpheresState,
  slice as shperesSlice,
  slice2 as sumSpheresSlice,
} from "./slices/spheres";
import statsSlice, { IStatsState } from "./slices/stats";

export interface IMainReducer {
  ministries: IMinistriesState;
  programs: IProgramsState;
  goals: IGoalsState;
  premiers: IPremiersState;
  stats: IStatsState;
  filters: IFiltersState;
  headerPrograms: IHeaderProgramsState;
  spheres: ISpheresState;
  sumSpheres: ISpheresState;
}

const reducer = combineReducers<IMainReducer>({
  ministries: ministriesSlice.reducer,
  programs: programsSlice.reducer,
  goals: goalsSlice.reducer,
  premiers: premiersSlice.reducer,
  stats: statsSlice.reducer,
  filters: filtersSlice.reducer,
  headerPrograms: headerPrograms.reducer,
  spheres: shperesSlice.reducer,
  sumSpheres: sumSpheresSlice.reducer,
});

export default reducer;
