import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";
import { getAllPremiersInfo } from "SRC/redux/slices/summary/api/client";

interface IDataItem {
  sum?: number;
  code: string;
  text?: string;
}

export interface IPremier {
  VP_NAME: string;
  VP_CODE: string;
  VP_PHOTO_URL: string;
  VP_SHORT_NAME: string;
  code: string;
  data: IDataItem[];
}

export interface IPremiersState {
  items: IPremier[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "premiers-full/get",
    async (period: IPeriod) => await getAllPremiersInfo(period)
  ),
};

const initialState: IPremiersState = {
  items: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIPremier = (array: unknown): boolean =>
  Array.isArray(array) && !!array.length;

export const slice = createSlice({
  name: "premiersFull",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IPremiersState, action) => {
        state.items = isIPremier(action.payload.data)
          ? (action.payload.data as unknown as IPremier[])
          : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};
