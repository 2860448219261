import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "0 0.5rem 0 1rem",
  },
  elementSX: (theme: Theme) => ({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  }),
};
