import { Box, SxProps, Typography } from "@mui/material";
import transport from "ASSETS/non-usage/programms/transport.png";
import { SecretProgram } from "ASSETS/svg";
import React from "react";
import ProgressDoughnutChart from "SRC/components/Charts/ProgressDoughnut/ProgressDoughnutChart";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";

import { css } from "./RatingTop.styled";

interface IRatingTopProps {
  data: IRatingTopWorstData | IRatingTopBestData;
  withDarkBgr?: boolean | undefined;
}

export const RatingTop = ({ data, withDarkBgr }: IRatingTopProps) => {
  return (
    <>
      <Box sx={css.container as SxProps}>
        <Box sx={css.icon as SxProps}>
          <span>{data.code}</span>
          <img src={data.gp_icon_url} aria-hidden alt="Title Item Image." />
          {data["Секретная"] === "Секретная" && (
            <Box sx={css.secretProgram}>
              <SecretProgram />
            </Box>
          )}
        </Box>
        <Box sx={css.titleContainer as SxProps}>
          <Typography sx={css.title}>{data.gp_name}</Typography>
        </Box>
        <Box sx={css.doughnut}>
          <ProgressDoughnutChart
            total={100}
            value={Math.round(data.sum)}
            chartSmall={true}
            withDarkBgr={withDarkBgr}
          />
        </Box>
      </Box>
    </>
  );
};
