import { Box, CircularProgress, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { Overlay } from "SRC/pages/App.styled";
import { ObjectTab } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/ObjectTab";
import { IObjectDataItem } from "SRC/pages/Program/common/EventCard/EventTabs/ObjectTab/util/interfaces";
import { useListOfObjects } from "SRC/redux/slices/gosprogram/hooks/useListOfObjects";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import { actions as listOfObjectsActions } from "SRC/redux/slices/gosprogram/slices/listOfObjects";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

interface IObjectDataDataItem {
  sum: string | number;
  code: string;
}

interface IObjectItemData {
  NAME: string;
  R_ID: string;
  code: string;
  data: IObjectDataDataItem[];
  Денежный: string;
  "Ед. измерения бюджета": string;
  "Ед. измерения результата": string;
  "Отвественеый исполнитель": string;
  "Тип мероприятия": string;
  "Характеристика результата": string;
}

interface IObject {
  NAME: string;
  R_ID: string;
  code: string;
  data: IObjectItemData[];
  "Дата начала": string;
  "Дата окончания": string;
  "Код ОКС": string;
  "Местонахождение объекта": string;
  Мощность: string;
  SORT_ORDER: string;
}

export const ObjectTabWrapper = () => {
  const theme = useTheme();
  const { selectedEventId } = useProgramFilters();
  const { period }: IFilters = useFilters();
  const dispatch = useDispatch();

  const {
    items: listOfObjects,
    fetched,
    fetching,
    error,
    load,
  } = useListOfObjects();

  useEffect(() => {
    if (!fetching && !fetched && !error && selectedEventId) {
      load({
        id: selectedEventId,
        period: period.filterValue,
      });
    }
  }, [fetching, fetched, error, selectedEventId]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        listOfObjectsActions.get({
          id: selectedEventId,
          period: period.filterValue,
        })
      );
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch, selectedEventId, period]);

  const { data: data1 } = listOfObjects[0] || {};

  const [listOfObjectsData, setListOfObjectsData] = useState<any>([]);

  useEffect(() => {
    if (data1 && data1.length > 0) {
      const { data: data2 } = data1[0] || {};
      setListOfObjectsData(data2);
    }
  }, [listOfObjects, data1, selectedEventId]);

  const data = useMemo<IObjectDataItem[]>(
    () =>
      listOfObjectsData
        ?.map((item: IObject) => ({
          id: item["Код ОКС"],
          nameObject: item.NAME,
          nameRegion: item["Местонахождение объекта"],
          countObjects: item.Мощность,
          ready: roundNumbersToFixed(
            Number(
              (Number(item.data[0].data.find((it) => it.code === "1404")?.sum) /
                Number(
                  item.data[0].data.find((it) => it.code === "1403")?.sum
                )) *
                100 || 0
            ),
            0
          ),
          datePlan: [item["Дата начала"], item["Дата окончания"]],
          hasPhoto: Boolean(
            item.data[0].data.find((it) => it.code === "1455")?.sum
          ),
          hasVideo: Boolean(
            item.data[0].data.find((it) => it.code === "1456")?.sum
          ),
          SORT_ORDER: Number(item.SORT_ORDER),
        }))
        .sort(
          (a: IObjectDataItem, b: IObjectDataItem) =>
            Number(a.SORT_ORDER) - Number(b.SORT_ORDER)
        ),
    [listOfObjects, listOfObjectsData, selectedEventId]
  );

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        padding: "0.5rem 2.5rem",
      }}
    >
      {fetching ? (
        <Box sx={{ width: "100%", height: "33rem" }}>
          <Overlay>
            <CircularProgress />
          </Overlay>
        </Box>
      ) : (
        <ObjectTab data={data} />
      )}
    </Box>
  );
};
