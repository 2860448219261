export const css = {
  boxContainer: {
    position: "relative",
    padding: "1rem 20px",
  },
  boxIcon: {
    display: "flex",
    flexDirection: "column",
  },
  buttonStyle: {
    width: "2.25rem",
    height: "2.25rem",
    right: "1rem",
    "&:hover, &:focus": {
      background: "none !important",
    },
    "&:hover svg path:nth-of-type(2)": {
      fill: "#2B3A69",
      transition: "0.2s linear",
    },
    "&:focus svg path:nth-of-type(2)": {
      fill: "#4A79D8",
      transition: "0.2s linear",
    },
    "&:nth-of-type(1)": {
      marginBottom: "0.5rem",
    },
  },
  nameRegion: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  countObjects: {
    display: "flex",
    justifyContent: "center",
  },
  datePlanContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dates: {
    display: "flex",
    flexDirection: "column",
  },
  tooltipTitle: {
    fontWeight: 700,
    fontSize: "1rem",
  },
};
