import { actions as headerPrograms } from "../main/slices/headerPrograms";
import { actions as cashExecution } from "./slices/cashExecution";
import { actions as controlPoints } from "./slices/controlPoints";
import { actions as goalConnect } from "./slices/goalConnect";
import { actions as listOfObjects } from "./slices/listOfObjects";
import { actions as modalSideBar } from "./slices/modalSideBar";
import { actions as objectPhotos } from "./slices/objectPhotos";
import { actions as objectVideos } from "./slices/objectVideos";
import { actions as programEvents } from "./slices/programEvents";
import { actions as programGoals } from "./slices/programGoals";
import { actions as resultCharacteristic } from "./slices/resultCharacteristic";
import { actions as statistics } from "./slices/statistics";
import { actions as structureElements } from "./slices/structureElements";
import { actions as subjects } from "./slices/subjects";
import { actions as tableType } from "./slices/tableType";

export interface IGosprogramActions {
  programGoals: typeof programGoals;
  programEvents: typeof programEvents;
  statistics: typeof statistics;
  structureElements: typeof structureElements;
  tableType: typeof tableType;
  controlPoints: typeof controlPoints;
  cashExecution: typeof cashExecution;
  subjects: typeof subjects;
  listOfObjects: typeof listOfObjects;
  resultCharacteristic: typeof resultCharacteristic;
  modalSideBar: typeof modalSideBar;
  objectPhotos: typeof objectPhotos;
  objectVideos: typeof objectVideos;
  goalConnect: typeof goalConnect;
  headerPrograms: typeof headerPrograms;
}

const actions: IGosprogramActions = {
  programGoals,
  programEvents,
  statistics,
  structureElements,
  tableType,
  controlPoints,
  cashExecution,
  subjects,
  listOfObjects,
  resultCharacteristic,
  modalSideBar,
  objectPhotos,
  objectVideos,
  goalConnect,
  headerPrograms,
};

export default actions;
