import { Box, styled, Typography } from "@mui/material";

export const tableSearchStyled = {
  filledInput: {
    fontSize: "1.2rem",
    "::before, ::after": {
      borderBottom: "none!important",
    },
  },
};
export const SearchContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "1.5rem",
  background: `linear-gradient(180deg, ${theme.palette.background.paperDarkBlue} 0%, ${theme.palette.background.paperDarkGray} 100%)`,
  borderRadius: "1rem",

  [theme.breakpoints.up("lg")]: {
    padding: "1rem",
  },
}));

export const NumOfResultsContainer = styled(Box)(({ theme }) => ({
  paddingTop: "1.5rem",
  display: "flex",
  flexDirection: "row",
}));

export const NumOfResultsText = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  lineHeight: "2.5rem",
  fontWeight: "bold",
  color: theme.palette.primary.contrastText,
}));

export const NumOfResultsLabel = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.1rem",
  fontWeight: "normal",
  color: theme.palette.main.gray,
  marginLeft: "2rem",

  [theme.breakpoints.up("lg")]: {
    marginLeft: "1rem",
  },
}));
