import { Theme, Typography } from "@mui/material";
import warningTriangle from "ASSETS/svg/warningTriangle.svg";
import React from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  WarningIconContainer,
  WarningIconImage,
} from "SRC/components/Table/assets/icons";
import {
  css,
  css as tableCss,
  TableCellBigText,
  TableImage,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
}

const getMinistriesColumns = (theme: Theme) => {
  return [
    {
      Header: "Министерство",
      accessor: "ministryName",
      Cell: (tableProps: CustomTableProps) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            paddingLeft: "2rem",
          }}
        >
          {tableProps.row.original.atRisk > 0 && (
            <WarningIconContainer>
              <WarningIconImage src={warningTriangle} alt="danger" />
            </WarningIconContainer>
          )}
          {/* <TableImage
              style={{ borderRadius: "50%" }}
              src={tableProps.row.original.vicePremierPhoto}
              alt="ministry"
            /> */}
          <Typography
            sx={css.tableCellText}
            className="with-right-border withMarginLeft whiteText"
          >
            {tableProps.row.original.ministryName}
          </Typography>
        </div>
      ),
    },
    {
      Header: "Госпрограммы",
      accessor: "numOfPrograms",
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={css.tableCellText}
          className="with-right-border grayText"
          align="center"
        >
          {tableProps.row.original.numOfPrograms}
        </Typography>
      ),
    },
    {
      Header: "Мероприятия",
      columns: [
        {
          Header: "План",
          accessor: "plan",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={{ color: theme.palette.main.gray }}
            >
              {tableProps.row.original.plan}
            </TableCellBigText>
          ),
        },
        {
          Header: "Факт",
          accessor: "fact",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={{ color: theme.palette.main.gray }}
            >
              {tableProps.row.original.fact}
            </TableCellBigText>
          ),
        },
        {
          Header: "Под риском",
          accessor: "atRisk",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={tableCss.tableCellText(
                theme,
                "",
                false,
                tableProps.row.original.atRisk
              )}
              className="bigText with-right-border atRisk"
            >
              {tableProps.row.original.atRisk}
            </Typography>
          ),
        },
      ],
    },
    {
      Header: "Уровень достижения",
      accessor: "achievementLevel",
      Cell: (tableProps: CustomTableProps) => (
        <div style={{ padding: "20px 30px" }}>
          <TableCellBigText
            align="center"
            sx={{ color: theme.palette.main.gray, mb: 2 }}
          >
            {`${roundNumbersToFixed(
              tableProps.row?.original.achievementLevel,
              0
            )}%`}
          </TableCellBigText>
          <ProgressBar value={tableProps.row?.original.achievementLevel} />
        </div>
      ),
    },
  ];
};

export { getMinistriesColumns };
