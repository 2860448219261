import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from "@mui/material";
import { SmallWhiteArrowDown } from "ASSETS/svg/arrow";
import { ReactComponent as CloseButton } from "ASSETS/svg/close/close.svg";
import { CSSProperties, Dispatch, SetStateAction, useMemo } from "react";

import { css } from "./Select.styled";

type TOptionValue = string | number | undefined;

export interface IOption {
  value: TOptionValue;
  label: string | number | undefined;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface ISelect {
  value?: string;
  onChange:
    | ((value: TOptionValue) => void)
    | Dispatch<SetStateAction<string>>
    | ((value: string) => void);
  options: IOption[];
  hasAllOption?: boolean;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style?: IStyle;
}

const BaseSelect = ({
  value,
  onChange,
  options,
  staticLabel = true,
  label,
  hasAllOption = true,
  colorizeActiveOption = true,
  style = {} as IStyle,
}: ISelect) => {
  const optionsJSX: JSX.Element[] = useMemo(
    () =>
      options.map((item: IOption) => (
        <MenuItem
          value={item.value}
          disabled={item.disabled}
          sx={style.optionStyle}
          key={item.value}
        >
          {item.label}
        </MenuItem>
      )),
    [options, style.optionStyle]
  );

  const handleSelectProgram = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  const handleCloseFilter = () => {
    if (value !== "") onChange("");
  };

  return (
    <FormControl variant="standard" sx={css.element}>
      {label && staticLabel ? (
        <Box sx={{ ...css.label, ...style.labelStyle }}>{label}</Box>
      ) : (
        <InputLabel>{label}</InputLabel>
      )}
      <Select
        value={value}
        onChange={handleSelectProgram}
        label="Вице-премьеры"
        sx={
          css.select(value, colorizeActiveOption, style.selectStyle) as SxProps
        }
        displayEmpty={hasAllOption}
        IconComponent={() => (
          <Box sx={css.iconComponent as SxProps}>
            {value !== "" ? (
              <Box onClick={handleCloseFilter}>
                <CloseButton style={css.closeButton as CSSProperties} />
              </Box>
            ) : null}
            <Box sx={{ display: "flex" }}>
              <SmallWhiteArrowDown style={css.arrowButton as CSSProperties} />
            </Box>
          </Box>
        )}
      >
        {hasAllOption && (
          <MenuItem
            value=""
            key=""
            sx={{ ...css.allItem, ...style.optionStyle } as SxProps}
          >
            Все
          </MenuItem>
        )}
        {optionsJSX}
      </Select>
    </FormControl>
  );
};

export default BaseSelect;
