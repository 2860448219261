import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SmallArrowLeft } from "ASSETS/svg/arrow";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { TableModal } from "SRC/components/FullPageTableModal/FullPageTableModal";
import { TableActionsButtons } from "SRC/components/TableActionsButtons/TableActionsButtons";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { EventCardWrapper } from "SRC/pages/Program/common/EventCardWrapper/EventCardWrapper";
import { EventsTable } from "SRC/pages/Program/common/EventsSection/EventsTable/EventsTable";
import { getEventColumns } from "SRC/pages/Program/common/EventsSection/util/columns";
import { IEventItem } from "SRC/pages/Program/common/EventsSection/util/interfaces";
import { IStatus } from "SRC/pages/Program/common/ProgramSummary/interfaces";
import { StructureCard } from "SRC/pages/Program/common/StructureCard/StructureCard";
import { useStructureElements } from "SRC/redux/slices/gosprogram/hooks/useStructureElements";

import { css } from "./EventsSection.styled";

interface IEventsSectionProps {
  items: IEventItem[];
  openTableModal?: boolean;
  setTableModalOpen?: (value: boolean) => void;
  withoutButton?: boolean;
  setOpenEventCard?: Dispatch<SetStateAction<boolean>>;
  statuses: IStatus[];
  downloadData?: IEventItem[];
  infoRows?: Record<string, string>[][];
}

export const EventsSection = ({
  items,
  openTableModal,
  setTableModalOpen,
  withoutButton,
  setOpenEventCard,
  statuses,
  downloadData,
  infoRows,
}: IEventsSectionProps) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const { items: structures } = useStructureElements();

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  const { headings, mergeCells, wscols } = useTableExportParams(
    downloadData,
    "programEvents"
  );

  const eventsTableColumns = useMemo(() => getEventColumns(theme), [theme]);

  const [eventModalMode, setEventModalMode] = useState<
    "default" | "eventCard" | "eventsFilter"
  >("default");

  const RenderTable = () => {
    return (
      <>
        {openTableModal && (
          <TableActionsButtons
            open={openTableModal}
            setOpen={setTableModalOpen}
            downloadData={downloadData || items}
            fileName="Мероприятия"
            wscols={wscols}
            headings={headings}
            mergeCells={mergeCells}
            infoRows={infoRows}
          />
        )}
        <EventsTable
          columns={eventsTableColumns}
          data={items}
          isFilterModalOpen={isFilterModalOpen}
          setIsFilterModalOpen={setIsFilterModalOpen}
          openTableModal={openTableModal}
          eventModalMode={eventModalMode}
          setEventModalMode={setEventModalMode}
          withoutButton={withoutButton}
          setOpenEventCard={setOpenEventCard}
          statuses={statuses}
        />
      </>
    );
  };

  return (
    <>
      <RenderTable />
      {!match && (
        <TableModal
          open={openTableModal}
          setOpen={setTableModalOpen}
          setEventModalMode={setEventModalMode}
          tableName={eventModalMode === "default" ? "Мероприятия" : ""}
          backButton={
            eventModalMode !== "default" && (
              <Box
                sx={css.backContainer}
                onClick={() => setEventModalMode("default")}
              >
                <SmallArrowLeft style={css.arrow} />
                <Typography sx={css.backText as SxProps}>назад</Typography>
              </Box>
            )
          }
        >
          {eventModalMode === "eventCard" ? (
            <EventCardWrapper
              setEventModalMode={setEventModalMode}
              eventModalMode={eventModalMode}
            />
          ) : eventModalMode === "eventsFilter" ? (
            <StructureCard
              structures={structures}
              openTableModal={openTableModal}
              setEventModalMode={setEventModalMode}
              statuses={statuses}
            />
          ) : (
            <RenderTable />
          )}
        </TableModal>
      )}
    </>
  );
};
