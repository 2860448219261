import { Box, SxProps, Theme, Typography } from "@mui/material";
import { SecretProgram, WarningTriangle } from "ASSETS/svg";
import React, { CSSProperties } from "react";
import { TableProps, UseTableRowProps } from "react-table";
import { Percent } from "SRC/components/elements/Percent/Percent";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import {
  css as tableCss,
  TableCellBigText,
  TableFooterText,
} from "SRC/components/Table/BaseTable/BaseTable.styled";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";

interface CustomTableProps extends TableProps {
  row: UseTableRowProps<any>;
  rows?: UseTableRowProps<any>;
}

const getGeneralInfoColumns = (theme: Theme, isLgWidth: boolean) => {
  const data = [
    {
      Header: "№",
      accessor: "id",
      width: css.idColumn.width,
      Cell: (tableProps: CustomTableProps) => {
        return (
          <>
            <Box sx={css.idCell}>
              <Box sx={css.iconContainer}>
                {tableProps.row.original.secretProgram === 1 && (
                  <Box sx={css.secretProgram}>
                    <SecretProgram />
                  </Box>
                )}
                {tableProps.row.original.atRisk > 0 && (
                  <Box>
                    <WarningTriangle
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Typography
                sx={tableCss.tableCellText}
                align="center"
                className="grayText"
              >
                {tableProps.row.original.id}
              </Typography>
            </Box>
          </>
        );
      },
      Footer: " ",
    },
    {
      Header: " ",
      accessor: "icon",
      width: css.iconColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.iconColumn.cellWrapper as CSSProperties}>
          <img
            style={tableCss.tableImage(true)}
            src={tableProps.row.original.icon}
            alt="govProgram"
          />
        </div>
      ),
      Footer: " ",
    },
    {
      Header: <Box sx={css.nameColumn.headerWrapper}>Госпрограмма</Box>,
      accessor: "govProgram",
      width: css.nameColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <Typography
          sx={tableCss.tableCellText}
          className="cellWrapper whiteText"
        >
          {tableProps.row.original.govProgram}
        </Typography>
      ),
      Footer: (
        <TableFooterText align="left" sx={css.nameColumn.footerWrapper(theme)}>
          Итого
        </TableFooterText>
      ),
    },
    {
      Header: "Вице-премьер",
      accessor: "vicePremier",
      Cell: (tableProps: CustomTableProps) => (
        <TableCellBigText sx={css.vicePremierColumn.cellText(theme) as SxProps}>
          {tableProps.row.original.vicePremier}
        </TableCellBigText>
      ),
      Footer: " ",
    },
    {
      Header: "Министерства",
      accessor: "foiv",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.cashColumn.fact.cellWrapper as CSSProperties}>
          <TableCellBigText sx={css.foivColumn.cellText(theme) as SxProps}>
            {tableProps.row.original.foiv}
          </TableCellBigText>
        </div>
      ),
      Footer: " ",
    },
    {
      Header: "Уровень достижения",
      accessor: "performance",
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.performanceColumn.cellWrapper}>
          <TableCellBigText
            sx={css.performanceColumn.text(
              theme,
              tableProps.row.original.performance
            )}
          >
            {roundNumbersToFixed(tableProps.row.original.performance, 2)}
            <Percent theme={theme} />
          </TableCellBigText>
          <ProgressBar value={tableProps.row.original.performance || 0} />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.performance + sum,
            0
          );
          return roundNumbersToFixed(total / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.govProgramsColumn.footerWrapper}>
            <TableFooterText
              sx={css.performanceColumn.footerText(theme, average)}
            >
              {`${average}`}
              <Percent theme={theme} />
            </TableFooterText>
            <ProgressBar value={average} />
          </div>
        );
      },
    },
    {
      Header: "Кассовое исполнение",
      accessor: "cash",
      columns: [
        {
          Header: "План",
          width: css.cashColumn.plan.width,
          accessor: "cashPlan",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={css.cashColumn.plan.cellWrapper(
                theme,
                tableProps.row.original.cashPlan
              )}
            >
              {tableProps.row.original.cashPlan}
              <TableCellBigText sx={css.nameColumn.cellWrapper(theme)}>
                млрд. руб.
              </TableCellBigText>
            </TableCellBigText>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              const avarenge = rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.cashPlan + sum,
                0
              );

              return roundNumbersToFixed(avarenge / rowsArray.length, 0) || 0;
            }, [tableProps.rows]);

            return (
              <TableFooterText
                sx={css.cashColumn.plan.footerWrapper(theme, total)}
              >
                {total}
                <Percent theme={theme} />
              </TableFooterText>
            );
          },
        },
        {
          Header: "Факт",
          width: css.cashColumn.fact.width,
          accessor: "cashFact",
          Cell: (tableProps: CustomTableProps) => (
            <div style={css.cashColumn.fact.cellWrapper as CSSProperties}>
              <Typography
                sx={tableCss.tableCellText(
                  theme,
                  tableProps.row.original.statusLabel
                )}
                className="bigText with-right-border cashColumn-fact-cellText"
                align="center"
              >
                {tableProps.row.original.cashFact}
                <Percent theme={theme} />
              </Typography>
              <ProgressBar value={tableProps.row.original.cashFact || 0} />
            </div>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              const avarenge = rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.cashFact + sum,
                0
              );

              return roundNumbersToFixed(avarenge / rowsArray.length, 0) || 0;
            }, [tableProps.rows]);

            return (
              <div style={css.cashColumn.fact.footerWrapper}>
                <Typography
                  sx={tableCss.tableCellText(theme)}
                  align="center"
                  className="with-right-border whiteText tableFooterText"
                >
                  {total}
                  <Percent theme={theme} />
                </Typography>
                <ProgressBar value={total} />
              </div>
            );
          },
        },
      ],
      Footer: " ",
    },
    {
      Header: "Цели",
      accessor: "goals",
      columns: [
        {
          Header: "План",
          width: css.goalsColumn.plan.width,
          accessor: "goalsPlan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={tableCss.tableCellText(theme)}
              className="bigText grayText"
            >
              {tableProps.row.original.goalsPlan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.goalsPlan + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <TableFooterText sx={css.goalsColumn.plan.footerWrapper(theme)}>
                {total}
              </TableFooterText>
            );
          },
        },
        {
          Header: "Выполнено",
          width: css.goalsColumn.done.width,
          accessor: "goalsDone",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={css.goalsColumn.done.cellWrapper(theme)}
            >
              {tableProps.row.original.goalsDone}
            </TableCellBigText>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.goalsDone + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <TableFooterText
                align="center"
                sx={css.goalsColumn.done.footerWrapper(theme)}
              >
                {total}
              </TableFooterText>
            );
          },
        },
        {
          Header: "Не выполнено",
          width: css.goalsColumn.fail.width,
          accessor: "goalsFail",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={tableCss.tableCellText(theme)}
              className="bigText with-right-border redText"
            >
              {tableProps.row.original.goalsFail}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.goalsFail + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <Typography
                align="center"
                sx={tableCss.tableCellText(theme)}
                className="with-right-border redText"
              >
                {total}
              </Typography>
            );
          },
        },
      ],
      Footer: " ",
    },
    {
      Header: "Показатели",
      accessor: "indicators",
      columns: [
        {
          Header: "План",
          width: css.eventsIndicatorsColumn.width,
          accessor: "indicatorsPlan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={tableCss.tableCellText(theme)}
              className="bigText grayText"
            >
              {tableProps.row.original.indicatorsPlan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.indicatorsPlan + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <TableFooterText sx={css.goalsColumn.plan.footerWrapper(theme)}>
                {total}
              </TableFooterText>
            );
          },
        },
        {
          Header: "Выполнено",
          width: css.eventsIndicatorsColumn.width,
          accessor: "indicatorsDone",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={css.goalsColumn.done.cellWrapper(theme)}
            >
              {tableProps.row.original.indicatorsDone}
            </TableCellBigText>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.indicatorsDone + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <TableFooterText
                align="center"
                sx={css.goalsColumn.done.footerWrapper(theme)}
              >
                {total}
              </TableFooterText>
            );
          },
        },
        {
          Header: "Не выполнено",
          width: css.eventsIndicatorsColumn.width,
          accessor: "indicatorsFail",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText(theme)}
              className="bigText with-right-border redText"
              align="center"
            >
              {tableProps.row.original.indicatorsFail}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.indicatorsFail + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <Typography
                sx={tableCss.tableCellText(theme)}
                className="tableFooterText with-right-border redText"
                align="center"
              >
                {total}
              </Typography>
            );
          },
        },
      ],
      Footer: " ",
    },
    {
      Header: "Мероприятия",
      accessor: "events",
      columns: [
        {
          Header: "План",
          width: css.eventsGoalsColumn.width,
          accessor: "eventsPlan",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              align="center"
              sx={tableCss.tableCellText(theme)}
              className="bigText grayText"
            >
              {tableProps.row.original.eventsPlan}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.eventsPlan + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <TableFooterText sx={css.goalsColumn.plan.footerWrapper(theme)}>
                {total}
              </TableFooterText>
            );
          },
        },
        {
          Header: "Выполнено",
          width: css.eventsGoalsColumn.width,
          accessor: "eventsDone",
          Cell: (tableProps: CustomTableProps) => (
            <TableCellBigText
              align="center"
              sx={css.goalsColumn.done.cellWrapper(theme)}
            >
              {tableProps.row.original.eventsDone}
            </TableCellBigText>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.eventsDone + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <TableFooterText
                align="center"
                sx={css.goalsColumn.done.footerWrapper(theme)}
              >
                {total}
              </TableFooterText>
            );
          },
        },
        {
          Header: "Не выполнено",
          width: css.eventsGoalsColumn.width,
          accessor: "eventsFail",
          Cell: (tableProps: CustomTableProps) => (
            <Typography
              sx={tableCss.tableCellText(theme)}
              className="bigText with-right-border redText"
              align="center"
            >
              {tableProps.row.original.eventsFail}
            </Typography>
          ),
          Footer: (tableProps: CustomTableProps) => {
            const total = React.useMemo(() => {
              const rowsArray = Array(tableProps.rows || []).flat();
              return rowsArray.reduce(
                (sum: number, row: UseTableRowProps<any>) =>
                  row.original.eventsFail + sum,
                0
              );
            }, [tableProps.rows]);

            return (
              <Typography
                sx={tableCss.tableCellText(theme)}
                className="tableFooterText with-right-border redText"
                align="center"
              >
                {total}
              </Typography>
            );
          },
        },
      ],
      Footer: " ",
    },
    {
      Header: "Удовлетворенность населения",
      accessor: "populationSatisfaction",
      width: css.populateColumn.width,
      Cell: (tableProps: CustomTableProps) => (
        <div style={css.populationSatisfactionColumn.cellWrapper}>
          <TableCellBigText
            sx={css.populationSatisfactionColumn.cellText(
              theme,
              tableProps.row.original.populationSatisfaction
            )}
          >
            {`${tableProps.row.original.populationSatisfaction}`}
            <Percent theme={theme} />
          </TableCellBigText>
          <ProgressBar
            value={tableProps.row.original.populationSatisfaction || 0}
          />
        </div>
      ),
      Footer: (tableProps: CustomTableProps) => {
        const average = React.useMemo(() => {
          const rowsArray = Array(tableProps.rows || []).flat();
          const total = rowsArray.reduce(
            (sum: number, row: UseTableRowProps<any>) =>
              row.original.populationSatisfaction + sum,
            0
          );

          return roundNumbersToFixed(total / rowsArray.length, 0) || 0;
        }, [tableProps.rows]);

        return (
          <div style={css.populationSatisfactionColumn.footerWrapper}>
            <TableFooterText
              sx={css.performanceColumn.footerText(theme, average)}
            >
              {`${average}`}
              <Percent theme={theme} />
            </TableFooterText>
            <ProgressBar value={average} />
          </div>
        );
      },
    },
  ];

  return data;
};

export { getGeneralInfoColumns };
