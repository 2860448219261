import { Theme } from "@mui/material";

export const css = {
  container: {
    display: "flex",
    gap: "1rem",
  },
  generalEffect: {
    display: "flex",
    maxWidth: "16rem",
    flexDirection: "column",
    background: "#2E364A",
    padding: "1rem 1.5rem 2.5rem",
    borderRadius: "1rem",
    gap: "1rem",
    flex: "1 0 15%",
    alignItems: "center",
  },
  chart: {
    background: (theme: Theme) => theme.palette.charts.darkBlueBackground,
    borderRadius: "50%",
    display: "flex",
    height: "11.25rem",
    width: "11.25rem",
    padding: "0.3rem",
  },
  title: {
    fontWeight: 400,
    fontSize: "1.25rem",
  },
  num: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  effects: {
    display: "flex",
  },
  effectsContainer: {
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
    flex: 1,
  },
  effectItem: {
    display: "flex",
    background: (theme: Theme) => theme.palette.background.paper,
    flexDirection: "column",
    borderRadius: "1rem",
    padding: "1rem 1.5rem",
    justifyContent: "space-between",
    width: "calc(100% * (1/4) - 1rem)",
    flexGrow: "1",
  },
  itemTop: {
    display: "flex",
    flexDirection: "row",
    gap: "0.25rem",
  },
  itemBottom: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  barContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  barTitle: {
    fontSize: "1rem",
    fontWeight: 300,
  },
  barUnit: {
    fontWeight: 700,
    fontSize: "1.25rem",
  },
};
