import { Box, styled, Theme, Typography } from "@mui/material";
import { CSSProperties } from "@mui/styles";

interface IProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  sxContainer?: CSSProperties;
}

export const CardContainer = styled(Box)<IProps>(
  ({ isSelected, isDisabled, theme }: IProps & { theme: Theme }) => ({
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minHeight: "9.5rem",
    padding: "1.5rem 1.25rem",
    alignItems: "center",
    borderRadius: "1rem",
    border: `1px solid ${theme.palette.complementary.darkBlue}`,
    cursor: isDisabled ? "default" : "pointer",
    background: isSelected
      ? theme.palette.complementary.darkBlue
      : theme.palette.main.darkBlue,
  })
);

export const StatusIcon = styled("div")(({ theme }) => ({
  width: "1.5rem",
  height: "1.5rem",
  position: "absolute",
  top: "-0.75rem",

  "& svg": {
    width: "100%",
    height: "100%",
  },
}));

export const StatusText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1rem",
  fontWeight: "normal",
  color: theme.palette.main.gray,
  textAlign: "center",
}));

export const StatusAmount = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "bold",
  color: theme.palette.primary.contrastText,
}));
