import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  PaperProps,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { SmallWhiteArrowDown } from "ASSETS/svg/arrow";
import { ReactComponent as CloseButton } from "ASSETS/svg/close/close.svg";
import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useState,
} from "react";

import { css } from "./SelectWithSearch.styled";

type TOptionValue = string | number;

export interface IOption {
  value: TOptionValue;
  label: string;
  disabled?: boolean;
}

interface IStyle {
  labelStyle?: SxProps;
  selectStyle?: SxProps;
  optionStyle?: SxProps;
}

interface ISelect {
  value?: string;
  onChange(value: TOptionValue): void;
  customOptions: IOption[];
  selectValue: IOption;
  setSelectValue?: Dispatch<SetStateAction<IOption>>;
  label?: string;
  staticLabel?: boolean;
  colorizeActiveOption?: boolean;
  style?: IStyle;
  getOptionLabel: any;
  hasCloseIcon: boolean;
}

const SelectWithSearch = ({
  value,
  onChange,
  customOptions,
  selectValue,
  setSelectValue,
  staticLabel = true,
  label,
  colorizeActiveOption = true,
  style = {} as IStyle,
  getOptionLabel,
  hasCloseIcon,
}: ISelect) => {
  const [inputValue, setInputValue] = useState("");

  const handleSelectProgram = (
    event: SyntheticEvent<Element, Event>,
    value: IOption | null
  ) => {
    if (value) {
      setSelectValue?.(value);
      onChange(value.value);
    }
  };

  const handleInputChange = (
    event: SyntheticEvent,
    newInputValue: string,
    reason: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleCloseFilter = () => {
    if (value !== "") onChange("");
  };

  const CustomPaper = (props: PaperProps) => {
    return <Paper {...props} sx={css.paperRoot} />;
  };

  return (
    <FormControl
      variant="standard"
      sx={css.element(value, colorizeActiveOption, style.selectStyle)}
    >
      {label && staticLabel ? (
        <Box sx={{ ...css.label, ...style.labelStyle }}>{label}</Box>
      ) : (
        <InputLabel>{label}</InputLabel>
      )}

      <Autocomplete
        options={customOptions}
        value={selectValue}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onChange={handleSelectProgram}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        autoComplete
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) =>
          option.label === label ? (
            <Box sx={css.allItemContainer} key="0">
              <MenuItem
                {...props}
                value=""
                key=""
                sx={
                  {
                    ...css.allItem(value === ""),
                    ...style.optionStyle,
                  } as SxProps
                }
              >
                Все
              </MenuItem>
            </Box>
          ) : (
            <MenuItem
              {...props}
              value={option.value}
              sx={
                {
                  ...css.item(value === option.value),
                  ...style.optionStyle,
                } as SxProps
              }
              key={option.value}
            >
              {option.label}
            </MenuItem>
          )
        }
        popupIcon={
          <SmallWhiteArrowDown style={css.arrowButton as CSSProperties} />
        }
        clearIcon={
          hasCloseIcon && (
            <Box onClick={handleCloseFilter}>
              <CloseButton style={css.closeButton as CSSProperties} />
            </Box>
          )
        }
        PaperComponent={CustomPaper}
        noOptionsText={
          <Typography sx={css.noOptionsText}>
            По вашему запросу ничего не найдено
          </Typography>
        }
      />
    </FormControl>
  );
};

export default SelectWithSearch;
