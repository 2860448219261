import AddIcon from "@mui/icons-material/Add";
import { Box, ClickAwayListener, Grid, SxProps } from "@mui/material";
import { useTheme } from "@mui/material";
import { SecretProgram } from "ASSETS/svg";
import ReactECharts from "echarts-for-react";
import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { getDataSumByCodeGP } from "SRC/constants/dataCodes";
import { roundNumbersToFixed } from "SRC/helpers/roundNumbersToFixed";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import themeConfig from "SRC/theme";

import { css } from "./Equalizer.styled";
import { getOption } from "./options";

interface ITooltipProps {
  cords: [number, number];
  data: TProgram;
}

const Tooltip = forwardRef(({ cords, data }: ITooltipProps, ref) => {
  const { calculationMethod }: IFilters = useFilters();

  return (
    <Box sx={css.tooltipContainer(cords) as SxProps} ref={ref}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        sx={css.tooltipHeader}
      >
        <Grid item sx={css.tooltipHeaderLeft}>
          <Box> {data.gp_code}</Box>
          {data?.["Секретная"] === "1" && (
            <Box sx={css.secretProgram}>
              <SecretProgram />
            </Box>
          )}
        </Grid>
        <Grid
          item
          sx={css.tooltipHeaderRight(
            calculationMethod === "effect"
              ? getDataSumByCodeGP(data, 1753) || 0
              : getDataSumByCodeGP(data, 1796) || 0
          )}
        >
          {roundNumbersToFixed(
            calculationMethod === "effect"
              ? getDataSumByCodeGP(data, 1753) || 0
              : getDataSumByCodeGP(data, 1796) || 0,
            0
          )}{" "}
          <Box component="span" sx={css.tooltipHeaderRightPercent}>
            %
          </Box>
        </Grid>
      </Grid>
      <Box sx={css.tooltipText}>{data.gp_name}</Box>
      <Box sx={css.tooltipIconWrapper as SxProps}>
        <img
          src={data.gp_icon_url}
          alt={data.gp_name}
          style={css.tooltipImg as CSSProperties}
        />
      </Box>
      <Box sx={css.tooltipBtn as SxProps}>
        <Link to={`program/${data.gp_code}`}>
          <AddIcon sx={css.tooltipBtnIcon} />
        </Link>
      </Box>
    </Box>
  );
});

const Equalizer = () => {
  const theme = useTheme<typeof themeConfig>();
  const { items } = usePrograms();
  const {
    program,
    filteredPrograms,
    setSelectedProgram,
    setSelectedGoal,
    setSelectedVP,
    tooltipCoordinates,
    setSelectedTooltipCoords,
    calculationMethod,
  }: IFilters = useFilters();
  const [currentProgram, setCurrentProgram] = useState<TProgram>(
    {} as TProgram
  );
  const [codes, values]: [string[], number[]] = useMemo(
    () =>
      items.reduce(
        (acc, item: TProgram) => {
          return [
            [...acc[0], String(item.gp_code)],
            [
              ...acc[1],
              roundNumbersToFixed(
                calculationMethod === "effect"
                  ? getDataSumByCodeGP(item, 1753) || 0
                  : getDataSumByCodeGP(item, 1796) || 0,
                0
              ),
            ],
          ];
        },
        [[], []] as [string[], number[]]
      ),
    [items, calculationMethod]
  );

  const chartEvents = useMemo(
    () => ({
      click: (e: any) => {
        const chosenProgram =
          items.find((item) => item.gp_code === e.name) || ({} as TProgram);

        if (!filteredPrograms.includes(e.name)) {
          setSelectedGoal("");
          setSelectedVP("");
        }
        setCurrentProgram(chosenProgram);
        setSelectedTooltipCoords([
          e.event.offsetX < window.innerWidth / 2
            ? e.event.offsetX
            : e.event.offsetX - 300,
          e.value < 60 ? e.event.offsetY : e.event.offsetY + 120,
        ]);
        setTimeout(() => {
          if (program === String(chosenProgram.gp_code)) {
            return;
          }
          setSelectedProgram(String(chosenProgram.gp_code));
        });
      },
    }),
    [
      items,
      setSelectedProgram,
      setSelectedGoal,
      setSelectedVP,
      filteredPrograms,
      program,
      calculationMethod,
      window.innerWidth,
    ]
  );

  const outClickHandler = () => {
    setSelectedProgram("");
  };

  const options = useMemo(
    () =>
      getOption(theme, codes, values, filteredPrograms as string[], program),
    [theme, codes, values, program, filteredPrograms]
  );

  const chart = useMemo(
    () => (
      <ReactECharts option={options} style={css.graph} onEvents={chartEvents} />
    ),
    [options, chartEvents]
  );

  useEffect(() => {
    if (items) {
      const currentItem = items.filter((el) => el.gp_code === program)[0];
      setCurrentProgram(currentItem);
    }
  }, [items, program, calculationMethod]);

  return (
    <Box sx={css.container}>
      {chart}
      {currentProgram && (
        <ClickAwayListener onClickAway={outClickHandler}>
          <Tooltip cords={tooltipCoordinates} data={currentProgram} />
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default Equalizer;
