import { Theme } from "@mui/material";

export const css = {
  tabs: (theme: Theme) => ({
    "& .MuiTab-root": {
      fontSize: "1.25rem",
      padding: "1rem 0rem",
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTabs-flexContainer": {
      padding: "0 2.5rem",
    },
  }),
  tabPanel: {
    padding: "0 !important",
  },
  container: {
    color: (theme: Theme) => theme.palette.text.onAccent,
    padding: "1rem 2.5rem",

    "& p.gray": {
      fontSize: "1rem",
      lineHeight: "1rem",
      color: (theme: Theme) => theme.palette.main.gray,
    },
    "& p.blue": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      color: (theme: Theme) => theme.palette.main.blue,
      fontWeight: "bold",
    },
    "& p.white": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
      color: (theme: Theme) => theme.palette.text.onAccent,
      fontWeight: "bold",
    },
    "& p.marginTop": {
      marginTop: "0.5rem",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  stepContainer: {
    position: "relative",
    margin: "1rem 0",
    height: "1rem",
  },
  stepPoint: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",

    "&.stepRed": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.stepGreen": {
      background: (theme: Theme) => theme.palette.success.main,
    },
  },
  stepLine: {
    position: "absolute",
    width: "100%",
    height: "0.8rem",
    zIndex: -1,
    background: (theme: Theme) => theme.palette.complementary.lightShadow,
    transform: "translateY(15%)",

    "&.smallLine": {
      width: "1rem",
      borderTopRightRadius: "1rem",
      borderBottomRightRadius: "1rem",
    },
  },
  differenceContainer: {
    display: "flex",
    alignItems: "end",
    paddingBottom: "0.2rem",
  },
  difference: {
    background: (theme: Theme) => theme.palette.background.paperBorderGray,
    width: "fit-content",
    borderRadius: "2px",
    fontSize: "0.7rem",
    padding: "0.15rem 0.1rem 0.1rem 0.1rem",
    lineHeight: "0.7rem",
    fontWeight: "bold",

    "&.greenDifference": {
      color: (theme: Theme) => theme.palette.success.main,
    },
    "&.redDifference": {
      color: (theme: Theme) => theme.palette.error.main,
    },
  },
  legendPoint: {
    width: "1rem",
    height: "1rem",
    borderRadius: "50%",

    "&.stepRed": {
      background: (theme: Theme) => theme.palette.error.main,
    },
    "&.stepGreen": {
      background: (theme: Theme) => theme.palette.success.main,
    },
  },
};
