import { Theme } from "@mui/material";

export const chartCss = {
  gridContainer: (theme: Theme) => ({
    width: "100%",
    padding: "1.5rem 1.5rem 0 1.5rem",
    height: "50%",

    [theme.breakpoints.up("xl")]: {
      marginBottom: "1rem",
    },
  }),
  topLeftContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  gridItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  innerGridItem: (theme: Theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "6.75rem",

    [theme.breakpoints.up("xl")]: {
      maxHeight: "5rem",
    },
  }),
  circle: {
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "50%",
    background: (theme: Theme) => theme.palette.primary.contrastText,
    border: (theme: Theme) => `5px solid ${theme.palette.main.darkBlue}`,
  },
  blueCircle: {
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "50%",
    background: (theme: Theme) => theme.palette.primary.main,
    border: (theme: Theme) => `5px solid ${theme.palette.main.darkBlue}`,
  },
  button: {
    width: "1.5rem",
    height: "1.5rem",
    background: (theme: Theme) => theme.palette.main.darkBlue,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    cursor: "pointer",
  },
  chartStyle: {
    height: "45%",
  },
};
