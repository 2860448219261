import { AxiosBasicCredentials } from "axios";
import { AxiosBasicCredentialsWithRefresh } from "SRC/redux/slices/auth/tokenSlice";

import client from "../../../../core/api/client";

export const AUTH_API_BASEPATH = "api/auth_api/v1.0";

export type Token = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export const getToken = () => {
  const data = new FormData();
  data.append("grant_type", "oam_remote_user");
  data.append("client_id", "planet-analitics");
  data.append("client_secret", "planet-analitics-password");

  return client.post<Token>(`${AUTH_API_BASEPATH}/oauth/token`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const refreshToken = (
  credentials?: AxiosBasicCredentialsWithRefresh
) => {
  const data = new FormData();
  data.append("grant_type", "oam_remote_user");
  data.append("client_id", "planet-analitics");
  data.append("client_secret", "planet-analitics-password");

  return client.post<Token>(
    `${AUTH_API_BASEPATH}/oauth/token${
      credentials
        ? `&username=${credentials.username}&refresh_token=${credentials.refresh_token}`
        : ""
    }`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export interface IUser {
  changePassRequired: boolean;
  email: string | null;
  expireDate: string | null;
  firstName: string | null;
  id: number;
  lastName: string | null;
  login: string;
  middleName: string | null;
  name: string;
}

export const getAuthorized = () =>
  client.get<IUser>(`${AUTH_API_BASEPATH}/user/authorized`);
