import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { MutableRefObject, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { getIndicatorsColumns } from "SRC/pages/Summary/common/DasboardTables/IndicatorsTable/indicatorsData";
import {
  IIndicatorsEventsDataItem,
  IIndicatorsPremiersEventsDataItem,
  ITableDataItem,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { useAppSelector } from "SRC/redux/hooks";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPremiers,
  TPremier,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

import { css } from "../DashboardTables.styled";

interface IIndicatorsTable {
  forwardRef: MutableRefObject<HTMLElement | undefined>;
  onScroll: (scroll: any) => void;
  tableData: ITableDataItem[];
  setSortBy: (sortBy: any) => void;
}

export const IndicatorsTable = ({
  forwardRef,
  onScroll,
  tableData,
  setSortBy,
}: IIndicatorsTable) => {
  const theme = useTheme();
  const { program, setSelectedProgram }: IFilters = useFilters();

  const columns: Column[] = useMemo(() => getIndicatorsColumns(theme), [theme]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy: baseSortBy,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Box sx={css.tablesWrapper as SxProps}>
      <Box>
        <Typography sx={css.title as SxProps}>Показатели</Typography>
      </Box>
      <BaseTable
        columns={columns}
        data={tableData}
        sortedColumns={["План", "Выполнено", "Не выполнено"]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={(sortBy) => {
          setSortBy(sortBy);
          baseSortBy(sortBy);
        }}
        tableHeight={css.tableHeight}
        selectedRow={program}
        setSelectedRow={setSelectedProgram}
        scrollHide={true}
        forwardRef={forwardRef}
        onScroll={onScroll}
      />
    </Box>
  );
};
