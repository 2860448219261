import { Box, useMediaQuery, useTheme } from "@mui/material";
import { MutableRefObject, useMemo } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { BaseTable } from "SRC/components/Table";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import {
  IGeneralInfoPremiersDataItem,
  ITableDataItem,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPremiers,
  TPremier,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";

import { getGeneralInfoPremiersColumns } from "./tableData";

interface IGeneralInfoPremiersTab {
  posTable?: string;
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  setSortBy: (sortBy: any) => void;
  tableData: ITableDataItem[];
}

export const GeneralInfoPremiersTab = ({
  posTable,
  forwardRef,
  onScroll,
  setSortBy,
  tableData,
}: IGeneralInfoPremiersTab) => {
  const theme = useTheme();
  const { vp, setSelectedVP }: IFilters = useFilters();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const columns: Column[] = useMemo(
    () => getGeneralInfoPremiersColumns(theme),
    [theme]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setSortBy: baseSortBy,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Box sx={css.tablesWrapper(false)}>
      <BaseTable
        columns={columns}
        data={tableData}
        sortedColumns={[
          "Кол-во госпрограмм",
          "Уровень достижения",
          "Удовлетворенность населения",
        ]}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        prepareRow={prepareRow}
        setSortBy={(sortBy) => {
          setSortBy(sortBy);
          baseSortBy(sortBy);
        }}
        tableHeight={matches ? css.tableHeightLong : css.tableHeight}
        selectedRow={vp}
        setSelectedRow={setSelectedVP}
        withBigRows={!matches}
        wrapSort={matches}
        positionTable={posTable}
        forwardRef={forwardRef}
        onScroll={onScroll}
        scrollX={false}
      />
    </Box>
  );
};
