import "react-datepicker/dist/react-datepicker.css";

import { Box, SxProps, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CalendarIcon } from "ASSETS/svg";
import {
  BlueArrowLeft,
  BlueArrowRight,
  SmallWhiteArrowDown,
  SmallWhiteArrowUp,
} from "ASSETS/svg/arrow";
import ruLocale from "date-fns/locale/ru";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";

import { css } from "./Calendar.styled";

interface ICalendarContainerProps {
  className?: string | undefined;
  children?: React.ReactNode | undefined;
}

interface IDatePickerCustomHeaderProps {
  date: Date;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled?: boolean;
  nextYearButtonDisabled?: boolean;
}

const YEAR = "year";
const MONTH = "month";
const QUARTER = "quarter";

export const Calendar = (): JSX.Element => {
  const { period, setSelectedPeriod }: IFilters = useFilters();

  const [value, setValue] = useState<Date | null>(period.value);
  const [calendarValue, setCalendarValue] = useState({
    year: `${moment(period.value).year()}`,
    quarter: `Q${moment(period.value).format("Q")}`,
    month: moment(period.value).format("MM"),
  });

  const [calendarView, setCalendarView] = useState<string>(YEAR);

  const [isOpen, setOpen] = useState<boolean>(false);

  const filterValue =
    calendarValue.year && !calendarValue.quarter && !calendarValue.month
      ? `${calendarValue.year}00`
      : calendarValue.year && calendarValue.quarter && !calendarValue.month
      ? `${calendarValue.year}${calendarValue.quarter}`
      : `${calendarValue.year}${calendarValue.month}`;

  const handleCalendarOpen = () => setOpen(true);
  const handleCalendarClose = () => {
    setOpen(false);
    setSelectedPeriod({ value: value, filterValue: filterValue });
  };

  const onChange = (date: Date) => {
    if (calendarView === YEAR) {
      setCalendarValue({
        year: `${moment(date).year()}`,
        quarter: "",
        month: "",
      });
      setValue(date);
    } else if (calendarView === QUARTER) {
      setCalendarValue({
        year: `${moment(date).year()}`,
        quarter: `Q${moment(date).format("Q")}`,
        month: "",
      });
      setValue(date);
    } else {
      setCalendarValue({
        year: `${moment(date).year()}`,
        quarter: `Q${moment(date).format("Q")}`,
        month: moment(date).format("MM"),
      });
      setValue(date);
    }
  };
  const onYearClick = () => setCalendarView(YEAR);
  const onMonthClick = () => setCalendarView(MONTH);
  const onQuarterClick = () => setCalendarView(QUARTER);

  const [dateFormat, setDateFormat] = useState<string>("yyyy");

  const getDateFormat = () => {
    const periodValue = period.filterValue.slice(4);
    if (periodValue === "00") {
      setDateFormat("yyyy");
    } else if (periodValue.includes("Q")) {
      setDateFormat("yyyy, QQQQ");
    } else {
      setDateFormat("yyyy, QQQQ, MM");
    }
  };

  useEffect(() => {
    getDateFormat();
  }, [period]);

  const DatePickerContainer = ({
    className,
    children,
  }: ICalendarContainerProps) => {
    return (
      <Box sx={css.wrapper(calendarView === YEAR, calendarView === MONTH)}>
        <CalendarContainer>
          <Box sx={css.calendarContainer}>{children}</Box>
          <Box sx={css.buttonsContainer}>
            <Box
              onClick={onYearClick}
              sx={css.button(calendarView === YEAR) as SxProps}
            >
              Год
            </Box>
            <Box
              onClick={onQuarterClick}
              sx={css.button(calendarView === QUARTER) as SxProps}
            >
              Квартал
            </Box>
            <Box
              onClick={onMonthClick}
              sx={css.button(calendarView === MONTH) as SxProps}
            >
              Месяц
            </Box>
          </Box>
        </CalendarContainer>
      </Box>
    );
  };

  return (
    <Box sx={css.datePickerContainer}>
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
        }: IDatePickerCustomHeaderProps) =>
          calendarView === YEAR ? null : (
            <Box sx={css.datePickerHeaderContainer}>
              <Box sx={css.headerBtn} onClick={decreaseYear}>
                <BlueArrowLeft />
              </Box>
              <Typography sx={css.headerText}>
                {moment(date).format("YYYY")}
              </Typography>
              <Box
                sx={css.headerBtn}
                style={{
                  justifyContent: "flex-end",
                }}
                onClick={increaseYear}
              >
                <BlueArrowRight />
              </Box>
            </Box>
          )
        }
        selected={value}
        locale={ruLocale}
        onChange={onChange}
        dateFormat={dateFormat}
        showQuarterYearPicker={calendarView === QUARTER}
        showYearPicker={calendarView === YEAR}
        showMonthYearPicker={calendarView === MONTH}
        showFullMonthYearPicker={calendarView === MONTH}
        calendarContainer={DatePickerContainer}
        shouldCloseOnSelect
        portalId="calendar-root-portal"
        popperClassName="calendar-popper"
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        customInput={
          <TextField
            variant="filled"
            helperText={null}
            sx={css.textField}
            InputProps={{
              startAdornment: <CalendarIcon />,
              endAdornment: isOpen ? (
                <SmallWhiteArrowUp />
              ) : (
                <SmallWhiteArrowDown />
              ),
            }}
          />
        }
      />
    </Box>
  );
};
