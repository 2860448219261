import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import React, { FC } from "react";
import { css } from "SRC/components/CardsAnalitic/CardsAnalitic.styled";

interface ICardsAnalitic {
  sx?: SxProps;
  headerLeft?: string;
  headerRight?: string | number | React.ReactNode;
  style?: CSSProperties | undefined;
}

export const CardsAnalitic: FC<ICardsAnalitic> = ({
  children,
  headerLeft,
  headerRight,
  style,
}) => {
  return (
    <Box sx={css.container as SxProps}>
      {(headerLeft || headerRight) && (
        <Box sx={css.header}>
          <Box sx={css.headerLeft}>{headerLeft}</Box>
          <Box>{headerRight}</Box>
        </Box>
      )}
      <Box sx={style}>{children}</Box>
    </Box>
  );
};
