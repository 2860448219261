import { Box, Modal, SxProps } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { EventCardWrapper } from "SRC/pages/Program/common/EventCardWrapper/EventCardWrapper";

import { css } from "../Modals.styled";

interface IModalEventProps {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  openTableModal?: boolean;
}

export const ModalEvent = ({ open, setOpen }: IModalEventProps) => {
  const handleClose = () => setOpen?.(false);
  return (
    <Modal
      open={open || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={css.modalBox as SxProps}>
        <EventCardWrapper setOpen={setOpen} open={open} />
      </Box>
    </Modal>
  );
};
