import { Theme } from "@mui/material";

const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "май",
  "июнь",
  "июль",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

const getOption = (planData: number[], factData: number[], theme: Theme) => {
  return {
    xAxis: {
      type: "category",
      show: false,
      data: months,
    },
    yAxis: {
      type: "value",
      show: false,
    },
    grid: {
      height: "100%",
      width: "97%",
      top: "40%",
      right: "3%",
    },
    tooltip: {
      show: true,
    },
    series: [
      {
        data: planData,
        type: "line",
        symbol: "none",
        smooth: true,
        itemStyle: {
          color: theme.palette.main.blue,
        },
      },
      {
        data: factData,
        type: "line",
        smooth: true,
        symbol: "none",
        itemStyle: {
          color: theme.palette.primary.contrastText,
        },
      },
    ],
  };
};

export default getOption;
