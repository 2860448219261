import { styled, Typography } from "@mui/material";
import theme from "SRC/theme";

let height = 0.75;
let trackMargins = 0.25;
let trackHeight = height - trackMargins;

export const IndicatorLineGraphWrap = styled("div")((props) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "2.5rem",
}));

export const LineWrap = styled("div")((props) => ({
  height: `${height}rem`,
  padding: `0.25rem`,
  position: "relative",
  borderRadius: `${height / 2}rem`,
  backgroundColor: props.theme.palette.main.darkBlue,
}));
export const LineTrack = styled("div")((props) => ({
  position: "absolute",
  height: `${trackHeight}rem`,
  left: `${trackMargins / 2}rem`,
  top: `${trackMargins / 2}rem`,
  width: `calc(100% - ${trackMargins}rem)`,
  borderRadius: `${height / 2}rem`,
  backgroundColor: props.theme.palette.main.darkBlue,
}));
const graphLine = styled("div")((props) => ({
  height: `100%`,
  position: "absolute",
  top: 0,
  borderRadius: `${trackHeight / 2}rem`,
}));
export const DoneLine = styled(graphLine)((props) => ({
  left: 0,
  zIndex: 1,
  "&:after": {
    position: "absolute",
    content: '""',
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "7px",
    top: "-0.125rem",
    right: 0,
  },
}));
export const DoneLineCount = styled("div")((props) => ({
  position: "absolute",
  right: "0.375rem",
  top: `${height * 2.25}rem`,
  [theme.breakpoints.up("xl")]: {
    top: `${height * 2.25}rem`,
  },
}));
export const DoneLineIco = styled("div")((props) => ({
  position: "absolute",
  right: 0,
  top: "0.5rem",
  width: "0.75rem",
  height: "0.5rem",
  "& img": {
    width: "1rem",
    right: "0.1rem",
    position: "relative",
  },
  "&:after": {
    position: "relative",
    content: '"%"',
    top: "0.0625rem",
    left: "0.625rem",
    color: props.theme.palette.text.secondary,
  },
}));
export const WarningLine = styled(graphLine)((props) => ({
  right: 0,
  backgroundColor: props.theme.palette.error.main,
  "&:after": {
    position: "absolute",
    content: '""',
    fontSize: "0.0625rem",
    width: "0.0625rem",
    height: "2.5rem",
    backgroundColor: props.theme.palette.error.main,
    top: 0,
    left: 0,
  },
}));
export const WarningCount = styled("div")((props) => ({
  position: "absolute",
  left: `0.625rem`,
  top: `${height * 1.5}rem`,
}));
export const WarningIco = styled("div")((props) => ({
  width: "1.625rem",
  height: "1.375rem",
  position: "absolute",
  left: `-2.1875rem`,
  top: `${height * 1.5}rem`,
}));
export const InProgressLine = styled(graphLine)((props) => ({
  left: 0,
  backgroundColor: props.theme.palette.main.yellow,
}));

export const LineTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.125rem",
  color: theme.palette.text.onAccent,
  fontWeight: "bold",
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.625rem",
  },
}));
export const LineTitleBottom = styled(Typography)(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 400,
  color: theme.palette.divider,
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.125rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));

export const LineCountLeft = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
}));

export const LineCountRight = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));
