import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, {
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import ProgramsCounter from "SRC/components/ProgramsCounter/ProgramsCounter";
import Select from "SRC/components/Select/Select";
import {
  getInfoRowsSummaryPrograms,
  getInfoRowsSummaryVicePremiers,
} from "SRC/helpers/getInfoRows";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { GeneralInfoTabFilters } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralFullInfoTab/GeneralInfoTabFilters/GeneralInfoTabFilters";
import { GeneralInfoTab } from "SRC/pages/Summary/common/SummaryComplexGrid/GeneralInfoTab/GeneralInfoTab";
import {
  IGeneralInfoDataItem,
  IGeneralInfoDataItemWithoutIcon,
  IGeneralInfoPremiersDataItem,
  IGeneralInfoPremiersDataItemWithoutId,
  ITableDataItem,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { actions as tableTypeAction } from "SRC/redux/slices/gosprogram/slices/tableType";
import {
  IPremiers,
  TPremier,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import {
  EGridTabs,
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import {
  IPremiersFull,
  usePremiersFull,
} from "SRC/redux/slices/summary/hooks/usePremiers";
import { usePrograms as useProgramsFull } from "SRC/redux/slices/summary/hooks/usePrograms";
import { dispatch } from "SRC/redux/store";
import themeConfig from "SRC/theme";
import { Range } from "xlsx";

import { Filters } from "../Filters/Filters";
import { GeneralFullInfoPremiersTab } from "./GeneralFullInfoPremiersTab/GeneralFullInfoPremiersTab";
import { GeneralFullInfoTab } from "./GeneralFullInfoTab/GeneralFullInfoTab";
import { GeneralInfoPremiersTab } from "./GeneralInfoPremiersTab/GeneralInfoPremiersTab";
import { css } from "./SummaryComplexGrid.styled";

const tabs = [
  {
    label: "Госпрограммы",
    value: EGridTabs.PROGRAMS,
  },
  {
    label: "Вице-премьеры",
    value: EGridTabs.PREMIERS,
  },
  {
    label: "Регионы",
    value: EGridTabs.REGIONS,
    disabled: true,
  },
  {
    label: "Округа",
    value: EGridTabs.DISTRICTS,
    disabled: true,
  },
];

interface ISummaryComplexGrid {
  forwardRef?: MutableRefObject<HTMLElement | undefined>;
  onScroll?: (scroll: any) => void;
  scrollX?: boolean;
  setSortBy: (sortBy: any) => void;
  tableData: ITableDataItem[];
}

interface ITabExportData {
  downloadData:
    | IGeneralInfoDataItemWithoutIcon[]
    | IGeneralInfoPremiersDataItemWithoutId[];
  fileName: string;
  headings: Record<string, string | object>[];
  mergeCells: Range[] | undefined;
  wscols: Record<string, number>[];
  infoRows: Record<string, string>[][];
}

type TExportData = {
  [key: string]: ITabExportData;
};

export const SummaryComplexGrid = ({
  forwardRef,
  onScroll,
  setSortBy,
  tableData,
}: ISummaryComplexGrid) => {
  const theme = useTheme<typeof themeConfig>();
  const [tableType, setTableType] = useState<string>(EGridTabs.PROGRAMS);
  const {
    clear,
    calculationMethod,
    complexGridTab,
    setSelectedComplexGridTab,
  }: IFilters = useFilters();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [isGovProgramsModalOpen, setGovProgramsModalOpen] = useState(false);
  const [isVicePremiersModalOpen, setVicePremiersModalOpen] = useState(false);
  const [isGovProgramsColumnsModalOpen, setGovProgramsColumnsModalOpen] =
    useState(false);
  const [isVicePremiersColumnsModalOpen, setVicePremiersColumnsModalOpen] =
    useState(false);

  const tabsHandler = (e: SyntheticEvent, value: string) => {
    setSelectedComplexGridTab(value);
    // clear();
  };

  useEffect(() => {
    dispatch(tableTypeAction.changeTableType(complexGridTab));
  }, [complexGridTab]);

  const {
    program,
    filteredPrograms,
    vp,
    setSelectedVP,
    filteredVPs,
  }: IFilters = useFilters();
  const { items: programs } = usePrograms();
  const { items: programsFull } = useProgramsFull();
  const { items: vpItems }: IPremiers = usePremiers();
  const { elems: vpElems }: IPremiersFull = usePremiersFull();
  const [generalInfoVicePremiersFilter, setGeneralInfoVicePremiersFilter] =
    useState<string>("");
  const [generalInfoFoivFilter, setGeneralInfoFoivFilter] =
    useState<string>("");

  const summaryProgramsTableData = useMemo<IGeneralInfoDataItem[]>(
    () =>
      programs
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((item: TProgram, index) => ({
          id: item.gp_code,
          icon: item.gp_icon_url,
          govProgram: item.gp_short_name,
          secretProgram: Number(item["Секретная"]),
          vicePremier: item["Вице-премьер"] !== -1 ? item["Вице-премьер"] : "",
          foiv: "Минпромторг",
          performance: ~~(Math.random() * 100),
          populationSatisfaction: ~~(Math.random() * 100),
          cashPlan: ~~(Math.random() * 100),
          cashFact: ~~(Math.random() * 100),
          goalsPlan: ~~(Math.random() * 10),
          goalsDone: ~~(Math.random() * 10),
          goalsFail: ~~(Math.random() * 10),
          indicatorsPlan: ~~(Math.random() * 10),
          indicatorsDone: ~~(Math.random() * 10),
          indicatorsFail: ~~(Math.random() * 10),
          eventsPlan: ~~(Math.random() * 10),
          eventsDone: ~~(Math.random() * 10),
          eventsFail: ~~(Math.random() * 10),
        }))
        .sort((a: IGeneralInfoDataItem, b: IGeneralInfoDataItem) => {
          return Number(a.id) - Number(b.id);
        }),
    [programs, filteredPrograms]
  );

  const summaryProgramsTableDataFull = useMemo<IGeneralInfoDataItem[]>(
    () =>
      programsFull
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((elem: TProgram, index) => ({
          id: elem.gp_code,
          icon: elem.gp_icon_url,
          govProgram: elem.gp_short_name,
          secretProgram: Number(elem["Секретная"]),
          vicePremier: elem["Вице-премьер"] !== -1 ? elem["Вице-премьер"] : "",
          foiv: elem["Министерство"],
          performance:
            calculationMethod === "effect"
              ? elem.data.find((el) => el.code === "1753")?.sum || 0
              : elem.data.find((el) => el.code === "1796")?.sum || 0,
          populationSatisfaction:
            elem.data.find((el) => el.code === "1765")?.sum || 0,
          cashPlan: elem.data.find((el) => el.code === "1590")?.sum || 0,
          cashFact: elem.data.find((el) => el.code === "1754")?.sum || 0,
          goalsPlan: elem.data.find((el) => el.code === "1755")?.sum || 0,
          goalsDone: elem.data.find((el) => el.code === "1756")?.sum || 0,
          goalsFail: elem.data.find((el) => el.code === "1757")?.sum || 0,
          indicatorsPlan: elem.data.find((el) => el.code === "1578")?.sum || 0,
          indicatorsDone: elem.data.find((el) => el.code === "1579")?.sum || 0,
          indicatorsFail: elem.data.find((el) => el.code === "1580")?.sum || 0,
          eventsPlan: elem.data.find((el) => el.code === "1584")?.sum || 0,
          eventsDone: elem.data.find((el) => el.code === "1585")?.sum || 0,
          eventsFail: elem.data.find((el) => el.code === "1586")?.sum || 0,
        }))
        .sort((a: IGeneralInfoDataItem, b: IGeneralInfoDataItem) => {
          return Number(a.id) - Number(b.id);
        }),
    [programsFull, filteredPrograms, calculationMethod]
  );

  const summaryProgramsTableDataFiltered: IGeneralInfoDataItem[] =
    useMemo(() => {
      if (generalInfoVicePremiersFilter && generalInfoFoivFilter) {
        return summaryProgramsTableDataFull?.filter(
          (item: IGeneralInfoDataItem) =>
            item.vicePremier === generalInfoVicePremiersFilter &&
            item.foiv === generalInfoFoivFilter
        );
      }
      if (generalInfoVicePremiersFilter && !generalInfoFoivFilter) {
        return summaryProgramsTableDataFull?.filter(
          (item: IGeneralInfoDataItem) =>
            item.vicePremier === generalInfoVicePremiersFilter
        );
      }
      if (!generalInfoVicePremiersFilter && generalInfoFoivFilter) {
        return summaryProgramsTableDataFull?.filter(
          (item: IGeneralInfoDataItem) => item.foiv === generalInfoFoivFilter
        );
      }
      return summaryProgramsTableDataFull;
    }, [
      generalInfoVicePremiersFilter,
      generalInfoFoivFilter,
      summaryProgramsTableDataFull,
    ]);

  const summaryVicePremiersTableData = useMemo<IGeneralInfoPremiersDataItem[]>(
    () =>
      vpItems
        .filter((vp) => filteredVPs.includes(vp.VP_CODE))
        .map((item: TPremier, index: number) => ({
          id: index + 1,
          vicePremier: item.VP_SHORT_NAME,
          vicePremierPhoto: item.VP_PHOTO_URL,
          govPrograms: ~~(Math.random() * 10),
          performance: ~~(Math.random() * 100),
          populationSatisfaction: ~~(Math.random() * 100),
          cashPlan: ~~(Math.random() * 100),
          cashFact: ~~(Math.random() * 100),
          goalsPlan: ~~(Math.random() * 10),
          goalsDone: ~~(Math.random() * 10),
          goalsFail: ~~(Math.random() * 10),
          indicatorsPlan: ~~(Math.random() * 10),
          indicatorsDone: ~~(Math.random() * 10),
          indicatorsFail: ~~(Math.random() * 10),
          eventsPlan: ~~(Math.random() * 10),
          eventsDone: ~~(Math.random() * 10),
          eventsFail: ~~(Math.random() * 10),
        }))
        .sort(
          (
            a: IGeneralInfoPremiersDataItem,
            b: IGeneralInfoPremiersDataItem
          ) => {
            return Number(a.id) - Number(b.id);
          }
        ),
    [vpItems, filteredVPs]
  );

  const summaryVicePremiersTableDataFull = useMemo<
    IGeneralInfoPremiersDataItem[]
  >(
    () =>
      vpElems
        .filter((vp) => filteredVPs.includes(vp.VP_CODE))
        .map((elem: TPremier, index: number) => ({
          id: index + 1,
          vicePremier: elem.VP_SHORT_NAME,
          vicePremierPhoto: elem.VP_PHOTO_URL,
          govPrograms: elem.data.find((el) => el.code === "1765")?.sum || 0,
          performance:
            calculationMethod === "effect"
              ? elem.data.find((el) => el.code === "1759")?.sum || 0
              : elem.data.find((el) => el.code === "1797")?.sum || 0,
          populationSatisfaction:
            elem.data.find((el) => el.code === "1764")?.sum || 0,
          cashPlan: elem.data.find((el) => el.code === "1711")?.sum || 0,
          cashFact: elem.data.find((el) => el.code === "1712")?.sum || 0,
          goalsPlan: elem.data.find((el) => el.code === "1761")?.sum || 0,
          goalsDone: elem.data.find((el) => el.code === "1762")?.sum || 0,
          goalsFail: elem.data.find((el) => el.code === "1763")?.sum || 0,
          indicatorsPlan: elem.data.find((el) => el.code === "1699")?.sum || 0,
          indicatorsDone: elem.data.find((el) => el.code === "1700")?.sum || 0,
          indicatorsFail: elem.data.find((el) => el.code === "1701")?.sum || 0,
          eventsPlan: elem.data.find((el) => el.code === "1705")?.sum || 0,
          eventsDone: elem.data.find((el) => el.code === "1706")?.sum || 0,
          eventsFail: elem.data.find((el) => el.code === "17107")?.sum || 0,
        }))
        .sort(
          (
            a: IGeneralInfoPremiersDataItem,
            b: IGeneralInfoPremiersDataItem
          ) => {
            return Number(a.id) - Number(b.id);
          }
        ),
    [vpItems, filteredVPs, calculationMethod]
  );

  const components = (complexGridTab: number | string) => {
    if (complexGridTab === "vicePremiers" && matches) {
      return [
        <GeneralInfoPremiersTab
          setSortBy={setSortBy}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={summaryProgramsTableDataFull}
        />,
      ];
    } else {
      return [
        <GeneralInfoTab
          setSortBy={setSortBy}
          forwardRef={forwardRef}
          onScroll={onScroll}
          tableData={summaryProgramsTableDataFull}
        />,
      ];
    }
  };

  const onlyUnique = (
    value: string | number | undefined,
    index: number,
    arr: any
  ) => {
    return arr.indexOf(value) === index;
  };

  const generalInfoVicePremiersFilterValues = useMemo<
    (string | number | undefined)[]
  >(
    () =>
      summaryProgramsTableDataFiltered
        .map((item) => (item.vicePremier !== -1 ? item.vicePremier : ""))
        .filter(Boolean)
        .filter(onlyUnique),
    [summaryProgramsTableDataFiltered]
  );
  const generalInfoFoivFilterValues = useMemo<(string | undefined)[]>(
    () =>
      summaryProgramsTableDataFiltered
        .map((item) => item.foiv)
        .filter(Boolean)
        .filter(onlyUnique),
    [summaryProgramsTableDataFiltered]
  );

  const summaryProgramsTableDataExport = summaryProgramsTableDataFiltered.map(
    ({ icon, secretProgram, ...item }) => item
  );

  const summaryVicePremiersTableDataExport = summaryVicePremiersTableData.map(
    ({ id, ...item }) => item
  );

  const {
    headings: summaryProgramsHeadings,
    mergeCells: summaryProgramsMergeCells,
    wscols: summaryProgramsWscols,
  } = useTableExportParams(summaryProgramsTableDataExport, "summaryPrograms");
  const {
    headings: summaryVicePremiersHeadings,
    mergeCells: summaryVicePremiersMergeCells,
    wscols: summaryVicePremiersWscols,
  } = useTableExportParams(
    summaryVicePremiersTableDataExport,
    "summaryVicePremiers"
  );

  const gpExportFilter =
    generalInfoVicePremiersFilter && generalInfoFoivFilter
      ? `Госпрограммы вице-премьера ${generalInfoVicePremiersFilter}, министерства ${generalInfoFoivFilter}`
      : generalInfoVicePremiersFilter && !generalInfoFoivFilter
      ? `Госпрограммы вице-премьера ${generalInfoVicePremiersFilter}`
      : !generalInfoVicePremiersFilter && generalInfoFoivFilter
      ? `Госпрограммы министерства ${generalInfoFoivFilter}`
      : "Все";

  const infoRowsSummaryPrograms = useMemo(
    () => getInfoRowsSummaryPrograms(gpExportFilter),
    [summaryProgramsTableDataExport]
  );
  const infoRowsSummaryVicePremiers = useMemo(
    () => getInfoRowsSummaryVicePremiers(),
    [summaryVicePremiersTableDataExport]
  );

  const tabExportData: ITabExportData = useMemo(() => {
    const exportData: TExportData = {
      [EGridTabs.PROGRAMS]: {
        downloadData: summaryProgramsTableDataExport,
        fileName: "Сводная таблица. Госпрограммы",
        wscols: summaryProgramsWscols,
        headings: summaryProgramsHeadings,
        mergeCells: summaryProgramsMergeCells,
        infoRows: infoRowsSummaryPrograms,
      },
      [EGridTabs.PREMIERS]: {
        downloadData: summaryVicePremiersTableDataExport,
        fileName: "Сводная таблица. Вице-премьеры",
        wscols: summaryVicePremiersWscols,
        headings: summaryVicePremiersHeadings,
        mergeCells: summaryVicePremiersMergeCells,
        infoRows: infoRowsSummaryVicePremiers,
      },
    };
    return exportData[complexGridTab];
  }, [
    complexGridTab,
    summaryProgramsTableDataFiltered,
    summaryVicePremiersTableData,
  ]);

  const { downloadData, fileName, wscols, headings, mergeCells, infoRows } =
    tabExportData;

  const componentsDesktop = [
    <GeneralFullInfoTab
      forwardRef={forwardRef}
      onScroll={onScroll}
      tableData={summaryProgramsTableDataFiltered}
      program={program}
      setSelectedProgram={() => null}
      isTableModalOpen={isGovProgramsModalOpen}
      setTableModalOpen={setGovProgramsModalOpen}
      generalInfoVicePremiersFilter={generalInfoVicePremiersFilter}
      setGeneralInfoVicePremiersFilter={setGeneralInfoVicePremiersFilter}
      generalInfoFoivFilter={generalInfoFoivFilter}
      setGeneralInfoFoivFilter={setGeneralInfoFoivFilter}
      generalInfoVicePremiersFilterValues={generalInfoVicePremiersFilterValues}
      generalInfoFoivFilterValues={generalInfoFoivFilterValues}
      downloadData={summaryProgramsTableDataExport}
      infoRows={infoRowsSummaryPrograms}
      isColumnsModalOpen={isGovProgramsColumnsModalOpen}
      setColumnsModalOpen={setGovProgramsColumnsModalOpen}
    />,
    <GeneralFullInfoPremiersTab
      forwardRef={forwardRef}
      onScroll={onScroll}
      tableData={summaryVicePremiersTableDataFull}
      vp={vp}
      setSelectedVP={setSelectedVP}
      isTableModalOpen={isVicePremiersModalOpen}
      setTableModalOpen={setVicePremiersModalOpen}
      downloadData={summaryVicePremiersTableDataExport}
      infoRows={infoRowsSummaryVicePremiers}
      isColumnsModalOpen={isVicePremiersColumnsModalOpen}
      setColumnsModalOpen={setVicePremiersColumnsModalOpen}
    />,
  ];

  const selectStyle = useMemo(() => css.selector(theme), [theme]);

  return (
    <Box>
      {matches ? (
        <>
          <Box sx={css.tableHeaderWrapper}>
            <Paper
              sx={css.tableTitle}
              component="h1"
              onClick={clear}
              elevation={0}
            >
              Сводная таблица
            </Paper>
            <ProgramsCounter />
          </Box>
          <Box sx={css.divider} />
          <Box sx={css.selectorsWrapper}>
            <Select
              value={String(complexGridTab)}
              onChange={setSelectedComplexGridTab}
              options={tabs}
              label="Вид"
              hasAllOption={false}
              colorizeActiveOption={false}
              style={selectStyle}
            />
            <Box sx={css.filters}>
              <Filters withCounter={false} />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={css.headerContainer}>
            <Typography variant="h1" component="h1">
              Сводная таблица
            </Typography>
          </Box>
          <CustomTabs
            value={complexGridTab}
            onChange={tabsHandler}
            tabs={tabs}
            withBgr
            contained
            components={componentsDesktop}
            style={css.tabsWrapper(theme, false, true)}
            borderType={"none"}
            withTableButtons
            isTableModalOpen={
              complexGridTab === EGridTabs.PROGRAMS
                ? isGovProgramsModalOpen
                : isVicePremiersModalOpen
            }
            setTableModalOpen={
              complexGridTab === EGridTabs.PROGRAMS
                ? setGovProgramsModalOpen
                : setVicePremiersModalOpen
            }
            downloadData={downloadData}
            fileName={fileName}
            wscols={wscols}
            headings={headings}
            mergeCells={mergeCells}
            infoRows={infoRows}
            rightSideFilters={
              complexGridTab === EGridTabs.PROGRAMS && <GeneralInfoTabFilters />
            }
            withCustomColumns
            setColumnsModalOpen={
              complexGridTab === EGridTabs.PROGRAMS
                ? setGovProgramsColumnsModalOpen
                : setVicePremiersColumnsModalOpen
            }
          />
        </>
      )}
      {matches && components(tableType)}
    </Box>
  );
};
