import { Theme } from "@mui/material";

export const css = {
  structureWrapper: (theme: Theme, openTableModal?: boolean) => ({
    border: openTableModal
      ? "none"
      : `1px solid ${theme.palette.background.opacityWhite}`,
    borderRadius: "0.75rem",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: `linear-gradient(180deg, ${theme.palette.background.paperDarkGray} 0%, ${theme.palette.background.paperDarkBlue} 100%)`,
    color: theme.palette.text.onAccent,
    [theme.breakpoints.up("xl")]: { border: "none" },
  }),
  titleWrapper: (theme: Theme) => ({
    justifyContent: "space-between",
    display: "flex",
    padding: "2.5rem 2.5rem 1.5rem 2.5rem",
    background: `linear-gradient(180deg, ${theme.palette.background.paperDarkBlue} 0%, ${theme.palette.background.paperDarkGray} 100%)`,
    [theme.breakpoints.up("xl")]: { padding: "1.5rem 1.5rem 0.5rem 1.5rem" },
  }),
  closeButton: {
    cursor: "pointer",
  },
  headerTitle: (theme: Theme) => ({
    fontSize: "2rem",
    fontWeight: 700,
    [theme.breakpoints.up("xl")]: { fontSize: "1.5rem" },
  }),
  filterStatusesContainer: (theme: Theme) => ({
    padding: "1.5rem 2.5rem 2.5rem 2.5rem",
    display: "flex",
    alignItems: "end",
    gap: "0.5rem",
    justifyContent: "space-between",
    [theme.breakpoints.up("xl")]: { padding: "1.5rem" },
  }),
  statusesContainer: (theme: Theme) => ({
    display: "flex",
    height: "100%",
    gap: "0.5rem",
    flex: "1",
    alignItems: "end",
    [theme.breakpoints.up("xl")]: { alignItems: "start" },
  }),
  buttonContainer: (theme: Theme) => ({
    display: "flex",
    justifyContent: "end",
    flexWrap: "wrap",
    flex: 1,
    gap: "0.5rem",
  }),
  statusesButton: (theme: Theme) => ({
    fontSize: "1rem",
    fontWeight: 700,
    maxHeight: "2.5rem",
    maxWidth: "20.25rem",
    minWidth: "16rem",
    width: "100%",
    [theme.breakpoints.up("xl")]: { fontSize: "1rem !important" },
  }),
  tableStatusContainer: (theme: Theme) => ({
    display: "flex",
    padding: "1.5rem 1rem",
    width: "100%",
    minWidth: "9rem",
    minHeight: "9.5rem",
    background: (theme: Theme) =>
      `linear-gradient(180deg, ${theme.palette.background.paperDarkBlue} 0%, ${theme.palette.background.paperDarkGray} 100%)`,
    [theme.breakpoints.up("xl")]: { minHeight: "7.5rem" },
  }),
  totalContainer: (theme: Theme) => ({
    background: `${theme.palette.complementary.darkBlue}`,
    padding: "1.5rem 1.875rem",
    display: "flex",
    flex: 1,
    maxWidth: "10rem",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minHeight: "10rem",
    minWidth: "10rem",
    borderRadius: "1rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: "20rem",
    },
    [theme.breakpoints.up("xl")]: { padding: "1.5rem" },
  }),
  totalTitle: {
    fontSize: "1rem",
    color: (theme: Theme) => `${theme.palette.text.primary}`,
  },
  totalCount: {
    fontSize: "2.5rem",
    fontWeight: 700,
  },
};
