import { Theme, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Layout } from "SRC/layouts";
import {
  IRatingTopBestData,
  IRatingTopWorstData,
} from "SRC/pages/Summary/common/Rating/util/interfaces";
import { ITableDataItem } from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { useAppSelector } from "SRC/redux/hooks";
import {
  IFilters,
  useFilters,
} from "SRC/redux/slices/summary/hooks/useFilters";
import {
  IPremiers,
  TPremier,
  usePremiers,
} from "SRC/redux/slices/summary/hooks/usePremiers";
import {
  TProgram,
  usePrograms,
} from "SRC/redux/slices/summary/hooks/usePrograms";
import { useProgramTopBest } from "SRC/redux/slices/summary/hooks/useProgramTopBest";
import { useProgramTopWorst } from "SRC/redux/slices/summary/hooks/useProgramTopWorst";
import { useSummary } from "SRC/redux/slices/summary/hooks/useSummary";

import { CenterLayout, LeftLayout, RightLayout } from "./layouts";

export const Summary = () => {
  const theme = useTheme<Theme>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));

  const [sortBy, setSortBy] = useState<{ id: string; desc: boolean } | null>(
    null
  );

  const { filteredPrograms, filteredVPs }: IFilters = useFilters();
  const { loading, loaded, init } = useSummary();
  const { items: programs } = usePrograms();
  const { items: vpItems }: IPremiers = usePremiers();
  const { items: programsTopWorst, fetched: fetchedWorst } =
    useProgramTopWorst();
  const { items: programsTopBest, fetched: fetchedBest } = useProgramTopBest();
  const { data: dataTopBest } = programsTopBest[0]?.data[0] || [{}];
  const { data: dataTopWorst } = programsTopWorst[0]?.data[0] || [{}];
  const tableType = useAppSelector(
    (state) => state.gosprogram.tableType.tableType
  );

  const leftTableRef = useRef<HTMLElement>();
  const cashTableRef = useRef<HTMLElement>();
  const goalTableRef = useRef<HTMLElement>();
  const indicatorsTableRef = useRef<HTMLElement>();
  const eventsTableRef = useRef<HTMLElement>();
  const satisfactionTableRef = useRef<HTMLElement>();
  useEffect(() => {
    if (!loading && !loaded) {
      init();
    }
  }, [init, loaded, loading]);
  const handleScrollLeft = (scroll: any) => {
    if (
      cashTableRef.current &&
      goalTableRef.current &&
      indicatorsTableRef.current &&
      eventsTableRef.current &&
      satisfactionTableRef.current
    ) {
      cashTableRef.current.scrollTop = scroll.target.scrollTop;
      goalTableRef.current.scrollTop = scroll.target.scrollTop;
      indicatorsTableRef.current.scrollTop = scroll.target.scrollTop;
      eventsTableRef.current.scrollTop = scroll.target.scrollTop;
      satisfactionTableRef.current.scrollTop = scroll.target.scrollTop;
    }
  };

  const handleScrollCenter = (scroll: any) => {
    if (leftTableRef.current) {
      leftTableRef.current.scrollTop = scroll.target.scrollTop;
    }
  };

  const bestData = useMemo<IRatingTopBestData[]>(
    () =>
      dataTopBest && fetchedBest
        ? [...dataTopBest].sort(
            (a: IRatingTopBestData, b: IRatingTopBestData) =>
              Number(b.sum) - Number(a.sum)
          )
        : [],
    [dataTopBest, fetchedBest]
  );

  const worstData = useMemo<IRatingTopWorstData[]>(
    () =>
      dataTopWorst && fetchedWorst
        ? [...dataTopWorst].sort(
            (a: IRatingTopWorstData, b: IRatingTopWorstData) =>
              Number(b.sum) - Number(a.sum)
          )
        : [],
    [dataTopWorst, fetchedWorst]
  );

  const tableData = useMemo<ITableDataItem[]>(() => {
    if (tableType === 0) {
      return programs
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((item: TProgram) => ({
          id: item.gp_code,
          icon: item.gp_icon_url,
          govProgram: item.gp_short_name,
          vicePremier: item["Вице-премьер"] !== -1 ? item["Вице-премьер"] : "",
          performance: ~~(Math.random() * 100),
          populationSatisfaction: ~~(Math.random() * 100),
          cashPlan: ~~(Math.random() * 100),
          cashFact: ~~(Math.random() * 100),
          goalsPlan: ~~(Math.random() * 10),
          goalsDone: ~~(Math.random() * 10),
          goalsFail: ~~(Math.random() * 10),
          indicatorsPlan: ~~(Math.random() * 10),
          indicatorsDone: ~~(Math.random() * 10),
          indicatorsFail: ~~(Math.random() * 10),
          eventsPlan: ~~(Math.random() * 10),
          eventsDone: ~~(Math.random() * 10),
          eventsFail: ~~(Math.random() * 10),
        }))
        .sort((a: ITableDataItem, b: ITableDataItem) => {
          return Number(a.id) - Number(b.id);
        });
    } else {
      return vpItems
        .filter((vp) => filteredVPs.includes(vp.VP_CODE))
        .map((item: TPremier, index: number) => ({
          id: index + 1,
          vicePremierPhoto: item.VP_PHOTO_URL,
          vicePremier: item.VP_SHORT_NAME,
          govPrograms: ~~(Math.random() * 10),
          performance: ~~(Math.random() * 100),
          populationSatisfaction: ~~(Math.random() * 100),
          cashPlan: ~~(Math.random() * 100),
          cashFact: ~~(Math.random() * 100),
          goalsPlan: ~~(Math.random() * 10),
          goalsDone: ~~(Math.random() * 10),
          goalsFail: ~~(Math.random() * 10),
          indicatorsPlan: ~~(Math.random() * 10),
          indicatorsDone: ~~(Math.random() * 10),
          indicatorsFail: ~~(Math.random() * 10),
          eventsPlan: ~~(Math.random() * 10),
          eventsDone: ~~(Math.random() * 10),
          eventsFail: ~~(Math.random() * 10),
        }))
        .sort((a: ITableDataItem, b: ITableDataItem) => {
          return Number(a.id) - Number(b.id);
          //  добавить условие если есть сортбай то сортировать по полю которое лежит внутри сортбай
          if (sortBy) {
            return sortBy?.desc
              ? String(b[sortBy?.id as keyof ITableDataItem]).localeCompare(
                  String(a[sortBy?.id as keyof ITableDataItem])
                )
              : String(a[sortBy?.id as keyof ITableDataItem]).localeCompare(
                  String(b[sortBy?.id as keyof ITableDataItem])
                );
          }
        });
    }
  }, [programs, filteredPrograms, vpItems, filteredVPs, tableType, sortBy]);

  return (
    <Layout
      left={
        <LeftLayout
          theme={theme}
          isLargeSize={isLargeSize}
          forwardRef={leftTableRef}
          onScroll={handleScrollLeft}
          setSortBy={setSortBy}
          tableData={tableData}
          topBestData={bestData}
          topWorstData={worstData}
        />
      }
      center={
        isLargeSize && (
          <CenterLayout
            cashTableRef={cashTableRef}
            goalTableRef={goalTableRef}
            indicatorsTableRef={indicatorsTableRef}
            eventsTableRef={eventsTableRef}
            satisfactionTableRef={satisfactionTableRef}
            onScroll={handleScrollCenter}
            setSortBy={setSortBy}
            tableData={tableData}
          />
        )
      }
      right={
        isLargeSize && (
          <RightLayout topBestData={bestData} topWorstData={worstData} />
        )
      }
    />
  );
};
