import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { IProgramEvent } from "SRC/redux/slices/gosprogram/slices/programEvents";
import { IParams } from "SRC/redux/slices/gosprogram/slices/programEvents";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";

export interface IProgramEvents {
  fetching: boolean;
  fetched: boolean;
  items: IProgramEvent[];
  load(params: IParams): void;
  getItemByCode(code: string): IProgramEvent | undefined;
  error: SerializedError | null;
}

export const useProgramEvents = (): IProgramEvents => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programEvents = [],
    fetched,
  } = useAppSelector((state) => state.gosprogram.programEvents);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(
    (params) => {
      if (!fetching) {
        dispatch(actions.programEvents.get(params)).then((action) => {
          if (actions.programEvents.get.rejected.match(action)) {
            setError(action.error);
          }
          return action;
        });
      }
    },
    [dispatch, fetching]
  );

  const getItemByCode = (code: string): IProgramEvent | undefined =>
    programEvents.find((programEvent) => programEvent.code === code);

  return {
    fetching,
    fetched,
    items: programEvents,
    getItemByCode,
    load,
    error,
  };
};
