import { Box, Step, SxProps } from "@mui/material";
import { DoubleDownArrow, DoubleUpArrow } from "ASSETS/svg/arrow/index";
import React, { Dispatch, SetStateAction } from "react";
import { IStepsInfo } from "SRC/pages/Program/common/EventCard/util/interfaces";
import theme from "SRC/theme";

import { css, StepLabelCheckpoint } from "../Checkpoint.styled";

interface IProps {
  label: IStepsInfo;
  openName: boolean;
  setOpenName: Dispatch<SetStateAction<boolean>>;
}

export const StepComponent = ({ label, openName, setOpenName }: IProps) => {
  const maxLength = 70;
  const truncatedString = label.namePoint.substring(0, maxLength) + "...";
  const isLargeString = label.namePoint.length > maxLength;
  const displayText = openName ? label.namePoint : truncatedString;

  const handleExpand = () => setOpenName(!openName);

  return (
    <Step key={label.namePoint} sx={{ position: "relative" }}>
      <Box sx={css.stepLabelDate}>
        <Box sx={css.dateFinish(label.statusPoint)}>{label.dateFinish}</Box>
        <Box sx={css.datePlan} component={"span"}>
          &nbsp;/&nbsp;{label.datePlan}
        </Box>
        <Box sx={css.deviation(label.deviationColor)}>{label.deviation}</Box>
      </Box>
      <StepLabelCheckpoint
        status={label.statusPoint}
        StepIconComponent={() => (
          <Box
            sx={css.colorStep(label.statusPoint) as SxProps}
            className={"colorStep"}
          />
        )}
      >
        {isLargeString ? (
          <>
            {displayText}
            {openName ? (
              <DoubleUpArrow style={css.expandIcon} onClick={handleExpand} />
            ) : (
              <DoubleDownArrow style={css.expandIcon} onClick={handleExpand} />
            )}
          </>
        ) : (
          label.namePoint
        )}
      </StepLabelCheckpoint>
      <Box sx={css.stepperConnector(label.statusPoint, theme) as SxProps} />
    </Step>
  );
};
