import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Layout } from "SRC/layouts";
import { CenterLayout } from "SRC/pages/ProgramsBoard/layouts";
import themeConfig from "SRC/theme";

export const ProgramsBoard = () => {
  const theme = useTheme<typeof themeConfig>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Layout
      left={<></>}
      center={<CenterLayout theme={theme} isLargeSize={isLargeSize} />}
      right={<></>}
    />
  );
};
