import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { IDataProgramGoal } from "SRC/pages/Program/common/NationalGoalsConnect/NationalGoalsConnect";
import { useAppSelector } from "SRC/redux/hooks";
import { useProgramFilters } from "SRC/redux/slices/gosprogram/hooks/useProgramFilters";
import theme from "SRC/theme";

import { css } from "./ProgramGoalItem.styled";

interface IProgramGoalItemProps {
  item: IDataProgramGoal;
  activeFilter: boolean;
  filterProgram: boolean;
  id: number;
}

export const ProgramGoalItem = ({
  item,
  activeFilter,
  filterProgram,
  id,
}: IProgramGoalItemProps) => {
  const [activeProgramGoalItem, setActiveProgramGoalItem] = useState(false);
  const { selectedProgramGoalId } = useAppSelector(
    (state) => state.gosprogram.programFilters
  );
  const { setSelectedGoalFactorsId, setSelectedGoalProgramId } =
    useProgramFilters();
  const {
    setSelectedProgramGoalId,
    setSelectedProgramGoalFactorsId,
    setSelectedGoalId,
    setSelectedProgramGoalCodes,
    setSelectedHiddenFactorsId,
  } = useProgramFilters();
  const handleProgramGoalItem = () => {
    setSelectedGoalId("");
    setSelectedGoalFactorsId([]);
    setSelectedGoalProgramId([]);
    setSelectedHiddenFactorsId("");
    if (!activeProgramGoalItem) {
      selectedProgramGoalId === item.id
        ? setSelectedProgramGoalId("")
        : setSelectedProgramGoalId(item.id);
      setSelectedProgramGoalFactorsId(item.idGoalsFactor);
      setSelectedProgramGoalCodes(item.goalsCode);
    } else {
      setSelectedProgramGoalId("");
      setSelectedProgramGoalFactorsId([]);
      setSelectedProgramGoalCodes([]);
    }
  };

  useEffect(() => {
    if (selectedProgramGoalId === item.id) {
      setActiveProgramGoalItem(true);
    } else {
      setActiveProgramGoalItem(false);
    }
  }, [selectedProgramGoalId]);

  return (
    <Box
      id={`programItem-${id}`}
      className="programItem"
      sx={css.itemLeft}
      onClick={handleProgramGoalItem}
    >
      <Box
        sx={css.leftName(
          activeProgramGoalItem,
          activeFilter,
          filterProgram,
          theme
        )}
      >
        {item.title}
      </Box>
      <Box sx={css.leftUnit}>{item.unit}</Box>
      <Box
        sx={css.selectPoint(
          activeProgramGoalItem,
          activeFilter,
          filterProgram,
          theme
        )}
      ></Box>
    </Box>
  );
};
