import { TabsListUnstyled, TabUnstyled } from "@mui/base";
import { styled } from "@mui/styles";

export const TabList = styled(TabsListUnstyled)({
  width: "6.25rem",
  display: "flex",
  flexDirection: "row",
  backgroundColor: "transparent",
  border: "1px solid #58637b",
  padding: "0.25rem",
  borderRadius: "0.5rem",
});

export const Tab = styled(TabUnstyled)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.25rem",
  borderRadius: "0.5rem",
  border: "none",
  background: "transparent",
  cursor: "pointer",
  flexBasis: "49%",

  "&:focus": {
    backgroundColor: "#4a79d8",
  },

  "&.Mui-selected": {
    "& svg": {
      fill: "white",
    },
  },
});

export const css = {
  wrapper: {
    maxWidth: "1900px",
    margin: "0 auto",
    width: "100%",
    flex: 1,
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    height: "100%",
    minHeight: "21rem",
    position: "relative",
  },
};
