import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { LayoutNew } from "SRC/layouts";
import { ComplexGrid } from "SRC/pages/MainNew/common/ComplexGrid/ComplexGrid";
import { NationalGoalsTable } from "SRC/pages/MainNew/common/NationalGoalsTable/NationalGoalsTable";
import themeConfig from "SRC/theme";

import { CenterLayout } from "./layouts";

export const MainNew = () => {
  const theme = useTheme<typeof themeConfig>();
  const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <LayoutNew
      left={isLargeSize && <ComplexGrid />}
      center={<CenterLayout theme={theme} isLargeSize={isLargeSize} />}
      right={isLargeSize && <NationalGoalsTable />}
    />
  );
};
