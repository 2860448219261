import { Theme } from "@mui/material";

export const css = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
  },
  graph: {
    width: "100%",
    height: "100%",
  },
  graphContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.2rem",
    borderRadius: "50%",
    background: (theme: Theme) => theme.palette.main.darkBlue,
    border: (theme: Theme) =>
      `1px solid ${theme.palette.complementary.grayBlue}`,
  },
};
