import { Theme } from "@mui/material";
import { ChartBgr } from "ASSETS/svg/backgrounds";

export const css = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  graph: {
    width: "100%",
    height: "100%",
  },
  textContainer: {
    width: "100%",
    height: "100%",
    paddingBottom: "1.5rem",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.2rem",
    background: `url(${ChartBgr}) no-repeat`,
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  symbol: {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.complementary.grayBlue,
    display: "flex",
  },
  text: {
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    fontWeight: "bold",
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
};
