import { Box } from "@mui/material";
import React, { MutableRefObject, useMemo, useState } from "react";
import { EventsTable } from "SRC/pages/Summary/common/DasboardTables/EventsTable/EventsTable";
import { GoalsTable } from "SRC/pages/Summary/common/DasboardTables/GoalsTable/GoalsTable";
import { IndicatorsTable } from "SRC/pages/Summary/common/DasboardTables/IndicatorsTable/IndicatorsTable";
import { SatisfactionTable } from "SRC/pages/Summary/common/DasboardTables/SatisfactionTable/SatisfactionTable";
import {
  ICashDataItem,
  IGeneralInfoPremiersDataItem,
  ITableDataItem,
} from "SRC/pages/Summary/common/SummaryComplexGrid/util/interfaces";
import { css } from "SRC/pages/Summary/layouts/center-layout/center-layout.styled";
import { useAppSelector } from "SRC/redux/hooks";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  IPremiers,
  TPremier,
  usePremiers,
} from "SRC/redux/slices/main/hooks/usePremiers";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";

import { CashTable } from "../../common/DasboardTables/CashTable/CashTable";

interface ICenterLayout {
  cashTableRef: MutableRefObject<HTMLElement | undefined>;
  goalTableRef: MutableRefObject<HTMLElement | undefined>;
  indicatorsTableRef: MutableRefObject<HTMLElement | undefined>;
  eventsTableRef: MutableRefObject<HTMLElement | undefined>;
  satisfactionTableRef: MutableRefObject<HTMLElement | undefined>;
  onScroll: (scroll: any) => void;
  setSortBy: (sortBy: any) => void;
  tableData: ITableDataItem[];
}

export const CenterLayout = ({
  cashTableRef,
  goalTableRef,
  indicatorsTableRef,
  eventsTableRef,
  satisfactionTableRef,
  onScroll,
  setSortBy,
  tableData,
}: ICenterLayout) => {
  return (
    <Box sx={css.container}>
      <CashTable
        forwardRef={cashTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
      />
      <GoalsTable
        forwardRef={goalTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
      />
      <IndicatorsTable
        forwardRef={indicatorsTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
      />
      <EventsTable
        forwardRef={eventsTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
      />
      <SatisfactionTable
        forwardRef={satisfactionTableRef}
        onScroll={onScroll}
        tableData={tableData}
        setSortBy={setSortBy}
      />
    </Box>
  );
};
